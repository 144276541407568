import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { ProjectDto } from '@root/types/dto';
import { getRequestResult } from '@root/utils/helpers';

const END_POINT = '/projects/';


interface UseProjectApi {
    getProjects: () => Promise<ProjectDto[] | SupportedRequestError>;
    newProject: (data: object) => Promise<{ Id: number; message: string; } | SupportedRequestError>;
    deleteProject: (id: string | number) => Promise<{ message: string; } | SupportedRequestError>;
    likeProject: (id: string | number) => Promise<any[] | SupportedRequestError>;
    getProjectById: (id: string | number) => Promise<ProjectDto | SupportedRequestError>;
    updateProject: (data: object) => Promise<{ message: string } | SupportedRequestError>;
}


export const useProjectApi = (): UseProjectApi => {
    const { requestGet, requestPostWithJson, requestDelete, requestPutWithJson } = useRequest();

    const getProjects = async (): Promise<ProjectDto[] | SupportedRequestError> => {
        const response = await requestGet(END_POINT);
        return getRequestResult(response, { propertyName: 'projects' });
    };

    const getProjectById = async (id: number | string): Promise<ProjectDto | SupportedRequestError> => {
        const response = await requestGet(`${END_POINT}${id}`);
        return getRequestResult(response, { propertyName:'project' });
    };

    const newProject = async (data: object): Promise<{ Id: number; message: string; } | SupportedRequestError> => {
        const response = await requestPostWithJson(END_POINT, {data});
        return response?.data;
    };

    const deleteProject = async (id: number | string): Promise<{ message: string; } | SupportedRequestError> => {
        const response = await requestDelete(`${END_POINT}${id}`);
        return getRequestResult(response, { propertyName: 'message' });
    };

    const likeProject = async (id: number | string): Promise<any[] | SupportedRequestError> => {
        const response = await requestGet(`${END_POINT}${id}/images/liked`);
        return getRequestResult(response, { propertyName: 'images' });
    };

    const updateProject = async (data: object): Promise<{ message: string } | SupportedRequestError> => {
        const response = await requestPutWithJson(END_POINT, {data});
        return response?.data;
    };

    return {
        getProjects,
        newProject,
        deleteProject,
        likeProject,
        getProjectById,
        updateProject
    };
};