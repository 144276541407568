import React from 'react';
import { Box, Divider, styled } from '@mui/material';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import InfluenceSlider from '@root/components/InfluenceSlider';
import DragAndDropPanel from '@root/components/DragAndDropPanel';
import { CommonConstants } from '@root/utils/constants';


const StyleDriveFooter = (): React.JSX.Element => {
    const { 
        controlImageSD,
        firstInfluenceImageSD,
        secondInfluenceImageSD,
        controlInfluenceSD,
        firstInfluenceSD,
        secondInfluenceSD,
        setControlImageSD,
        setFirstInfluenceImageSD,
        setSecondInfluenceImageSD,
        setControlInfluenceSD,
        setFirstInfluenceSD,
        setSecondInfluenceSD,
    } = useStyleDrive();
  return (
    <Container>
        <ImageSection>

        <DragAndDropPanel
            placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
            enabled={true}
            label={`Choose the ${CommonConstants.COMPLEX_LABEL_SEPARATOR}
                control image
                ${CommonConstants.COMPLEX_LABEL_SEPARATOR} or drag it here.`}
            OnUpload={setControlImageSD}
            loadedImageUrl={controlImageSD ? URL.createObjectURL(controlImageSD) : ''}/>
    
        <InfluenceSlider
            id={'controlInfluenceSD'}
            value={controlInfluenceSD} 
            OnChange={setControlInfluenceSD}
            label={'keep the shape'}
            step={1}
            range={[0, 100]}
            endAdornment={'%'}
            tooltipLabel={'Keep the shape follows reference image closer, transferring more details and color.'}
        ></InfluenceSlider>
        </ImageSection>
        <SectionDivider orientation="vertical"/>
        <ReferenceSection>
            <ImageSection>
                <DragAndDropPanel
                    placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
                    enabled={true}
                    OnUpload={setFirstInfluenceImageSD}
                    loadedImageUrl={firstInfluenceImageSD ? URL.createObjectURL(firstInfluenceImageSD) : ''}/>
                <InfluenceSlider
                    id={'firstInfluenceSD'}
                    value={firstInfluenceSD} 
                    OnChange={setFirstInfluenceSD}
                    label={'Influence'}
                    step={1}
                    range={[0, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>
            </ImageSection>

            <ImageSection>
                <DragAndDropPanel
                    placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
                    enabled={true}
                    OnUpload={setSecondInfluenceImageSD}
                    loadedImageUrl={secondInfluenceImageSD ? URL.createObjectURL(secondInfluenceImageSD) : ''}/>
                <InfluenceSlider
                    id={'secondInfluenceSD'}
                    value={secondInfluenceSD} 
                    OnChange={setSecondInfluenceSD}
                    label={'Influence'}
                    step={1}
                    range={[0, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>
            </ImageSection>
        </ReferenceSection>
    </Container>
  );
};

export default StyleDriveFooter;

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    gap: 2,
    marginTop: '20px',
    minHeight:'226px'
}));

const ImageSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '14px'
}));

const ReferenceSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '40px'
}));


const SectionDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '1px',
    height: '226px',
    margin: '0px 80px'
  }));
