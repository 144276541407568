import React from 'react';
import { Checkbox, styled } from '@mui/material';
import { CheckboxCheckedIcon } from '@assets/icons/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from '@assets/icons/CheckboxUncheckedIcon';
import { SelectCheckboxProps } from './SelectCheckbox.types';

const SelectCheckbox = ({ className, hidden = false, checked, onChange }: SelectCheckboxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(event.target.checked);
  };

  return (
    <StyledCheckBox
      disableRipple
      className={className}
      size={'small'}
      hidden={hidden}
      checked={checked}
      checkedIcon={<CheckboxCheckedIcon/>}
      icon={<CheckboxUncheckedIcon/>}
      onChange={handleChange}
    />
  );
};

export default SelectCheckbox;

const StyledCheckBox = styled(Checkbox)<{hidden: boolean}>(({ theme, hidden }) => ({
  opacity: hidden ? 0 : 1,
  transition: 'all 0.3s ease-out',

  '&.MuiCheckbox-root': {
    backgroundColor: 'transparent',
    color: theme.palette.neutrals.inactive1
  },
  
  '&.Mui-checked': {
    color: theme.palette.secondary.main
  },

  '& svg': {
    height: '24px',
    width: '24px'
  }
}));
