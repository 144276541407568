import { SvgIcon } from '@mui/material';
import React from 'react';

export const AspectRatioIcon = (props) => (
    <SvgIcon viewBox="0 0 20 32" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect
            x="19.4"
            y="31.4"
            width="18.8"
            height="30.8"
            rx="2.4"
            transform="rotate(-180 19.4 31.4)"
            stroke="currentColor"
            fill="none"
            strokeWidth="1.2"
        />
        <path
            d="M14.6667 28C15.5258 28 16.2222 27.1046 16.2222 26L17 26C17 27.6569 15.9553 29 14.6667 29L14.6667 28Z"
            stroke="currentColor"
            fill="none"
            strokeWidth="0.5"
        />
        <path
            d="M16.2222 6C16.2222 4.89543 15.5258 4 14.6667 4L14.6667 3C15.9553 3 17 4.34315 17 6L16.2222 6Z"
            stroke="currentColor"
            fill="none"
            strokeWidth="0.5"
        />
        <path
            d="M5.33333 4C4.47423 4 3.77778 4.89543 3.77778 6L3 6C3 4.34315 4.04467 3 5.33333 3L5.33333 4Z"
            stroke="currentColor"
            fill="none"
            strokeWidth="0.5"
        />
        <path
            d="M3.77778 26C3.77778 27.1046 4.47422 28 5.33333 28L5.33333 29C4.04467 29 3 27.6569 3 26L3.77778 26Z"
            stroke="currentColor"
            fill="none"
            strokeWidth="0.5"
        />
    </SvgIcon>
);
