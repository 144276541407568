import React from 'react';

import { Box, TextField } from '@mui/material';

const Search = ({ value, setValue }) => {
    const handleValueChange = (e) => {
        setValue(e.target.value);
    };
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
            }}>
            <TextField
                sx={{
                    width: '800px',
                    height: '100%',
                }}
                value={value}
                onChange={handleValueChange}
                variant="outlined"
                placeholder="Search"
            />
        </Box>
    );
};

export default Search;
