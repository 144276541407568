import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { Box, Divider, Typography, IconButton, styled } from '@mui/material';
import { MainNav, SecondaryNav } from '../../routes/routes';
import CreateNewProjectModal from '../Projects/ProjectListPage/components/CreateNewProjectModal';
import QuickAction from '@root/components/QuickAction';
import SecondaryNavWindow from './components/SecondaryNavWindow';
import AuthContext from '../../context/AuthContext/AuthContext';
import ProjectsContext from '../../context/ProjectsContext/ProjectsContext';
import ModelsContext from '../../context/ModelsContext/ModelsContext';
import LocalStorageContext from '@context/LocalStorageContext/LocalStorageContext';
import logoImage from '@assets/images/logoImage.svg';
import {StatusPanel} from '@pages/MainPage/components/StatusPanel/StatusPanel';
import NavButton from '@root/components/NavButton';
import { UserSettingsIcon } from '@root/assets/icons/UserSettingsIcon';
import { LicenseStatus, NavigationPaths, QuickActionTypes, UserRoles } from '@root/utils/constants/enums';
import { InfoMenuIcon } from '@root/assets/icons/InfoMenuIcon';
import AppInfoModal from './components/AppInfoModal';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';
import LicenseValidationErrorModal from '@root/components/LicenseValidationErrorModal';

const MainPage = () => {
    const { signOut, username, userRole } = useContext(AuthContext);
    const { storageProjectId, storageProjectName } = useContext(LocalStorageContext);
    const { projects } = useContext(ProjectsContext);
    const { models } = useContext(ModelsContext);
    const location = useLocation();
    const navigate = useNavigate();
    const { validationStatus } = useLicenseValidation();

    const [isInfoModalActive, setIsInfoModalActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [expirationDate, setExpirationDate] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
      toggleModalInfo();
    };

    const toUsersList = () => {
        navigate(`/${NavigationPaths.Users}`);
    };

    const toggleModalInfo = () => {
        setIsInfoModalActive(!isInfoModalActive);
    };

    useEffect(() => {
        const date = new Date(window._env_.REACT_APP_EXPIRATION_DATE);
        if (date instanceof Date && !isNaN(date)) {
            setExpirationDate(date.toDateString());
        }
    }, []);
    
    
    useEffect(() => {
        if (!isInfoModalActive) {
            setAnchorEl(null);
        }
    }, [isInfoModalActive]);
    
    return (
        <Container>
            <ContentWrapper>
                <NavButtonWrapper>
                    {MainNav.map((nav, i) => {
                        return (
                            nav.elementName && (
                                <NavButton
                                    key={i}
                                    to={
                                        nav.elementName === 'Create' &&
                                        storageProjectId
                                            ? `${nav.path}/${storageProjectId}`
                                            : nav.path
                                    }
                                    label={nav.elementName}
                                />
                            )
                        );
                    })}
                </NavButtonWrapper>
                <ContentDivider />
                {
                    !location?.pathname?.toLowerCase()?.includes('models/model') && (
                        <ProjectInfoWrapper>
                            <ProjectNameWrapper variant="subtitle2">
                                {storageProjectName}
                            </ProjectNameWrapper>
                        </ProjectInfoWrapper>
                    )
                }
                <LogoSectionWrapper>
                    <Logo src={logoImage} />
                </LogoSectionWrapper>

                <InfoSection>
                    { !!expirationDate && 
                        <>
                            <InfoLabel>
                                {`Warning: the demo will be unavailable starting ${expirationDate}.` }
                            </InfoLabel>
                            <InfoLabel>
                                {'Please save all the necessary data before then.' }
                            </InfoLabel>
                        </>
                    }
                    {(validationStatus && validationStatus.licenseStatus === LicenseStatus.FAILED) && (
                        <InfoLabel>
                            {'Warning: the license is invalid. Part of the functionality is unavailable.' }
                        </InfoLabel>
                    )}
                </InfoSection>
                { userRole === UserRoles.Admin && ( 
                    <ToolBarWrapper>
                        <HeaderButton>
                                <HeaderIcon
                                disableRipple
                                onClick={toUsersList}>
                                <UserSettingsIcon />
                                </HeaderIcon>
                        </HeaderButton>
                    </ToolBarWrapper> )
                }
                <AccountInfoWrapper singleIcon={userRole !== UserRoles.Admin}>
                    <InfoButton disableRipple
                    onClick={handleClick}
                    selected={isInfoModalActive}>
                        <InfoMenuIcon/>
                    </InfoButton>
                    <AppInfoModal
                        isActive={isInfoModalActive}
                        username={username}
                        anchorEl={anchorEl}
                        version={window._env_.REACT_APP_BUILD_VERSION}
                        handleSingOut={signOut}
                        handleClose={toggleModalInfo} />
                </AccountInfoWrapper>
            </ContentWrapper>

            <QuickActionsWrapper>
                <ProjectAndModelWrapper>
                    {SecondaryNav.map((nav, i) => {
                        return (
                            nav.elementName && (
                                <QuickAction
                                    key={i}
                                    label={nav.elementName}
                                    actionType={
                                        nav.elementName === 'My projects' ?
                                            QuickActionTypes.ProjectsList :
                                            QuickActionTypes.ModelsList
                                    }
                                    actionWindow={
                                        <SecondaryNavWindow
                                            fullPage={nav.path}
                                            isProjects={
                                                nav.elementName === 'My projects'
                                            }
                                            info={
                                                nav.elementName === 'My projects'
                                                    ? projects
                                                    : models
                                            }
                                            navigationBase={nav.navigationBase}
                                        />
                                    }
                                />
                            )
                        );
                    })}
                </ProjectAndModelWrapper>

                <QuickAction
                    label={'Task Status'}
                    actionType={QuickActionTypes.JobsStatuses}
                    actionWindow={<StatusPanel/>}
                />
            </QuickActionsWrapper>
            <Box sx={{ width: '100%', height: '100%' }}>
                <Outlet />
            </Box>
            <CreateNewProjectModal />
            <LicenseValidationErrorModal />
        </Container>
    );
};

export default MainPage;

const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
}));

const NavButtonWrapper = styled(Box)(() => ({
    display: 'flex',
    gap: '44px',
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
    borderBottom: 1,
    borderColor: 'transparent',
    height: '67px',
    paddingLeft: '30px',
    background: theme.palette.primary.light,

    '@media (max-width:1500px)': {
        height: '67px',
    },
}));

const AccountInfoWrapper = styled(Box)(({ theme, singleIcon }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: singleIcon ? 'auto' : '0',
    marginRight: '16px',
    position: 'relative',

    '& svg:hover ~ p': {
        opacity: 1,
    },

    '& p': {
        position: 'absolute',
        top: '25px',
        right: 0,
        width: 'max-content',
        background: theme.palette.primary.inactive,
        padding: '5px 10px',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '6px',
        opacity: '0',
        transition: 'all 0.5s',
        zIndex: 1
    },
}));

const ProjectInfoWrapper = styled(Box)(({ theme }) => ({
    color: theme.palette.primary.inactive,
    display: 'flex',
    height: '100%',
    alignItems: 'center'
}));

const ProjectNameWrapper = styled(Typography)(() => ({
    maxWidth: '300px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'clip',
    '@media (max-width:1700px)': {
        maxWidth: '100px'
    }
}));


const ContentDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '1px',
    height: '23px',
    margin: '0px 40px 0px 22px'
}));

const LogoSectionWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'end',

    height: '67px',
    padding: '7px 0px',
    gap: 0,

    position: 'absolute',
    left: 'calc(50% - 50.5px)',
}));

const Logo = styled('img')(() => ({
    height: '53px',
    width: '101px',
    objectFit: 'contain',
}));

const QuickActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'end',
  alignItems: 'flex-end',
  height: '60%',
  position: 'absolute',
  right: '0',
  bottom: '0',
  
  paddingBottom: theme.spacing(2),
  zIndex: '100',
  background: 'transparent'
}));

const ProjectAndModelWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
}));

const ToolBarWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',

    marginLeft: 'auto',
    marginRight: '32px',

    position: 'relative'
}));

const HeaderButton = styled(Box)(({ shown = false }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  
    width: '24px',
    height: '24px',
  
    opacity: shown ? 0 : 1
  }));
  
  const HeaderIcon = styled(IconButton)(() => ({
    '&:hover': {
      backgroundColor: 'transparent',
    },
  
    '& svg': {
        width: '24px',
        height: '24px',
    }
  }));

const InfoButton = styled(IconButton)(({ theme, selected }) => ({
    width: '24px',
    height: '24px',
    borderRadius: '0px',
    backgroundColor: selected ? theme.palette.primary.main : 'transparent',
    '&:hover': {
        color: theme.palette.primary.main
    }
}));

const InfoSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '24px',
    position: 'absolute',
    right: 100,
    margin: '20px 20px 20px 0px'
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.error.main,
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '14px',
    '@media (max-width:1500px)': {
        fontSize: '10px',
        lineHeight: '12px'
    }
}));
