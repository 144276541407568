
import { useRequest } from '@hooks/request/UseRequest';
import { AdminApiItems } from '@root/types/apiTypes';
import { RegistrationInfo, SupportedRequestError, UserUpdateInfo } from '@root/types/commonTypes';
import { UserInfoDto, UserRegistrationDto } from '@root/types/dto';

const ADMIN_END_POINT = '/admin';

export const  useAdminApi = (): AdminApiItems => {

    const { requestGet, requestPostWithJson, requestPutWithJson} = useRequest();

    const getAll = async (): Promise<UserInfoDto[] | SupportedRequestError> => {
        const response = await requestGet(`${ADMIN_END_POINT}/`);
        return response?.data;
    };

    const register = async(data: RegistrationInfo): Promise<UserRegistrationDto | SupportedRequestError> => {
        const response = await requestPostWithJson(`${ADMIN_END_POINT}/register`,
            { data }
        );

        return response?.data;
    };

    const updateUserInfo = async(id: string, data: UserUpdateInfo ): Promise<UserInfoDto | SupportedRequestError>  => {
        const response = await requestPutWithJson(
            `${ADMIN_END_POINT}/${id}/update`,
            { data }
        );

        return response?.data;
    };

    return {
        getAll,
        updateUserInfo,
        register
    };
};
