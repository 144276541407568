import {Job} from '@utils/job/Job';
import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { getRequestResult } from '@root/utils/helpers';

const END_POINT = '/jobs';

interface UseJobsApi {
    cancelJob: (jobId: number) => Promise<string | SupportedRequestError>;
    getJobStatus: () => Promise<Job[] | SupportedRequestError>;
    removeOldJobs: () => Promise<number[] | SupportedRequestError>;
}

export const useJobsApi = (): UseJobsApi => {
    const { requestGet, requestDelete} = useRequest();

    const cancelJob = async (id: number): Promise<string | SupportedRequestError> => {
        const response = await requestDelete(`${END_POINT}/cancel-job/${id}`);
        return getRequestResult(response, { propertyName:'jobId' });
    };

    const getJobStatus = async (): Promise<Job[] | SupportedRequestError> => {
        const response = await requestGet(`${END_POINT}/get_all`);
        return response?.data;
    };

    const removeOldJobs = async (): Promise<number[]  | SupportedRequestError> => {
        const response = await requestDelete(`${END_POINT}/remove-old-jobs`);
        return getRequestResult(response, { propertyName:'jobsId' });
    };
    
    return {
        cancelJob,
        getJobStatus,
        removeOldJobs
    };
};
