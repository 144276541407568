import React, { createContext, useContext, useState, useEffect } from 'react';
import { NotificationsContextItems } from '@root/types/contextTypes';
import { NotificationsContextProps } from './NotificationsContext.types';
import { CustomNotification } from '@root/types/commonTypes';
import { notificationMessages } from '@root/utils/constants/notificationMessages';
import uuid from 'react-uuid';

const NotificationsContext = createContext<NotificationsContextItems>({} as NotificationsContextItems);

export const NotificationsContextProvider = ({ children }: NotificationsContextProps) => {
    const [appFocused, setAppFocused] = useState<boolean>(true);
    const [notifications, setNotifications] = useState<CustomNotification[]>([]);

    // currently used for global hiding
    const [showNotifications, setShowNotifications] = useState<boolean>(false);
    
    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);

        return () => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    }, []);

    useEffect(() => {
        // For browser notifications
        if (appFocused) {
            return;
        }
        const notification = notifications.find((notification) => notification.broadcast === 'browser');
        if (notification) {
            if ('Notification' in window) {
                const createBrowserNotification = () => {
                    new Notification('DesignLab', {body: notification.text, icon: 'icon192.png'});
                };
                if (Notification.permission === 'granted') {
                    createBrowserNotification();
                } else if (Notification.permission !== 'denied') {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            createBrowserNotification();
                        }
                    });
                }
            }
            deleteNotification(notification.id);
        }
    }, [notifications, appFocused]);

    const onFocus = (): void => {
        setAppFocused(true);
    };

    const onBlur = (): void => {
        setAppFocused(false);
    };

    const deleteNotification = (id: string): void => {
        setNotifications((prev) => prev.filter((el) => el.id !== id));
    };

    const createNotification = (title: string, text: string, broadcast = 'app'): void => {
        if (!showNotifications) {
            return;
        }
        const newNotification: CustomNotification = {
            state: true,
            title,
            text,
            id: uuid(),
            broadcast,
        };
        setNotifications((prev) => [...prev, newNotification]);
    };


    return (
        <NotificationsContext.Provider
            value={{
                notifications,
                createNotification,
                deleteNotification,
                notificationMessages,
                appFocused,
            }}>
            {children}
        </NotificationsContext.Provider>
    );
};


export const useNotifications = (): NotificationsContextItems => {
    const context = useContext(NotificationsContext);
    if (!context) {
        throw new Error('Can not use useNotifications out of NotificationsContext.');
    }
    return context;
};