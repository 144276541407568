import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';


export const MoveToCurateIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" 
            {...props}>
            <path d="M11.3997 7.55511L1.99978 18.5001L5.27357 21.2689L14.6735 10.324M11.3997 7.55511L15.4999 2.99976L18.7737 5.76863L14.6735 10.324M11.3997 7.55511L14.6735 10.324" stroke="white" strokeWidth="1.5" strokeLinejoin="round" fill={'none'}/>
            <path d="M13.4873 16.7073V19.8284M11.9267 18.2678H15.0478" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
            <path d="M18.9493 12.0249V16.7066M16.6084 14.3658H21.2901" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
            <path d="M4.90388 4.2229V8.90461M2.56302 6.56376H7.24473" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
        </SvgIcon>
    );
};