import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';

interface UseUpscaleApi {
    upscale: (data: object) => Promise<any | SupportedRequestError>;
    getUpscaledImage: (id: string | number) => Promise< any| SupportedRequestError>;
}

export const useUpscaleApi = (): UseUpscaleApi => {
    const { requestPostWithJson, requestGet } = useRequest();

    const upscale = async (data: object): Promise<any | SupportedRequestError> => {
        const response = await requestPostWithJson('/generations/upscale', {data});
        return response?.data;
    };

    const getUpscaledImage = async (id: string | number): Promise< any| SupportedRequestError> => {
        const response = await requestGet(`/images/${id}/enhancements`);
        return response?.data;
    };
    
    return {
        upscale,
        getUpscaledImage
    };
};