import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import ContentLoader from '@root/components/ContentLoader';
import { PanoramaPlaceholderProps } from './PanoramaPlaceholder.types';

const PanoramaPlaceholder = ({
    label,
    isActive,
    isFullScreen,
    withLoader = false
}: PanoramaPlaceholderProps): React.JSX.Element => {
  return (
        <PlaceholderWrapper active={isActive} small={!isFullScreen}>
            <LoadingInfo>
                { withLoader && <ContentLoader state={isActive}/> }
                <PlaceholderTypography>{label}</PlaceholderTypography>
            </LoadingInfo>
        </PlaceholderWrapper>
  );
};

export default PanoramaPlaceholder;

const PlaceholderWrapper = styled(Box)<{ active?: boolean, small?: boolean }>(( { active = true, small = true , theme }) => ({
    display:'flex',
    height:  '100%',
    width: small ? '70%' : '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    backgroundColor: theme.palette.primary.light,
    borderRadius: small ? '15px' : '0px',
    justifyContent: 'center',
    alignItems: 'center',
    ...(!active && { display: 'none' })
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '20px',
    lineHeight: '22px',
    textAlign: 'center',
    color: theme.palette.neutrals.inactive1
}));

const LoadingInfo = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    height: '100%',
    width: '100%',

    gap: '14px'

}));
