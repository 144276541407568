export enum CurateTools {
    MixImages = 1,
    Brush,
    Lasso,
    BackgroundReplacement,
    Sketch,
    StyleDrive,
    Eraser,
    Upscale,
    SceneShift
}