import React, { createContext, ReactNode, useContext, useState } from 'react';
import { MixingIngredient } from '@utils/curate/MixingIngredient';
import { MixImageContextItems } from '@root/types/contextTypes';
import { MixImageToolInfo } from '@root/types/commonTypes';
import { CuratePageConstants } from '@root/utils/constants';

const MixImageContext = createContext<MixImageContextItems | null>(null);

interface MixImageProviderProps {
    children: ReactNode;
}

export const MixImageProvider = ({ children }: MixImageProviderProps) => {

    const [firstMixingIngredient, setFirstMixingIngredient] = useState<MixingIngredient | null>(null);
    const [secondMixingIngredient, setSecondMixingIngredient] = useState<MixingIngredient | null>(null);
    const [firstIngredientWeight, setFirstIngredientWeight] = useState<number>(CuratePageConstants.DEFAULT_MIX_IMAGE_INFLUENCE);

    const [activeImageUrlMix, setActiveImageUrlMix] = useState<string>('');
    const [activeImageLayerIdMix, setActiveImageLayerIdMix] = useState<string | number>('');
    const [isFooterExpanded, setIsFooterExpanded] = useState<boolean>(false);

    const fileToIngredient = (file: File): MixingIngredient => {
        return {
            file,
            dataUrl: URL.createObjectURL(file),
        };
    };

    const handleSetFirstMixingIngredient = (file: File): void => {
        setFirstMixingIngredient(fileToIngredient(file));
    };

    const handleSetSecondMixingIngredient = (file: File): void => {
        setSecondMixingIngredient(fileToIngredient(file));
    };

    const createMixImageConfig = (): MixImageToolInfo | null => {
        const firstFile = firstMixingIngredient?.file;
        const secondFile = secondMixingIngredient?.file;

        if (!firstFile || !secondFile) {
            return null;
        }

        return {
            firstInputImage: firstFile,
            secondInputImage: secondFile,
            firstImageStrength: 100 - firstIngredientWeight,
        };
    };

    const clearMixImageToolInfo = (): void => {
        setFirstMixingIngredient(null);
        setSecondMixingIngredient(null);
        setFirstIngredientWeight(CuratePageConstants.DEFAULT_MIX_IMAGE_INFLUENCE);

        setActiveImageUrlMix('');
        setActiveImageLayerIdMix('');
    };

    const contextValue = {
        firstMixingIngredient,
        secondMixingIngredient,
        setFirstMixingIngredient: handleSetFirstMixingIngredient,
        setSecondMixingIngredient: handleSetSecondMixingIngredient,

        firstIngredientWeight,
        setFirstIngredientWeight,

        readyToMix: !!firstMixingIngredient && !!secondMixingIngredient,
        createMixImageConfig,
        activeImageUrlMix,
        setActiveImageUrlMix,

        activeImageLayerIdMix,
        setActiveImageLayerIdMix,
        clearMixImageToolInfo,

        isFooterExpanded,
        setIsFooterExpanded
    };

    return (
        <MixImageContext.Provider value={contextValue}>
            {children}
        </MixImageContext.Provider>
    );
};

export const useMixImage = (): MixImageContextItems => {
    const context = useContext(MixImageContext);

    if (context === null) {
        throw new Error('useMixImage cannot be used without its provider');
    }

    return context;
};
