import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import ContentLoader from '../ContentLoader';
import { InfoTagFieldProps, InfoTagType } from './InfoTagField.type';

const InfoTagField = ({ withLoader = false, type, label }: InfoTagFieldProps): React.JSX.Element => {
  return (
    <>
        { withLoader && <ContentLoader state={true}/> }
        <TagContent type={type}>
            <Typography>{label}</Typography>
        </TagContent>
    </>
  );
};

export default InfoTagField;

const TagContent = styled(Box)<{type: InfoTagType}>(({ theme, type }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    padding: '4px',
    height: '15px',
    borderRadius: '3px',
    backgroundColor: type === 'default' ? theme.palette.secondary.grey : theme.palette[type].main,

    '& .MuiTypography-root': {
        fontFamily: 'Roboto400',
        fontSize: '10px !important',
        lineHeight: '14px !important',
    
        color: type === 'default' ? theme.palette.primary.main : theme.palette.neutrals.main
    }
}));
