export enum UpscaleOptions {
    Two = 2,
    Four = 4,
    Eight = 8  
}

export interface UpscaleOptionDetails {
    label: string;
    scaleFactor: number;
    enabled?: boolean;
}

type UpscaleOptionInfo = {
    [key in UpscaleOptions]: UpscaleOptionDetails;
};

export const supportedUpscaleOptions: UpscaleOptionInfo = {
    [UpscaleOptions.Two]: {
        label: '2x',
        scaleFactor: UpscaleOptions.Two,
    },
    [UpscaleOptions.Four]: {
        label: '4x',
        scaleFactor: UpscaleOptions.Four,
    },
    [UpscaleOptions.Eight]: {
        label: '8x',
        scaleFactor: UpscaleOptions.Eight,
    },

};
