import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const FullScreenCloseIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32" 
            {...props}>
            <path d="M10.8528 11.0921L4.88184 5.12109M10.8528 11.0921H5.06843M10.8528 11.0921V5.49428M21.6752 11.0921L27.6461 5.12109M21.6752 11.0921V5.49428M21.6752 11.0921H26.8998" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M10.8528 19.6754L4.88184 26.0195M10.8528 19.6754L10.8528 25.6463M10.8528 19.6754L5.08938 19.6754" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M21.6752 19.7726L28.0193 26.0195M21.6752 19.7726L21.7693 25.5562M21.6752 19.7726L27.2722 19.6815" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        </SvgIcon>
    );
};