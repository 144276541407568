import React, { useContext, useEffect, useState } from 'react';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import { MergeModelsDto, ModelDto } from '@root/types/dto';
import { Box, styled } from '@mui/material';
import ModelsListHeader from './components/ModelsListHeader';
import ModelsList from './components/ModelsList';
import MergeModelsForm from './components/MergeModelsForm';
import { RequestErrorCode } from '@root/utils/constants/enums';
import { ErrorMessages } from '@root/utils/constants';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const ModelsListPage = (): React.JSX.Element => {
    const { models, mergeModels } = useContext(ModelsContext);
    const { checkLicenseStatus } = useLicenseValidation();

    const [mergeErrorMessage, setMergeErrorMessage] = useState<string>('');
    const [searchFieldValue , setSearchFieldValue] = useState<string>('');
    const [modelsList, setModelsList] = useState<ModelDto[]>([]);
    const [isMerge, setIsMerge] = useState<boolean>(false);

    const submitMergeModels = async (mergeConfig: MergeModelsDto): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const result = await mergeModels(mergeConfig);
        if (result?.errorCode) {
            setMergeErrorMessage(result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS ? 
                ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS :
                ErrorMessages.MODEL_ERROR_MESSAGE.CREATE_ERROR);
        } else {
            return result;
        }
    };

    useEffect(() => {
        setModelsList(models);
        setSearchFieldValue('');
    }, [models]);

    useEffect(() => {
        const newList = models.filter((el: ModelDto) =>
            el.Name.toLowerCase().startsWith(searchFieldValue),
        );
        setModelsList(newList);
    }, [searchFieldValue]);

  return (
    <Wrapper>
        <ModelsListHeader
            isMerge={isMerge}
            setIsMerge={setIsMerge}
            searchValue={searchFieldValue}
            setSearchValue={setSearchFieldValue}/>
        <Container>
            <MergeMenu>
                <MergeModelsForm
                    setErrorMessage={setMergeErrorMessage}
                    errorMessage={mergeErrorMessage}
                    isActive={isMerge}
                    setIsActive={setIsMerge}
                    onSubmit={submitMergeModels}
                />
            </MergeMenu>
            <ModelsListContent>
                <ModelsList models={modelsList}></ModelsList>
            </ModelsListContent>
        </Container>
    </Wrapper>
  );
};

export default ModelsListPage;

const Wrapper = styled(Box)(() => ({
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Container = styled(Box)(() => ({
    width: '100%',
    height: 'calc(100% - 70px)',
    display: 'flex',

    gap: '30px',
    paddingRight: '20px',
}));

const MergeMenu = styled(Box)(() => ({
    flexGrow: 0,
    height: '100%',
}));

const ModelsListContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    width: '100%',
    height: 'calc(100% - 100px)',
    paddingRight: '40px',
    gap: '30px',
}));