import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddIcon = (props: SvgIconProps): JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29 29" 
            {...props}>
            <path d="M6 14H22" stroke="#686868" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M14 22V6" stroke="#686868" strokeWidth="1.5" strokeLinecap="round"/>
            <circle cx="14.5" cy="14.5" r="13.75" stroke="#686868" strokeWidth="1.5" fill='none'/>
        </SvgIcon>
    );
};