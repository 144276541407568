import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SaveIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 21" 
            {...props}>
            <path d="M6.75 17.5161V13.6774C6.75 13.1251 7.19772 12.6774 7.75 12.6774H13.7188C14.271 12.6774 14.7188 13.1251 14.7188 13.6774V17.5161M6.75 3H4C3.44772 3 3 3.44772 3 4V10.7419V17C3 17.5523 3.44772 18 4 18H17C17.5523 18 18 17.5523 18 17V7.23777C18 7.00095 17.916 6.77181 17.7628 6.59116L15.0183 3.35339C14.8283 3.12924 14.5493 3 14.2555 3H12.8438M6.75 3V6.83871C6.75 7.39099 7.19772 7.83871 7.75 7.83871H11.8438C12.396 7.83871 12.8438 7.39099 12.8438 6.83871V3M6.75 3H12.8438"
                stroke="currentColor"
                strokeWidth="1.5"
                fill='none'/>
        </SvgIcon>
    );
};