import React from 'react';
import { Box, Divider, styled } from '@mui/material';
import InfluenceSlider from '@root/components/InfluenceSlider';
import DragAndDropPanel from '@root/components/DragAndDropPanel';
import { CommonConstants } from '@root/utils/constants';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';

const SceneShiftFooter = (): React.JSX.Element => {
    const {
        controlImageSceneShift,
        setControlImageSceneShift,
    
        influenceImageSceneShift,
        setInfluenceImageSceneShift,
    
        influenceSceneShift,
        setInfluenceSceneShift
    } = useSceneShift();
  return (
    <Container>
        <ImageSection>

        <DragAndDropPanel
            placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
            enabled={true}
            label={`Choose the ${CommonConstants.COMPLEX_LABEL_SEPARATOR}
                control image
                ${CommonConstants.COMPLEX_LABEL_SEPARATOR} or drag it here.`}
            OnUpload={setControlImageSceneShift}
            loadedImageUrl={controlImageSceneShift ? URL.createObjectURL(controlImageSceneShift) : ''}/>

        </ImageSection>
        <SectionDivider orientation="vertical"/>
        <ReferenceSection>
            <ImageSection>
                <DragAndDropPanel
                    placeholderRatio={CommonConstants.DEFAULT_PLACEHOLDER_RATIO}
                    enabled={true}
                    OnUpload={setInfluenceImageSceneShift}
                    loadedImageUrl={influenceImageSceneShift ? URL.createObjectURL(influenceImageSceneShift) : ''}/>
                <InfluenceSlider
                    id={'influenceSceneShift'}
                    value={influenceSceneShift} 
                    OnChange={setInfluenceSceneShift}
                    label={'Influence'}
                    step={1}
                    range={[0, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>
            </ImageSection>
        </ReferenceSection>
    </Container>
  );
};

export default SceneShiftFooter;

const Container = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    gap: 2,
    marginTop: '20px',
    minHeight:'226px'
}));

const ImageSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '14px'
}));

const ReferenceSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
}));


const SectionDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '1px',
    height: '226px',
    margin: '0px 80px'
}));
