import { IconButton as MuiIconButton, styled } from '@mui/material';

export const IconButton = styled(MuiIconButton)<{ active: number | boolean }>(
    ({ active, theme }) => ({
        color: `${active ? theme.palette.primary.main : 'inherit'}`,
        '&:hover': {
            background: 'transparent',
        },
        padding: '8px 0px 8px 0px'
    }),
);
