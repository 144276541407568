import { useEffect, useState } from 'react';

const useKeyPress = (targetKey, withCtrl = false, blockDefaultEvent = false) => {
    const [keyPressed, setKeyPressed] = useState(false);

    const shouldBlockDefaultEvent = (key, ctrlKey) => {
        const pressedKey = key.toLowerCase();
        return pressedKey === targetKey &&
            ((withCtrl && ctrlKey) || !withCtrl) &&
            blockDefaultEvent;
    };


    useEffect(() => {
        const downHandler = (event) => {
            if (shouldBlockDefaultEvent(event.key, event.ctrlKey)) {
                event.preventDefault();
            }

            if (event.key.toLowerCase() === targetKey.toLowerCase()) {
                if (withCtrl && event.ctrlKey) {
                    setKeyPressed(true);
                } else if (!withCtrl) {
                    setKeyPressed(true);
                }
            }
        };

        const upHandler = (event) => {
            if (event.key.toLowerCase() === targetKey.toLowerCase()) {
                if (withCtrl && event.ctrlKey) {
                    setKeyPressed(false);
                } else if (!withCtrl) {
                    setKeyPressed(false);
                }
            }
        };

        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);

        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]);

    return keyPressed;
};

export default useKeyPress;
