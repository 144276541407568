import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { TuneDto } from '@root/types/dto';

interface UseTuneApi {
    getTunes: (projectId: number | string) => Promise<TuneDto[] | SupportedRequestError>;
    getTuneStatus: (tuneId: string | number) => Promise<number | string | SupportedRequestError>;
    deleteTune: (tuneId: string | number) => Promise<{message: string; projectImagesNumber: number} | SupportedRequestError>;
    newTune: (data: object) => Promise<any | SupportedRequestError>;
    getTuneImages: (tuneId: number | string) => Promise<any[] | SupportedRequestError>;
}

export const useTuneApi = (): UseTuneApi => {
    const { requestGet, requestDelete, requestPostWithForm } = useRequest();

    const getTunes = async (projectId: string | number): Promise<TuneDto[] | SupportedRequestError> => {
        const response = await requestGet(`/projects/${projectId}/tunes`);
        return response?.data;
    };

    const getTuneStatus = async (id: string | number): Promise<number | string | SupportedRequestError> => {
        const response = await requestGet(`/tunes/${id}`);
        return response?.data?.errorCode ? response?.data : response?.data?.tune?.StatusId;
    };

    const deleteTune = async (id: string | number): Promise<{message: string; projectImagesNumber: number} | SupportedRequestError> => {
        const response = await requestDelete(`/tunes/${id}`);
        return response?.data;
    };

    const newTune = async (data: object): Promise<any | SupportedRequestError> => {
        const response =  await requestPostWithForm('/generations/generate', {data});
        return response?.data;
    };

    const getTuneImages = async (tuneId: number | string): Promise<any[] | SupportedRequestError> => {
        const response = await requestGet(`/tunes/${tuneId}/images`);
        return response?.data;
    };

    return {
        getTunes,
        getTuneStatus,
        deleteTune,
        newTune,
        getTuneImages
    };
};