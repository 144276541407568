import { ProjectConstants } from './';

export const PROJECT_NAME_ERROR_MESSAGE = {
    ALREADY_EXISTS: 'Project name is already in use',
    NAME_IS_EMPTY: 'Project name is empty',
    NAME_IS_TOO_LONG: `Please use a shorter project name, up to ${ProjectConstants.PROJECT_NAME_MAX_LENGTH} symbols`,
    RENAME_ERROR: 'An error occurred during the renaming process',
    CREATE_ERROR: 'An error occurred during the creation process'
};

export const MODEL_ERROR_MESSAGE = {
    ALREADY_EXISTS: 'Model name is already in use',
    NAME_IS_EMPTY: 'Model name is empty',
    UPDATE_ERROR: 'An error occurred during the updating process',
    CREATE_ERROR: 'An error occurred during the creation process'
};

export const TUNE_ERROR_MESSAGE = {
    CREATE_ERROR: 'An error occurred during the tune generation.'
};