import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckboxCheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 18 18'}
            {...props}>
            <path d="M13.998 6L7.99805 12L4.99805 9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
            <rect x="0.748047" y="0.75" width="16.5" height="16.5" rx="3.25" stroke="currentColor" strokeWidth="1.5" fill={'none'}/>
        </SvgIcon>
    );
};
