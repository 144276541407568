import React from 'react';
import { Box, Slider, styled } from '@mui/material';
import { BrushSliderProps } from './BrushSlider.types';

const BrushSlider = ({
    name,
    value,
    step,
    min,
    max,
    onChange,
    disabled = false
}: BrushSliderProps): React.JSX.Element => {
    return (
        <BrushWrapper>
            <BrushSliderComponent
                isDisabled={disabled ? 1 : 0}
                id={name}
                name={name}
                value={value}
                step={step}
                min={min}
                max={max}
                valueLabelDisplay="auto"
                onChange={onChange}
            />
        </BrushWrapper>
    );
};

export default BrushSlider;

const BrushSliderComponent = styled(Slider)<{isDisabled: number}>(({ theme, isDisabled }) => ({
    width: '89px',
    height: '14px',

    padding: '0',
    marginLeft: '0',    

    zIndex: 5,

    pointerEvents: isDisabled ? 'none' : 'auto',
    color: 'transparent',

    '& .MuiSlider-thumb': {
        color: isDisabled ? theme.palette.primary.inactive : theme.palette.primary.cta2,
        width: '12px',
        height: '12px',
        padding: '6px',

        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none'
        },
        '&.Mui-active': {
            boxShadow: 'none'
        }
    }

}));

const BrushWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100px',
    height: '14px',
    marginRight:'10px',

    borderRadius: '15px',
    border: `1px solid ${theme.palette.primary.light1}`
}));
