import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const DownloadIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 32 32'}
            {...props}>
            <path d="M25.5 20V23.8333C25.5 24.408 25.2717 24.9591 24.8654 25.3654C24.4591 25.7717 23.908 26 23.3333 26H8.16667C7.59203 26 7.04093 25.7717 6.6346 25.3654C6.22827 24.9591 6 24.408 6 23.8333V20M10.3333 13.5833L15.75 19M15.75 19L21.1667 13.5833M15.75 19V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
        </SvgIcon>
    );
};
