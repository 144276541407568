import React, { useEffect, useState } from 'react';
import {LinearProgress, Skeleton, styled} from '@mui/material';
import { ProgressBarProps } from './ProgressBar.types';
import { ProgressBarState } from '@root/utils/constants/enums';
import { ProgressBarConstants } from '@root/utils/constants';

const ProgressBar = ({ externalState, setExternalState, externalProgressValue, isVisible = true }: ProgressBarProps): React.JSX.Element => {

    const [currentState, setCurrentState] = useState<ProgressBarState>(ProgressBarState.Inactive);
    const [progress, setProgress] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(0);

    useEffect(() => {

        if (currentState === ProgressBarState.Inactive) {
            return;
        }

        if (currentState === ProgressBarState.Queued && progress >= ProgressBarConstants.QUEUED_MAX_VALUE) {
            setCurrentState(ProgressBarState.Paused);
        } else if (progress >= maxValue) {
            const state = currentState === ProgressBarState.Finished ?
                ProgressBarState.Inactive :
                ProgressBarState.Waiting;

            setCurrentState(state);
            setExternalState(state);
        }

    }, [progress, currentState]);


    useEffect(() => {

        switch (externalState) {
            case ProgressBarState.Queued:
                setMaxValue(ProgressBarConstants.QUEUED_MAX_VALUE);
                setProgress(0);
                break;
        
            case ProgressBarState.InProgress:
                setProgress(progress > ProgressBarConstants.QUEUED_MAX_VALUE ? 0 : ProgressBarConstants.QUEUED_MAX_VALUE);
                break;

            case ProgressBarState.Finished:
                setMaxValue(ProgressBarConstants.MAX_VALUE);
                break;
        }

        setCurrentState(externalState);
    }, [externalState]);
    

    useEffect(() => {
        if (currentState === ProgressBarState.Inactive ||
            currentState === ProgressBarState.Paused) {
            return;
        }

        const timer = setInterval(() => {
          setProgress((prevProgress) => (prevProgress >= maxValue ?
            maxValue :
            prevProgress + ProgressBarConstants.STEP));
        }, ProgressBarConstants.INTERVAL_TIME);

    
        return () => {
          clearInterval(timer);
        };
    }, [maxValue, currentState]);
    

    useEffect(() => {
        if (externalProgressValue) {
            setMaxValue(externalProgressValue);
        }
    }, [externalProgressValue]);


    return (
        <>
            { 
                currentState !== ProgressBarState.Inactive &&
                currentState !== ProgressBarState.Paused &&
                currentState !== ProgressBarState.Waiting &&
                (
                    <ProgressPanel variant="determinate" value={progress} displayed={isVisible}/> 
                )
            }
            { (currentState === ProgressBarState.Paused || currentState === ProgressBarState.Waiting) &&
                <WaveAnimation
                    variant='rectangular'
                    progress={currentState === ProgressBarState.Paused ? ProgressBarConstants.QUEUED_MAX_VALUE : progress}
                    displayed={isVisible}
                />
            }
        </>
    );
};

export default ProgressBar;

const ProgressPanel = styled(LinearProgress)<{displayed: boolean}>(({ displayed, theme }) => ({
    position: 'absolute',
    top: '65px',
    left: 0,
    right: 0,
    bottom: 0,

    display: 'flex',
    background: 'transparent',

    visibility: displayed ? 'visible' : 'hidden',
    zIndex: '10',

    '& .MuiLinearProgress-bar': {
        borderRadius: '6px',
        background: `linear-gradient(90deg, ${theme.palette.primary.progress1}, ${theme.palette.primary.progress2})`,
        transition: 'background 0.5s',
        position: 'absolute',
        left: 0,
        bottom: 0,
        top: 0,
        maxHeight: '4px'
    }
}));

const WaveAnimation = styled(Skeleton)<{progress: number, displayed: boolean}>
(({ progress, displayed, theme }) => ({
    position: 'absolute',
    top: '65px',
    left: 0,
    right: 0,
    bottom: 0,

    height: '4px',
    width: `${progress}%`,
    borderRadius: '6px',

    background: progress === ProgressBarConstants.QUEUED_MAX_VALUE ?
        theme.palette.primary.progress2 :
        `linear-gradient(90deg, ${theme.palette.primary.progress1}, ${theme.palette.primary.progress2})`,
    opacity: 0.5,

    visibility: displayed ? 'visible' : 'hidden',
    zIndex: '10',
    
}));