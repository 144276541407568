import React, { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import SelectCanvasArea from '@root/lib/SelectCanvas/SelectCanvasArea';
import DrawCanvas from '@root/lib/DrawCanvas/DrawCanvas';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { useCurate } from '@hooks/curate/useCurate';
import { useDrag } from '@root/context/DragContext/useDrag';
import MousePointer from './MousePointer';
import EraserTool from './EraserTool';
import CreateNewSketchModal from './CreateNewSketchModal';
import { BrushDrawingSettings } from './BrushTool';
import BorderRadiusFilter from '@root/assets/filters';
import { CuratePageConstants } from '@root/utils/constants';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { CurateTools } from '@root/utils/constants/enums';
import { ImageHelpers } from '@root/utils/helpers';
import { AuxiliaryCanvas } from './CuratedCanvasComponents';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';

const CuratedCanvas = () => {
    const imageRef = useRef(null);
    const wrapperRef = useRef(null);
    const zoomWrapperRef = useRef(null);
    const canvasMask = useRef(null);
    const sketchMask = useRef(null);

    const observer = new ResizeObserver(() => setSizes());

    const { activeImageUrlSD, activeImageLayerIdSD } = useStyleDrive();
    const { activeImageUrlMix, activeImageLayerIdMix } = useMixImage();
    const {
        activeImageUrlSceneShift,
        activeImageLayerIdSceneShift
    } = useSceneShift();

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const { setDraggedImage } = useDrag();
    const [isShiftDown, setShiftDown] = useState(false);

    const [canvasSelector, setCanvasSelector] = useState('');
    const [canvasBrush, setCanvasBrush] = useState('');
    
    const [canvasSketch, setCanvasSketch] = useState('');

    const [historyPointsLasso, setHistoryPointsLasso] = useState([]);
    const [actualPointsLasso, setActualPointsLasso] = useState([]);

    const [historyPointsSketch, setHistoryPointsSketch] = useState([]);
    const [actualPointsSketch, setActualPointsSketch] = useState([]);

    const [modalState, setModalState] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundSketch, setBackgroundSketch] = useState('');

    const [pointerPosition, setPointerPosition] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [updatedLayerId, setUpdatedLayerId] = useState('');

    const {
        activeTool,
        toggleTool,
        isRedoUsed,
        setRedoUsed,
        isUndoUsed,
        setUndoUsed,
        setRedoDisable,
        setUndoDisable,
        setInputMask,
        canvasImageSrc,
        updateLayerImagePath,
        lastVisibleLayerId,
        setCanvasLoad,
        color,
        brushSize,
        eraserSize,
        isEraserSelected,
        toggleMetadataDialog,
        isMetadataDialogActive,
        selectLayerByOrderIndex,
        layers,
        canvasImageSketchSrc,
        selectRequired,
        setActiveImageDimensions
    } = useCurate();

    const onDragStart = async () => {
        const file = await ImageHelpers.getImageFile(canvasImageSrc);
        setDraggedImage(file);
    };
    const onDragEnd = () => {
        setDraggedImage(null);
    };

    const openMetadataDialog = (event) => {
        if (event.detail === 2) {
            if ((activeTool === CurateTools.StyleDrive && activeImageLayerIdSD) ||
                (activeTool === CurateTools.MixImages && activeImageLayerIdMix) ||
                (activeTool === CurateTools.SceneShift && activeImageLayerIdSceneShift)) {

                const toolActiveImageLayerId = getCreationToolActiveLayerId();

                const activeImageLayerIndex = layers.findIndex((el) => el.id === toolActiveImageLayerId);

                selectLayerByOrderIndex(layers[activeImageLayerIndex].orderIndex);
            }
            toggleMetadataDialog();
        }
    };

    const getCreationToolActiveLayerId = () => {
        switch (activeTool) {
            case  CurateTools.StyleDrive:
                return activeImageLayerIdSD;
            case  CurateTools.MixImages:
                return activeImageLayerIdMix;
            case  CurateTools.SceneShift:
                return activeImageLayerIdSceneShift;
        }
    };

    const onMouseDown = (e) => {

        if (activeTool === CurateTools.Lasso) {
            if (isShiftDown) {
                setHistoryPointsLasso([...actualPointsLasso]);
            } else {
                canvasSelector.clearCanvas();
                setHistoryPointsLasso([]);
            }

            canvasSelector.onMouseDown(e);
            document.addEventListener('mouseup', onMouseUpLasso, { once: true });
            document.addEventListener('touchend', onMouseUpLasso, { once: true });
        }

        if (activeTool === CurateTools.Brush) {
            setIsDrawing(true);
            setHistoryPointsSketch([...actualPointsSketch]);

            canvasBrush.onMouseDown(e);
            document.addEventListener('mouseup', onMouseUpDraw, { once: true });
            document.addEventListener('touchend', onMouseUpDraw, { once: true });
        }
    };
    const onMouseMove = (e) => {

        if (activeTool === CurateTools.Lasso) {
            canvasSelector.onMouseMove(e);
        }

        if (activeTool === CurateTools.Brush) {
            const position = updatePointerPosition(e, brushSize, canvasMask, isDrawing);
            setPointerPosition(position);
            canvasBrush.onMouseMove(e);
        }
    };

    const onMouseUpDraw = () => {
        setIsDrawing(false);

        canvasBrush.onMouseUp();
        canvasBrush.points.length &&
            setHistoryPointsSketch((prev) => [
                ...prev,
                [...canvasBrush.points],
            ]);
    };

    const onMouseUpLasso = () => {
        canvasSelector.onMouseUp();
        canvasSelector.points.length &&
            setHistoryPointsLasso((prev) => [
                ...prev,
                [...canvasSelector.points],
            ]);
    };

    const reselectAreas = () => {
        if (activeTool === CurateTools.Lasso) {
            canvasSelector.clearCanvas();

            actualPointsLasso.forEach((element) => {
                canvasSelector.points = element;
                canvasSelector.renderSelection();
                canvasSelector.endPath();
            });
        }
        if (activeTool === CurateTools.Brush) {
            canvasBrush.clearCanvas();

            actualPointsSketch.forEach((element) => {
                canvasBrush.points = element;
                canvasBrush.renderSelection();
            });
        }
    };

    const setSizes = () => {
        let width;
        let height;
        const containerHeight = wrapperRef.current.clientHeight;
        const containerWidth = wrapperRef.current.clientWidth;
        const imageHeight = imageRef.current?.naturalHeight;
        const imageWidth = imageRef.current?.naturalWidth;

        // TODO: Usage of the setActiveImageDimensions should be removed when the metadata is fixed
        setActiveImageDimensions({width: imageWidth, height: imageHeight});
        if (canvasImageSrc) {
            if (!imageHeight || !imageWidth) {
                return;
            }

            if (imageHeight / containerHeight < imageWidth / containerWidth) {
                width = containerWidth;
                height = (containerWidth / imageWidth) * imageHeight;
            } else {
                height = containerHeight;
                width = (containerHeight / imageHeight) * imageWidth;
            }
        } else {
            width = wrapperRef.current.clientWidth;
            height = wrapperRef.current.clientHeight;
        }

        setWidth(width);
        setHeight(height);
    };

    const createMask = () => {
        const canvas = document.createElement('canvas');

        canvas.width = canvasMask.current.clientWidth;
        canvas.height = canvasMask.current.clientHeight;

        const mask = new SelectCanvasArea(canvas, canvas);
        mask.clearCanvas(canvas.width, canvas.height);
        mask.ctx.fillStyle = 'black';
        mask.ctx.fillRect(0, 0, canvas.width, canvas.height);

        actualPointsLasso.forEach((element) => {
            mask.points = element;
            mask.fillStyle = 'white';
            mask.isLineDash = false;
            mask.lineWidth = 1;
            mask.renderSelection();
            mask.endPath();
        });

        const image = new Image();
        image.src = canvas.toDataURL('image/png');
        image.addEventListener('load', () => {
            canvas.width = imageRef.current.naturalWidth;
            canvas.height = imageRef.current.naturalHeight;
            mask.clearCanvas(canvas.width, canvas.height);
            mask.ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            setInputMask(canvas.toDataURL('image/png'));
        });
    };

    const loadImage = (img) =>
        new Promise((resolve, reject) => {
            img.crossOrigin = 'anonymous';
            img.onload = () => resolve(img);
            img.onerror = reject;
    });

    const createSketch = async (background, sketchImage) => {
        const canvas = document.createElement('canvas');
        
        canvas.width = sketchImage?.naturalWidth || background.naturalWidth;
        canvas.height = sketchImage?.naturalHeight || background.naturalHeight;

        const sketch = new DrawCanvas(canvas, canvas);
        sketch.color = canvasBrush.color;
        sketch.diameter = canvasBrush.diameter;
        sketch.clearCanvas(canvas.width, canvas.height);

        if (sketchImage) {
            sketch.ctx.drawImage(sketchImage, 0, 0);
        }

        const xScale = canvas.width / canvasMask.current.clientWidth;
        const yScale = canvas.height / canvasMask.current.clientHeight;

        sketch.ctx.scale(xScale, yScale);

        actualPointsSketch.forEach((element) => {
            sketch.points = element;
            sketch.renderSelection();
        });

        const image = canvas.toDataURL('image/png');

        await updateLayerImagePath(image);
    };

    const updatePointerPosition = (event, size, canvasMask, isDrawing) => {
        const point = event?.clientX
        ? { 
            x: event?.clientX,
            y: event?.clientY}
        : {
            x: event?.touches[0].clientX,
            y: event?.touches[0].clientY,
        };

        const canvas = canvasMask.current;
        if (canvas && !isDrawing) {
            const rect = canvas.getBoundingClientRect();
            const x = point.x;
            const y = point.y - rect.top;

            if (x >= (rect.left + size) &&
                x < (rect.left + width - size) &&
                y >= size / 2 &&
                y < (height - size)) {

                return { x, y };
            } else {

                return null;
            }
        }
    };

    const drawBrushInfo = (brashSketchImageUrl, drawSketch = false) => {
        const brushImg = new Image();
        brushImg.src = brashSketchImageUrl;

        setBackgroundSketch(brushImg);
        
        loadImage(brushImg).then(() => {
            canvasSketch.width = sketchMask.current.clientWidth;
            canvasSketch.height = sketchMask.current.clientHeight;
            if (drawSketch) {
                canvasSketch.ctx.drawImage(brushImg, 0, 0, canvasSketch.width, canvasSketch.height);
            }
        });
    };

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Shift') {
                setShiftDown(true);
            }
        });
        window.addEventListener('keyup', (e) => {
            if (e.key === 'Shift') {
                setShiftDown(false);
            }
        });

        return () => {
            window.removeEventListener('keydown', (e) => {
                if (e.key === 'Shift') {
                    setShiftDown(true);
                }
            });
            window.removeEventListener('keyup', (e) => {
                if (e.key === 'Shift') {
                    setShiftDown(false);
                }
            });
        };
    }, []);

    useEffect(() => {
        if (!canvasMask.current || canvasSelector || canvasBrush) {
            return;
        }
        const selector = new SelectCanvasArea(
            canvasMask.current,
            canvasMask.current,
        );
        setCanvasSelector(selector);

        const brush = new DrawCanvas(canvasMask.current, canvasMask.current);
        setCanvasBrush(brush);
    }, [canvasMask.current]);

    useEffect(() => {
        if (!sketchMask.current) {
            return;
        }

        const sketch = new DrawCanvas(sketchMask.current, sketchMask.current);
        setCanvasSketch(sketch);
    }, [sketchMask.current]);

    useEffect(() => {
        setActualPointsLasso([...historyPointsLasso]);
        setActualPointsSketch([...historyPointsSketch]);
    }, [historyPointsLasso, historyPointsSketch]);

    useEffect(() => {
        if (!canvasSelector || activeTool !== CurateTools.Lasso) {
            return;
        }

        setRedoDisable(actualPointsLasso.length === historyPointsLasso.length);
        setUndoDisable(actualPointsLasso.length === 0);

        if (isRedoUsed || isUndoUsed) {
            reselectAreas();
            setUndoUsed(false);
            setRedoUsed(false);
        }

        actualPointsLasso.length
            ? imageRef.current && createMask()
            : setInputMask('');
    }, [actualPointsLasso]);

    useEffect(() => {
        if (!canvasBrush || activeTool !== CurateTools.Brush) {
            return;
        }

        setRedoDisable(
            actualPointsSketch.length === historyPointsSketch.length,
        );
        setUndoDisable(actualPointsSketch.length === 0);

        if (isRedoUsed || isUndoUsed) {
            reselectAreas();
            setUndoUsed(false);
            setRedoUsed(false);
        }

        actualPointsSketch.length === 0 &&
            canvasBrush.clearCanvas(width, height);

        setUpdatedLayerId(lastVisibleLayerId);
    }, [actualPointsSketch]);

    useEffect(() => {
        if (!canvasBrush || activeTool !== CurateTools.Brush || !historyPointsSketch.length) {
            return;
        }

        if (selectRequired && updatedLayerId !== lastVisibleLayerId) {
            setCanvasLoad(false);
            createSketch(backgroundImage, backgroundSketch);
        }

    }, [selectRequired]);

    useEffect(() => {
        if (selectRequired || updatedLayerId !== lastVisibleLayerId ||
            activeTool !== CurateTools.Brush || !historyPointsSketch.length) {
            return;
        }
            if (updatedLayerId === lastVisibleLayerId) {
                setCanvasLoad(false);
                createSketch(backgroundImage, backgroundSketch);
            }

    }, [actualPointsSketch]);

    useEffect(() => {
        if (!canvasSelector) {
            return;
        }

        canvasSelector.enable = activeTool === CurateTools.Lasso;
        reselectAreas();
        setRedoDisable(
            activeTool === CurateTools.Lasso
                ? actualPointsLasso.length === historyPointsLasso.length
                : true,
        );
        setUndoDisable(activeTool === CurateTools.Lasso ? actualPointsLasso.length === 0 : true);

    }, [activeTool, canvasSelector]);

    useEffect(() => {
        if (!canvasBrush) {
            return;
        }

        canvasBrush.enable = activeTool === CurateTools.Brush;
        if (!canvasImageSrc && activeTool === CurateTools.Brush) {
            setModalState(true);
        }

        if (lastVisibleLayerId && activeTool === CurateTools.Brush) {
            canvasBrush.color = isEraserSelected ? 
                CuratePageConstants.DEFAULT_ERASER_COLOR : color;

            canvasBrush.diameter = isEraserSelected ? eraserSize : brushSize;

            const img = new Image();
            img.src = canvasImageSrc;
            setBackgroundImage(img);

            loadImage(img).then(() => {
                setHistoryPointsSketch([]);
                if (canvasImageSketchSrc) {
                    drawBrushInfo(canvasImageSketchSrc, true);
                } else {
                    setBackgroundSketch(null);
                }
            });
        }
    }, [activeTool, lastVisibleLayerId, canvasBrush]);

    useEffect(() => {
        if (activeTool !== CurateTools.Brush || !canvasBrush) {
            return;
        }

        if (canvasImageSrc) {
            canvasBrush.color = isEraserSelected ? CuratePageConstants.DEFAULT_ERASER_COLOR : color;
            canvasBrush.diameter = isEraserSelected ? eraserSize : brushSize;   
        }
    }, [color, brushSize, eraserSize, isEraserSelected]);

    useEffect(() => {
        if (activeTool !== CurateTools.Brush || !canvasBrush) {
            return;
        }

        canvasBrush.clearCanvas(
            canvasMask.current.clientWidth,
            canvasMask.current.clientHeight,
        );

        if (sketchMask?.current) {
            canvasSketch.clearCanvas(
                sketchMask.current.clientWidth,
                sketchMask.current.clientHeigh
            );
        }
    }, [lastVisibleLayerId]);

    useEffect(() => {
        if (!canvasSketch) {
            return;
        }

        if (canvasImageSketchSrc) {
            drawBrushInfo(canvasImageSketchSrc, true);
            setHistoryPointsSketch([]);
        }
    }, [height, width]);

    useEffect(() => {
        if (!canvasImageSrc && !modalState) {
            toggleTool();
        }
    }, [modalState]);

    useEffect(() => {
        if (isUndoUsed) {
            activeTool === CurateTools.Lasso &&
                setActualPointsLasso((prev) => prev.slice(0, -1));

            if (activeTool === CurateTools.Brush) {
                setCanvasLoad(false);

                setActualPointsSketch((prev) => prev.slice(0, -1));
            }
        }
    }, [isUndoUsed]);

    useEffect(() => {
        if (isRedoUsed) {
            activeTool === CurateTools.Lasso &&
                setActualPointsLasso((prev) => [
                    ...prev,
                    historyPointsLasso[actualPointsLasso.length],
                ]);

            if (activeTool === CurateTools.Brush) {
                setCanvasLoad(false);

                setActualPointsSketch((prev) => [
                    ...prev,
                    historyPointsSketch[actualPointsSketch.length],
                ]);
            }
        }
    }, [isRedoUsed]);

    useEffect(() => {

        // TODO: temporary solution, should be removed when the metadata is fixed
        if (imageRef.current) {
            const imageHeight = imageRef.current?.naturalHeight;
            const imageWidth = imageRef.current?.naturalWidth;

            setActiveImageDimensions({width: imageWidth, height: imageHeight});
        }

        if (wrapperRef.current && imageRef.current) {
            observer.observe(imageRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [imageRef.current, wrapperRef.current]);

    useEffect(() => {
        if (zoomWrapperRef.current) {
            observer.observe(imageRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, [zoomWrapperRef.current]);

    return (
        <CanvasWrapper ref={wrapperRef}>
            {(canvasImageSrc || activeImageUrlSD || activeImageUrlMix || activeImageUrlSceneShift) &&
                activeTool !== CurateTools.Lasso &&
                activeTool !== CurateTools.Eraser &&
                activeTool !== CurateTools.Brush && (
                    <ImageBox 
                        ref={zoomWrapperRef}
                        width={width}
                        onClick={openMetadataDialog}>
                        <TransformWrapper
                                disabled={isMetadataDialogActive}
                                disablePadding={true}
                                limitToBounds={true}>
                            <TransformComponent>
                                { canvasImageSketchSrc &&
                                    activeTool !== CurateTools.StyleDrive &&
                                    activeTool !== CurateTools.MixImages ? (
                                    <BackgroundImageWithOverlay
                                        ref={imageRef}
                                        onLoad={setSizes}
                                        src={canvasImageSketchSrc}
                                        path={canvasImageSrc}
                                    />)
                                    :
                                    <BackgroundImage
                                        onClick={openMetadataDialog}
                                        onLoad={setSizes}
                                        ref={imageRef}
                                        draggable={true}
                                        onDragStart={onDragStart}
                                        onDragEnd={onDragEnd}
                                        src={((activeTool === CurateTools.StyleDrive && activeImageUrlSD) ||
                                            (activeTool === CurateTools.MixImages && activeImageUrlMix) ||
                                            (activeTool === CurateTools.SceneShift && activeImageUrlSceneShift) ||
                                            canvasImageSrc
                                    )}/>
                                }
                            </TransformComponent>
                        </TransformWrapper>
                    </ImageBox>
                )
            }

            {canvasImageSrc &&
                (((activeTool === CurateTools.Lasso || activeTool === CurateTools.Eraser) && !canvasImageSketchSrc) ||
                activeTool === CurateTools.Brush) && (
                <BackgroundImage
                    onClick={openMetadataDialog}
                    onLoad={setSizes}
                    ref={imageRef}
                    draggable={false}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    src={activeTool === CurateTools.Brush ? backgroundImage.src : canvasImageSrc}
                />
            )}

            {canvasImageSrc && canvasImageSketchSrc &&
                (activeTool === CurateTools.Lasso || activeTool === CurateTools.Eraser) && (
                <BackgroundImageWithOverlay
                    onClick={openMetadataDialog}
                    onLoad={setSizes}
                    ref={imageRef}
                    draggable={false}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    src={canvasImageSketchSrc}
                    path={canvasImageSrc}
                />
            )}
            
            <BorderRadiusFilter/>

                { activeTool === CurateTools.Eraser &&
                    <EraserTool
                        imageRef={imageRef}
                        updatePointerPosition={updatePointerPosition}
                        width={width}
                        height={height}/>
                }

                <SketchCanvas
                    display={activeTool === CurateTools.Brush && backgroundSketch ? 1 : 0}
                    width={width}
                    height={height}
                    ref={sketchMask}>
                </SketchCanvas>

                <AuxiliaryCanvas
                    isTransparent={isEraserSelected}
                    display={(activeTool === CurateTools.Lasso || (activeTool === CurateTools.Brush)) ? 1 : 0}
                    onMouseDown={onMouseDown}
                    onTouchStart={onMouseDown}
                    onMouseMove={onMouseMove}
                    onTouchMove={onMouseMove}
                    width={width}
                    height={height}
                    ref={canvasMask}>
                </AuxiliaryCanvas>

                { activeTool !== CurateTools.Eraser && pointerPosition && !isDrawing &&
                    <MousePointer
                        size={isEraserSelected ? eraserSize : brushSize}
                        position={pointerPosition}
                        color={isEraserSelected ? CuratePageConstants.DEFAULT_BRUSH_POINTER_COLOR : color}
                        onMouseDown={onMouseDown}/>
                    
                 }
                 
            <CreateNewSketchModal
                open={modalState}
                close={() => setModalState(false)}
            />
            {activeTool === CurateTools.Brush && (
                <BrushDrawingSettingsWrapper>
                    <BrushDrawingSettings />
                </BrushDrawingSettingsWrapper>
            )}
        </CanvasWrapper>
    );
};

export default CuratedCanvas;

const CanvasWrapper = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '&:hover > button': {
        opacity: 1,
        background: theme.palette.primary.light,
    },

    userSelect: 'none'
}));

const SketchCanvas = styled('canvas')(({ display }) => ({
    position: 'absolute',
    display: !display && 'none',
    touchAction: 'none',
    opacity: 0.7,
}));

const BrushDrawingSettingsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',

    // 169px - half of the brush settings toolbar width
    right: 'calc(50% - 169px)',
    top: 'calc(100% + 25px)',

    '& svg': {
        fontSize: '17px'
    },
}));

const ImageBox = styled(Box)(({width, height}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    
    position: 'relative',
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : '100%',

    '& .react-transform-wrapper': {
        width: '100%',
        height: '100%',
        borderRadius: '15px'
    },

    '& .react-transform-component': {
        width: '100%',
        height: '100%',
        borderRadius: '15px',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

}));

const BackgroundImage = styled('img')(() => ({
    height: '100%',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    filter: 'url("#BorderRadiusFilter")'
}));

const BackgroundImageWithOverlay = styled('img')(({path}) => ({
    height: '100%',
    width: '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    filter: 'url("#BorderRadiusFilter")',
    position: 'absolute',
    background:  `no-repeat url(${path})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center'

}));
