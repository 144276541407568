import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ListViewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 22 24'}
    {...props}>
        <path d="M4 7C4 6.44772 4.42495 6 4.94915 6C5.47335 6 5.89831 6.44772 5.89831 7C5.89831 7.55228 5.47335 8 4.94915 8C4.42495 8 4 7.55228 4 7Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 6C4.44772 6 4 6.44772 4 7C4 7.55228 4.44772 8 5 8C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6Z" fill="currentColor"/>
        <path d="M4 12.0977C4 11.5454 4.42495 11.0977 4.94915 11.0977C5.47335 11.0977 5.89831 11.5454 5.89831 12.0977C5.89831 12.6499 5.47335 13.0977 4.94915 13.0977C4.42495 13.0977 4 12.6499 4 12.0977Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 11.0977C4.44772 11.0977 4 11.5454 4 12.0977C4 12.6499 4.44772 13.0977 5 13.0977C5.55228 13.0977 6 12.6499 6 12.0977C6 11.5454 5.55228 11.0977 5 11.0977Z" fill="currentColor"/>
        <path d="M4 17C4 16.4477 4.42495 16 4.94915 16C5.47335 16 5.89831 16.4477 5.89831 17C5.89831 17.5523 5.47335 18 4.94915 18C4.42495 18 4 17.5523 4 17Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M5 16C4.44772 16 4 16.4477 4 17C4 17.5523 4.44772 18 5 18C5.55228 18 6 17.5523 6 17C6 16.4477 5.55228 16 5 16Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.03369 7.00391C8.03369 6.58969 8.3524 6.25391 8.74556 6.25391H17.2879C17.6811 6.25391 17.9998 6.58969 17.9998 7.00391C17.9998 7.41812 17.6811 7.75391 17.2879 7.75391H8.74556C8.3524 7.75391 8.03369 7.41812 8.03369 7.00391Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.288 12.75L8.74559 12.7505C8.35244 12.7505 8.03371 12.4147 8.03369 12.0005C8.03367 11.5863 8.35237 11.2505 8.74552 11.2505L17.2879 11.25C17.681 11.25 17.9998 11.5857 17.9998 12C17.9998 12.4142 17.6811 12.75 17.288 12.75Z" fill="currentColor"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.03369 16.998C8.03369 16.5838 8.3524 16.248 8.74556 16.248H17.2879C17.6811 16.248 17.9998 16.5838 17.9998 16.998C17.9998 17.4123 17.6811 17.748 17.2879 17.748H8.74556C8.3524 17.748 8.03369 17.4123 8.03369 16.998Z" fill="currentColor"/>
    </SvgIcon>
  );
};
