import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ImageInfluenceInfoProps } from './ImageInfluenceInfo.types';

const ImageInfluenceInfo = ({ title, value }: ImageInfluenceInfoProps): React.JSX.Element => {
  return (
    <InfluenceInfo>
        <InfoLabel variant={'body16'}>
            {title}
        </InfoLabel>
        <Typography variant={'body16'}>
            {value}
        </Typography>
    </InfluenceInfo>
  );
};

export default ImageInfluenceInfo;

const InfluenceInfo = styled(Box)(() => ({
    maxWidth: '180px',
    display: 'flex',
    justifyContent: 'space-between'
}));

const InfoLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.inactive,
}));