export enum RequestErrorCode {
    AUTHORIZATION_ERROR = 'AUTHORIZATION_ERROR',
    SERVER_ERROR = 'SERVER_ERROR',
    MODEL_NOT_FOUND = 'MODEL_NOT_FOUND',
    MODEL_ALREADY_EXISTS = 'MODEL_ALREADY_EXISTS',
    CURATE_LAYER_NOT_FOUND = 'CURATE_LAYER_NOT_FOUND',
    CURATE_SESSION_NOT_FOUND = 'CURATE_SESSION_NOT_FOUND',
    IMAGE_NOT_FOUND = 'IMAGE_NOT_FOUND',
    FILE_NOT_FOUND = 'FILE_NOT_FOUND',
    TUNE_NOT_FOUND = 'TUNE_NOT_FOUND',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    IMAGE_ENHANCEMENT_NOT_FOUND = 'IMAGE_ENHANCEMENT_NOT_FOUND',
    PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
    PROJECT_ALREADY_EXISTS = 'PROJECT_ALREADY_EXISTS',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
    EXCEED_FILE_SIZE_LIMIT = 'EXCEED_FILE_SIZE_LIMIT',
    USER_EXIST = 'USER_EXIST',
    ERROR_MODEL = 'ERROR_MODEL',
    BAD_INPUT = 'BAD_INPUT',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    JOB_NOT_FOUND = 'JOB_NOT_FOUND',
    TRAINING_IMAGE_NOT_FOUND = 'TRAINING_IMAGE_NOT_FOUND',
    MODEL_OTP_NOT_FOUND = 'MODEL_OTP_NOT_FOUND',
    MODEL_OTP_INVALID = 'MODEL_OTP_INVALID',
    MODEL_DELETE_ERROR = 'MODEL_DELETE_ERROR',
    MODEL_UPDATE_ERROR = 'MODEL_UPDATE_ERROR',
    ACCESS_DENIED = 'ACCESS_DENIED',
    MODEL_3D_DATA_NOT_FOUND = 'MODEL_3D_DATA_NOT_FOUND',
    MODEL_3D_DATA_DELETE_ERROR = 'MODEL_3D_DATA_DELETE_ERROR',
    MODEL_3D_POSE_NOT_FOUND = 'MODEL_3D_POSE_NOT_FOUND',
    MODEL_3D_POSE_DELETE_ERROR = 'MODEL_3D_POSE_DELETE_ERROR',
    SCENE_SESSION_NOT_FOUND = 'SCENE_SESSION_NOT_FOUND',
    SCENE_LAYER_NOT_FOUND = 'SCENE_LAYER_NOT_FOUND',
    LICENSE_NOT_FOUND = 'LICENSE_NOT_FOUND'
}