import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ZipFileIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 42"
            {...props}>
            <path d="M8.36744 39.8099H3.99902C2.34217 39.8099 0.999023 38.4668 0.999023 36.8099V3.91406C0.999023 2.25721 2.34217 0.914062 3.99902 0.914062H19.61C20.4299 0.914062 21.214 1.24963 21.7801 1.84272L28.1692 8.53655C28.7018 9.09462 28.999 9.83643 28.999 10.6079V36.8099C28.999 38.4668 27.6559 39.8099 25.999 39.8099H21.6306M18.3148 32.3883V39.9141C18.3148 40.4663 17.8671 40.9141 17.3148 40.9141H12.6832C12.1309 40.9141 11.6832 40.4663 11.6832 39.9141V32.3883L12.7885 30.5349H17.2095L18.3148 32.3883Z" stroke="currentColor" strokeWidth="1.3125" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M17.9611 26.7901H15.8559L15.0664 26.4217V25.6848L15.8559 25.3164H17.9611L18.7506 25.6848V26.4217L17.9611 26.7901Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M14.2766 23.8428H12.1713L11.3818 23.4744V22.7376L12.1713 22.3691H14.2766L15.066 22.7376V23.4744L14.2766 23.8428Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M18.6994 21.6319H16.5942L15.8047 21.2635V20.5266L16.5942 20.1582H18.6994L19.4889 20.5266V21.2635L18.6994 21.6319Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M14.2766 19.4209H12.1713L11.3818 19.0525V18.3157L12.1713 17.9473H14.2766L15.066 18.3157V19.0525L14.2766 19.4209Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M18.6994 16.4737H16.5942L15.8047 16.1053V15.3684L16.5942 15H18.6994L19.4889 15.3684V16.1053L18.6994 16.4737Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M13.5422 14.2647H11.4369L10.6475 13.8963V13.1594L11.4369 12.791H13.5422L14.3317 13.1594V13.8963L13.5422 14.2647Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M18.6994 12.0538H16.5942L15.8047 11.6853V10.9485L16.5942 10.5801H18.6994L19.4889 10.9485V11.6853L18.6994 12.0538Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M13.5422 9.84282H11.4369L10.6475 9.4744V8.73756L11.4369 8.36914H13.5422L14.3317 8.73756V9.4744L13.5422 9.84282Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M17.9611 6.89556H15.8559L15.0664 6.52714V5.7903L15.8559 5.42188H17.9611L18.7506 5.7903V6.52714L17.9611 6.89556Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
            <path d="M12.8039 4.68462H10.6987L9.90918 4.3162V3.57936L10.6987 3.21094H12.8039L13.5934 3.57936V4.3162L12.8039 4.68462Z" stroke="currentColor" strokeWidth="0.9625" strokeLinecap="round" strokeLinejoin="round" fill='none'/>

        </SvgIcon>
    );
};
