import TunesContext from '@root/context/TunesContext/TunesContext';
import { TuneWithConfiguration } from '@root/types/commonTypes';
import { TuneImageDto } from '@root/types/dto';
import { useContext, useEffect, useState } from 'react';

interface TuneImagesNavigationItems {
    startImageIndex: number | null;
    setStartImageIndex: (value: number | null) => void;

    currentImage: TuneImageDto | null;
    setCurrentImage: (value: TuneImageDto) => void;

    startImage: TuneImageDto | null;
    setStartImage: (value: TuneImageDto) => void

    currentTune: TuneImageDto[];
    setCurrentTune: (value: TuneImageDto[]) => void;

    currentTuneIndex: number;
    currentImageIndex: number;
    currentTuneControlImages: any;
    currentTuneConfig: any;
    isNextDisabled: boolean;
    isPrevDisabled: boolean;

    moveToNextImage: () => void;
    moveToPreviousImage: () => void;
}

export const useTuneImagesNavigation = (): TuneImagesNavigationItems => {
    const { setActiveImageSrc, tunes } = useContext(TunesContext);

    const [startImage, setStartImage] = useState<TuneImageDto | null>(null);
    const [startImageIndex, setStartImageIndex] = useState<number | null>(null);

    const [currentTune, setCurrentTune] = useState<TuneImageDto[]>([]);
    const [currentTuneIndex, setCurrentTuneIndex] = useState<number>(0);

    const [currentImage, setCurrentImage] = useState<TuneImageDto | null>(null);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(-1);

    const [currentTuneControlImages, setCurrentTuneControlImages] = useState<any>(null);
    const [currentTuneConfig, setCurrentTuneConfig] = useState<any>(null);

    const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);
    const [isPrevDisabled, setIsPrevDisabled] = useState<boolean>(false);


    const updateSectionInfo = (tune: TuneWithConfiguration, tuneIndex: number, imageIndex: number): void => {
        setCurrentTune(tune.images);
        setCurrentTuneIndex(tuneIndex);
        setCurrentImageIndex(imageIndex);
        setCurrentImage(tune.images[imageIndex]);
        getTuneConfiguration(tune);
    };

    const getTuneConfiguration = (tune: TuneWithConfiguration) => {
        const config = tune.images.map((el: TuneImageDto) => {
            return JSON.parse(el.Configurations);
        });

        setCurrentTuneConfig(config);

        setCurrentTuneControlImages({
            depth:  {
                path: tune.config?.control_depth_image,
                influence: tune.config?.control_depth_weight
            },
            sketch:{
                path: tune.config?.control_sketch_image,
                influence: tune.config?.control_sketch_weight
            },
        });
    };

    const moveToNextImage = () => {
        if (tunes.length > 1 && currentImageIndex + 1 > currentTune.length - 1) {
            if (currentTuneIndex + 1 > tunes.length - 1) {
                return; 
            } else {
                setActiveImageSrc('');
                const nextTune = tunes[currentTuneIndex + 1];
                updateSectionInfo(nextTune, currentTuneIndex + 1, 0);
            }
        } else {
            setActiveImageSrc('');
            const nextIndex = Math.min(currentImageIndex + 1, currentTune.length - 1);
            const newActiveImage = currentTune[nextIndex];

            setCurrentImage(newActiveImage);
            setCurrentImageIndex(nextIndex);
        }
    };

    const moveToPreviousImage = () => {
        if (tunes.length > 1 && currentImageIndex - 1 < 0) {
            if (currentTuneIndex - 1 < 0) {
                return; 
            } else {
                setActiveImageSrc('');

                const previousTune = tunes[currentTuneIndex - 1];

                if (!previousTune?.images?.length) {
                    return;
                }

                updateSectionInfo(previousTune, currentTuneIndex - 1, previousTune.images.length - 1);
            }
        } else {
            setActiveImageSrc('');

            const prevIndex = Math.max(currentImageIndex - 1, 0);
            const newActiveImage = currentTune[prevIndex];

            setCurrentImage(newActiveImage);
            setCurrentImageIndex(prevIndex);
        }
    };

    useEffect(() => {
        if (currentTuneIndex + 1 > tunes.length - 1) {
            if (currentImageIndex === currentTune.length - 1) {
                setIsNextDisabled(true);
                return;
            }

        } else if (currentTuneIndex - 1 < 0) {
            if (currentImageIndex === 0) {
                setIsPrevDisabled(true);
                return;
            }
        }

        setIsNextDisabled(false);
        setIsPrevDisabled(false);
    }, [currentImageIndex]);
    

    useEffect(() => {
        if (!startImage || startImageIndex === null) {
            return;
        }

        if (tunes.length > 1) {
            const index = tunes?.findIndex((tune: TuneWithConfiguration) => tune.Id === startImage.TuneId);
            setCurrentTune(tunes[index].images);
            setCurrentTuneIndex(index);
            setCurrentImageIndex(startImageIndex);
            getTuneConfiguration(tunes[index]);
        } else {
            getTuneConfiguration(tunes[0]);
            setCurrentTune(tunes[0]);
            setCurrentImageIndex(startImageIndex);
        }

        setCurrentImage(startImage);
    }, [startImage, startImageIndex]);


  return { 
    startImageIndex,
    setStartImageIndex,

    currentImage,
    setCurrentImage,

    startImage,
    setStartImage,

    currentTune,
    setCurrentTune,

    currentTuneIndex,
    currentImageIndex,
    currentTuneControlImages,
    currentTuneConfig,
    isNextDisabled,
    isPrevDisabled,

    moveToNextImage,
    moveToPreviousImage
   };
};
