import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ImportIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M11.4134 17.833L11.8845 17.3852C11.7615 17.2558 11.5908 17.1827 11.4124 17.183C11.234 17.1833 11.0635 17.2569 10.9409 17.3866L11.4134 17.833ZM16.3573 23.0339L15.8862 23.4818L15.8933 23.4892L15.9006 23.4964L16.3573 23.0339ZM19.5837 20.583L20.0117 20.0938C19.7794 19.8906 19.4362 19.8787 19.1905 20.0654L19.5837 20.583ZM5.38944 25.1544L11.8858 18.2794L10.9409 17.3866L4.44455 24.2616L5.38944 25.1544ZM10.9423 18.2808L15.8862 23.4818L16.8284 22.5861L11.8845 17.3852L10.9423 18.2808ZM15.9006 23.4964L18.9881 26.5455L19.9015 25.6205L16.8141 22.5714L15.9006 23.4964ZM16.7505 23.5515L19.9769 21.1006L19.1905 20.0654L15.9641 22.5163L16.7505 23.5515ZM19.1556 21.0722L22.8223 24.2805L23.6784 23.3022L20.0117 20.0938L19.1556 21.0722Z" fill="currentColor"/>
`           <rect x="4.75" y="7.58301" width="18.6667" height="18.6667" rx="2.25" stroke="currentColor" strokeWidth="1.5" fill={'none'}/>
            <path d="M26.8136 11.2699C26.8136 14.3171 24.3383 16.7898 21.2818 16.7898C18.2253 16.7898 15.75 14.3171 15.75 11.2699C15.75 8.22269 18.2253 5.75 21.2818 5.75C24.3383 5.75 26.8136 8.22269 26.8136 11.2699Z" fill="#2B2B2B" stroke="currentColor" strokeWidth="1.5"/>
            <path d="M21.3343 13.6855C21.3343 13.1958 21.3343 10.7265 21.3343 8.48173M21.3343 8.48173L19.1875 10.6245M21.3343 8.48173L23.4811 10.6245" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon>
    );
};
