import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const StyleDriveIcon = (props: SvgIconProps): React.JSX.Element => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 32 32'}
      {...props}>
        <path d="M18.4902 22.25H22.2402M22.2402 22.25H25.9902M22.2402 22.25V18.5M22.2402 22.25V26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill='none'/>
        <path d="M6 12.75V6.75C6 6.55109 6.07902 6.36032 6.21967 6.21967C6.36032 6.07902 6.55109 6 6.75 6H12.75C12.9489 6 13.1397 6.07902 13.2803 6.21967C13.421 6.36032 13.5 6.55109 13.5 6.75V12.75C13.5 12.9489 13.421 13.1397 13.2803 13.2803C13.1397 13.421 12.9489 13.5 12.75 13.5H6.75C6.55109 13.5 6.36032 13.421 6.21967 13.2803C6.07902 13.1397 6 12.9489 6 12.75ZM6 25.25V19.25C6 19.0511 6.07902 18.8603 6.21967 18.7197C6.36032 18.579 6.55109 18.5 6.75 18.5H12.75C12.9489 18.5 13.1397 18.579 13.2803 18.7197C13.421 18.8603 13.5 19.0511 13.5 19.25V25.25C13.5 25.4489 13.421 25.6397 13.2803 25.7803C13.1397 25.921 12.9489 26 12.75 26H6.75C6.55109 26 6.36032 25.921 6.21967 25.7803C6.07902 25.6397 6 25.4489 6 25.25ZM18.5 12.75V6.75C18.5 6.55109 18.579 6.36032 18.7197 6.21967C18.8603 6.07902 19.0511 6 19.25 6H25.25C25.4489 6 25.6397 6.07902 25.7803 6.21967C25.921 6.36032 26 6.55109 26 6.75V12.75C26 12.9489 25.921 13.1397 25.7803 13.2803C25.6397 13.421 25.4489 13.5 25.25 13.5H19.25C19.0511 13.5 18.8603 13.421 18.7197 13.2803C18.579 13.1397 18.5 12.9489 18.5 12.75Z" stroke="currentColor" strokeWidth="1.5" fill='none'/>
      </SvgIcon>
  );
};
