import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled
} from '@mui/material';
import dayjs from 'dayjs';
import CopyValueButton from '@root/components/CopyValueButton';
import { CommonConstants, UsersListDefaults } from '@root/utils/constants';
import {
	LicenseStatusLabels,
	LicenseTypeLabels,
	LicenseValidationErrorLabels,
	ValidationTriggerTypeLabels
} from '@root/utils/constants/enums';
import { DEFAULT_LOG_PAGE_SIZE, useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

enum RowsPerPageOptions {
	FIVE = '5',
	TEN =  '10',
	TWENTY = '20'
}

const LicenseInfoTable = (): React.JSX.Element => {
    const {
		totalLogCount,
		validationLogs,
		logPageSize,
		isLoading,
		pageNumber,
        setPageNumber,
		setLogPageSize,
		getLicenseValidationInfo,
		runLicenseValidation
    } = useLicenseValidation();

    useEffect(() => {
		setLogPageSize(DEFAULT_LOG_PAGE_SIZE);
    }, []);

	const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
		setPageNumber(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setLogPageSize(+event.target.value);
		setPageNumber(0);
	};

	return (
		<Wrapper>
			<ActionRow>
				<ActionButton variant="contained"
					disabled={isLoading}
					onClick={runLicenseValidation}>
					<ActionLabel>{'Run validation'}</ActionLabel>
				</ActionButton>
				<ActionButton variant="contained"
					disabled={isLoading}
					onClick={getLicenseValidationInfo}>
					<ActionLabel>{'Refresh info'}</ActionLabel>
				</ActionButton>
			</ActionRow>

			{isLoading && 
				<ActionRow>
					<Loader>
						<span className="loader"></span>
					</Loader>
				</ActionRow>
			}

			<InfoTableContainer visible={!isLoading ? 1 : 0}>
				<Table>
					<InfoTableHeader>
						<TableRow>
							<HeaderLabel>{'Run at'}</HeaderLabel>
							<HeaderLabel>{'License Signature'}</HeaderLabel>
							<HeaderLabel>{'License Type'}</HeaderLabel>
							<HeaderLabel>{'Status'}</HeaderLabel>
							<HeaderLabel>{'Error Type'}</HeaderLabel>
							<HeaderLabel>{'Message'}</HeaderLabel>
							<HeaderLabel>{'Attempt No'}</HeaderLabel>
							<HeaderLabel>{'Trigger'}</HeaderLabel>
						</TableRow>
					</InfoTableHeader>
					<TableBody>
						
					{(logPageSize && logPageSize > 0
						? validationLogs?.slice(
							pageNumber * logPageSize, 
							pageNumber * logPageSize + logPageSize)
						: validationLogs
					)?.map((row, index) => (
							<TableRow key={index}>
								<InfoCell>{dayjs(row.timeStamp).format(UsersListDefaults.DEFAULT_DATE_FORMAT)}</InfoCell>
								<InfoCell>
									<ActionCell>
										<Typography>{row.licenseSignature || CommonConstants.INFO_NOT_FOUND_LABEL}</Typography>
										{ !!row.licenseSignature && <CopyValueButton value={row.licenseSignature} isLink={false}/> }
									</ActionCell>
								</InfoCell>
								<InfoCell>{row.licenseType ? LicenseTypeLabels[row.licenseType] : CommonConstants.INFO_NOT_FOUND_LABEL}</InfoCell>
								<InfoCell>{LicenseStatusLabels[row.status]}</InfoCell>
								<InfoCell>{row.errorType ? LicenseValidationErrorLabels[row.errorType] : CommonConstants.INFO_NOT_FOUND_LABEL}</InfoCell>
								<InfoCell>{row.message}</InfoCell>
								<InfoCell>{row.attemptNo}</InfoCell>
								<InfoCell>{ValidationTriggerTypeLabels[row.trigger]}</InfoCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Box>
					{(logPageSize &&
						<TablePagination
							component="div"
							rowsPerPageOptions={[
								...Object.values(RowsPerPageOptions).map(Number),
								{ label: 'All', value: -1 }
							]}
							count={totalLogCount}
							rowsPerPage={logPageSize}
							page={pageNumber}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}/>
					)}
				</Box>
			</InfoTableContainer>
		</Wrapper>
	);
};

export default LicenseInfoTable;

const Wrapper = styled(Box)(() => ({
	display: 'flex',
	flexDirection: 'column',
	height: 'calc(100% - 20px)'
}));

const InfoTableContainer = styled(TableContainer)<{visible: number}>(({ theme, visible }) => ({
	opacity: visible ? 1 : 0,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    width: '1300px',

    border: `1px solid ${theme.palette.primary.light}`,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px'
}));

const ActionRow = styled(Box)(() => ({
    alignSelf: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    padding: '20px 0px',
    gap: '20px',
    width: '1300px'
}));

const InfoTableHeader = styled(TableHead)(({ theme }) => ({
    height: '55px',
    backgroundColor: theme.palette.primary.light
}));
  
const HeaderLabel = styled(TableCell)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '18px',
  
    marginBottom: '6px',
    color: theme.palette.neutrals.light1
}));

const InfoCell = styled(TableCell)(() => ({
    maxWidth: '200px',
    width: 'auto',
    whiteSpace: 'normal',
	overflow: 'hidden',
    textOverflow: 'ellipsis'
}));

const ActionCell = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'baseline',
	gap: '5px',

	'& p': {
		maxWidth: '170px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
    },

	'& svg': {
        height: '16px',
        width: '16px',
        color: theme.palette.primary.main,
        cursor: 'pointer'
    }
}));

const ActionButton = styled(Button)(() => ({
    height: '36px',
    width: 'max-content'
}));

const ActionLabel = styled(Typography)(() => ({
    fontFamily: 'Roboto400',
    fontSize: '14px !important',
    lineHeight: '19.6px !important'
}));

const Loader = styled(Box)(() => ({
	width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));
