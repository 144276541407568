import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { EraserIcon } from '@root/assets/icons/EraserIcon';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import { CloseIcon } from '@root/assets/icons/CloseIcon';
import { EraserSettingsPanelProps } from './EraserSettingsPanel.types';
import BrushSlider from '@root/components/BrushSlider';

const ERASER_MIN_SIZE = 1;
const ERASER_MAX_SIZE = 100;
const ERASER_SIZE_SLIDER_STEP = 1;

interface ToolTipContentProps {
    onClose: () => void;
    withSpaceTooltip: boolean;
    withShiftTooltip: boolean;
}

const ToolTipContent = ({
    onClose,
    withSpaceTooltip,
    withShiftTooltip
}: ToolTipContentProps): React.JSX.Element => {
    return (
        <ContentWrapper>
            <InformationIcon/>
            <InformationSection>
                { withSpaceTooltip && 
                    <InformationLabel>{'Hold'}<strong>{' Space '}</strong>{'to pan the panorama'}</InformationLabel> 
                }
                { withShiftTooltip && 
                    <InformationLabel>{'Hold'}<strong>{' Shift '}</strong>{'to mark more areas'}</InformationLabel>
                }
            </InformationSection>
            <IconButton
                disableRipple
                onClick={onClose}>
                    <CloseIcon/>
            </IconButton>
        </ContentWrapper>
    );
};

const EraserSettingsPanel = ({
    eraserSize,
    setEraserSize,
    withSpaceTooltip = true,
    withShiftTooltip = true,
    isDisabled = false
}: EraserSettingsPanelProps): React.JSX.Element => {

    const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false);

    const onChange = (_: Event, newValue: number | number[]) => {
        setEraserSize(newValue as number);
    };
    return (
        <>
            <EraserIcon/>
            <BrushSlider
                name="eraserSize"
                value={eraserSize}
                step={ERASER_MIN_SIZE}
                min={ERASER_SIZE_SLIDER_STEP}
                max={ERASER_MAX_SIZE}
                onChange={onChange}
                disabled={isDisabled}/>
            <Tooltip
                slotProps={{
                    popper: {
                        modifiers: [{
                            name: 'offset',
                            options: { offset: [0, -30] }
                        }]
                    }
                }}
                open={isTooltipVisible}
                placement="right"
                title={
                        <ToolTipContent
                            withShiftTooltip={withShiftTooltip}
                            withSpaceTooltip={withSpaceTooltip}
                            onClose={() => setIsTooltipVisible(false)}/>
                    }>
                <ControlButton
                    active={!isTooltipVisible}
                    disableRipple
                    onClick={() => setIsTooltipVisible(true)}>
                    <InformationIcon/>
                </ControlButton>
            </Tooltip>
        </>
    );
};

export default EraserSettingsPanel;


const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    color: theme.palette.neutrals.inactive1,
    '& svg': {
        color: theme.palette.neutrals.inactive1,
        fontSize: '20px'
    },

    width: 'max-content',
    gap: '10px'
}));

const InformationSection = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    flexDirection: 'column'
}));

const InformationLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutrals.inactive1,

    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '16.6px'
}));

const ControlButton = styled(IconButton)<{active: boolean}>(({ theme, active }) => ({
    opacity: active ? 1 : 0,
    color: theme.palette.neutrals.inactive1,
    padding: 0,
    '& svg': {
        width: '17px',
        height: '17px'
    }
}));
