import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { Box, Typography, styled } from '@mui/material';
import AddImageLarge from '../../../../assets/images/AddImageLarge.png';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { ImageUploaderProps } from './types/ImageUploader.types';

const ImageUploader = ({ isDefault, handleImportImage }: ImageUploaderProps) => {
  return (
    <FileUploader
        handleChange={handleImportImage}
        multiple={true}
        types={['JPEG', 'PNG', 'JPG', 'WEBP']}>
        <Uploader className={isDefault ? 'default' : 'main'}>
            <StyledAddImageIngredientIcon small={!isDefault} />
            <InputLabel>
                choose the image or drag it here
            </InputLabel>
        </Uploader>
    </FileUploader>
  );
};

export default ImageUploader;

const Uploader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    border: `1.5px dashed ${theme.palette.primary.light1}`,
    borderRadius: '15px',

    backgroundColor: theme.palette.primary.grey,
    backgroundImage: `url(${AddImageLarge})` ,
    backgroundSize: '80%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    
    '&.main': {
        width: '373px',
        height: '210px',
    },

    '&.default': {
        width: '597px',
        height: '336px',
    }
}));

const StyledAddImageIngredientIcon = styled(AddImageIngredientIcon)<{small: boolean}>(({ theme, small }) => ({
    display: 'flex',
    marginTop: '24px',
    margin: '24px 14px 14px 14px',
    height: small ? '63px' : '83px',
    width:  small ? '63px' : '83px',
    color:  theme.palette.primary.grey
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.contrastText,
    alignSelf: 'center'
}));
