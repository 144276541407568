import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CopyIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            {...props}>
            <path d="M4.23535 7.34161C4.23535 5.47846 4.23535 4.54622 4.78688 3.96778C5.33778 3.38867 6.22563 3.38867 8.00006 3.38867H9.88241C11.6568 3.38867 12.5447 3.38867 13.0956 3.96778C13.6471 4.54622 13.6471 5.47846 13.6471 7.34161V10.6357C13.6471 12.4989 13.6471 13.4311 13.0956 14.0096C12.5447 14.5887 11.6568 14.5887 9.88241 14.5887H8.00006C6.22563 14.5887 5.33778 14.5887 4.78688 14.0096C4.23535 13.4311 4.23535 12.4989 4.23535 10.6357V7.34161Z" stroke="currentColor" strokeWidth="1.2" fill='none'/>
            <path d="M4.23489 12.6121C3.73566 12.6121 3.25688 12.4039 2.90387 12.0332C2.55086 11.6626 2.35254 11.1598 2.35254 10.6356V6.6827C2.35254 4.19827 2.35254 2.95573 3.08791 2.18425C3.82266 1.41211 5.00603 1.41211 7.37215 1.41211H9.88195C10.3812 1.41211 10.86 1.62034 11.213 1.991C11.566 2.36166 11.7643 2.86439 11.7643 3.38858" stroke="currentColor" strokeWidth="1.2" fill='none'/>
        </SvgIcon>
    );
};
