import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Tooltip, Typography, styled } from '@mui/material';
import ToolbarButton from '../ToolbarButton';
import { ReloadIcon } from '@root/assets/icons/ReloadIcon';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import InfoTagField from '@root/components/InfoTagField';
import InfoModal from '@root/components/InfoModal';
import { prefixBaseUrl } from '@root/utils/url/url';
import { CommonJobTypes, ImageGenerationStatus } from '@root/utils/constants/enums';
import { SceneImageDownloadDialogProps } from './SceneImageDownloadDialog.types';
import { ImageHelpers } from '@root/utils/helpers';
import { InfoTagType } from '@root/components/InfoTagField/InfoTagField.type';
import { SceneLayerConfig } from '@root/types/commonTypes';
import { ScenePageConstants } from '@root/utils/constants';

const DialogTagInfo: { [key in ImageGenerationStatus] : { label: string; type: InfoTagType } } = {
    [ImageGenerationStatus.ERROR]: {
        label: 'ERROR. Please regenerate',
        type: 'error'
    },
    [ImageGenerationStatus.PROCCESSING]: {
        label: 'IN PROGRESS',
        type: 'warning'
    },
    [ImageGenerationStatus.ENQUED]: {
        label: 'IN PROGRESS',
        type: 'warning'
    },
    [ImageGenerationStatus.READY]: {
        label: 'READY',
        type: 'success'
    },
    [ImageGenerationStatus.NONE]: {
        label: 'NOT STARTED',
        type: 'warning'
    }
};

const SceneImageDownloadDialog = ({
    active,
    selectedImage,
    handleClose,
    startGeneration,
    stopGeneration
}: SceneImageDownloadDialogProps): React.JSX.Element => {

    const [dimensions, setDimensions] = useState<string>('');

    const downloadImage = async (): Promise<void> => {
        if (!selectedImage?.filePanoramaPath) {
            return;
        }

        const path = prefixBaseUrl(selectedImage.filePanoramaPath);
        ImageHelpers.downloadPngByUrl(path);
    };

    const stopGenerationHandler = (): void => {
        if (selectedImage) {
            stopGeneration(selectedImage.id, CommonJobTypes.HDR);
        }
    };

    const downloadHdr = async (): Promise<void> => {
        if (!selectedImage?.fileHdrPath) {
            return;
        }

        const path = prefixBaseUrl(selectedImage.fileHdrPath);
        fetch(path)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = `${selectedImage.name}.hdr`;
            a.click();
        });
    };

    useEffect(() => {
        if (!selectedImage) {
            return;
        }
        const config: SceneLayerConfig = JSON.parse(selectedImage.configurations);

        setDimensions(config.high_quality ?
            `${ScenePageConstants.HIGH_RES_PANORAMA_WIDTH} x ${ScenePageConstants.HIGH_RES_PANORAMA_HEIGHT} px` :
            `${ScenePageConstants.PANORAMA_WIDTH} x ${ScenePageConstants.PANORAMA_HEIGHT} px`
        );

    }, [selectedImage]);
    

  return (
    <InfoModal
        isActive={active}
        onClose={handleClose}
        allowAutoClosing={false}
        headerTitle={'360 panorama download options'}>
        <ModalContentRow>
            <ModalRowInfoSection>
                <PreviewSection>
                    { selectedImage?.filePanoramaPath && 
                        <InfoTagField
                            type='success'
                            label={'READY'}/>
                    }
                </PreviewSection>
                <Typography>{'Panorama'}</Typography>
                <SectionTooltip
                    placement='right'
                    title={`Format: PNG, 8-bpc, ${dimensions}`}>
                    <span><InformationIcon></InformationIcon></span>
                </SectionTooltip>
            </ModalRowInfoSection>
            <ModalRowActionSection>
                <ToolbarButton
                    isDisabled={false}
                    clickEvent={downloadImage}>
                    <DownloadIcon/>
                </ToolbarButton>
            </ModalRowActionSection>
        </ModalContentRow>

        <RowDivider/>

        <ModalContentRow>
            <ModalRowInfoSection>
                <PreviewSection>
                    <InfoTagField
                        withLoader={
                            selectedImage?.statusHdrId === ImageGenerationStatus.PROCCESSING ||
                            selectedImage?.statusHdrId === ImageGenerationStatus.ENQUED
                        }
                        type={DialogTagInfo[selectedImage?.statusHdrId || ImageGenerationStatus.NONE].type}
                        label={DialogTagInfo[selectedImage?.statusHdrId || ImageGenerationStatus.NONE].label}/>
                </PreviewSection>
                <Typography>{'HDR'}</Typography>
                <SectionTooltip
                    placement='right'
                    title={`Format: HDR, 32-bpc, ${dimensions}`}>
                    <span><InformationIcon></InformationIcon></span>
                </SectionTooltip>
            </ModalRowInfoSection>

            <ModalRowActionSection>
                { (selectedImage?.statusHdrId === ImageGenerationStatus.READY || 
                    selectedImage?.statusHdrId === ImageGenerationStatus.ERROR) &&
                    <ToolbarButton
                        isDisabled={false}
                        clickEvent={startGeneration}>
                        <ReloadIcon/>
                    </ToolbarButton>
                }
                { selectedImage?.statusHdrId === ImageGenerationStatus.READY &&
                    <ToolbarButton
                        isDisabled={false}
                        clickEvent={downloadHdr}>
                        <DownloadIcon/>
                    </ToolbarButton>
                }
                { !selectedImage?.statusHdrId &&
                    <ActionButton variant="contained"
                        onClick={startGeneration}>
                        {'Generate HDR'}
                    </ActionButton>
                }
                { (selectedImage?.statusHdrId === ImageGenerationStatus.PROCCESSING ||
                    selectedImage?.statusHdrId === ImageGenerationStatus.ENQUED) &&
                    <ActionButton variant="contained"
                        onClick={stopGenerationHandler}>
                        {'Stop'}
                    </ActionButton>
                }
            </ModalRowActionSection>
        </ModalContentRow>
    </InfoModal>
  );
};

export default SceneImageDownloadDialog;

const ModalContentRow = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '47px'
}));

const ModalRowInfoSection = styled(Box)(() => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    gap: '11px',
    position: 'relative',
    height: '100%',

    
    '& .MuiTypography-root': {
        fontFamily: 'Roboto500',
        fontSize: '16px',
        lineHeight: '22px'
    }
}));

const ModalRowActionSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'end',
    gap: '10px'
}));

const RowDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '100%',
    height: '1px'
}));

const ActionButton = styled(Button)(() => ({
    height: '36px',
    gap: '4px',
    fontFamily: 'Roboto500',
    fontSize: '14px !important',
    lineHeight: '19.6px !important'
}));

const PreviewSection = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    gap: '10px',
    left: 0,
    top: 0
}));

const SectionTooltip = styled(Tooltip)(({ theme }) => ({
    width: '20px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    borderRadius: '100%',
    boxSizing: 'border-box',

    '& svg': {
        width: '18px',
        height: '18px',
        color: theme.palette.primary.main
    }
}));
