import React, { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { useScene } from '@root/context/ScenePageContext/useScene';
import { SceneMode } from '@root/utils/constants/enums';
import ScenePanorama from '../ScenePanorama';
import FullSizeImageView from '@root/pages/CuratePage/components/FullSizeImageView';
import PanoramaPlaceholder from './PanoramaPlaceholder';
import EraserSettingsPanel from '../../../../components/EraserSettingsPanel';

const SceneActiveImage = (): React.JSX.Element => {
    const {
        imagesList,
        activeMode,
        toggleFullScreen,
        moveToNextImage,
        moveToPreviousImage,
        isFullScreenActive,
        eraserSize,
        setEraserSize,
        setEraserMask,
        isEraserInProgress,
        selectedImageUrl,
        setIsToolbarDisabled
    } = useScene();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsToolbarDisabled(isLoading);
    }, [isLoading]);

    return (
        <>
            <Wrapper>
                {
                    imagesList.length || selectedImageUrl
                    ?
                        <>
                            <PanoramaWrapper small={true} active={!isLoading}>
                                <ScenePanorama
                                    activeImageUrl={selectedImageUrl}
                                    eraserSize={eraserSize}
                                    activeMode={activeMode}
                                    isDrawingBlocked={isEraserInProgress}
                                    setIsLoading={setIsLoading}
                                    setEraserMask={setEraserMask}/>
                            </PanoramaWrapper>
                            <PanoramaPlaceholder isActive={isLoading} isFullScreen={false} label={'Rendering image'} withLoader={true}/>
                        </>
                    :
                        <PanoramaPlaceholder isActive={true} isFullScreen={false} label={ 'Get started by submitting a prompt '}/>
                }

                <FullSizeImageView
                    active={isFullScreenActive}
                    handleClose={toggleFullScreen}
                    moveToNext={moveToNextImage}
                    moveToPrevious={moveToPreviousImage}>
                        <>
                            <PanoramaWrapper active={!isLoading} small={false}>
                                <ScenePanorama
                                    activeImageUrl={selectedImageUrl}
                                    activeMode={SceneMode.PAN}
                                    setIsLoading={setIsLoading}/>
                            </PanoramaWrapper>
                            <PanoramaPlaceholder isActive={isLoading} isFullScreen={true} label={'Rendering image'} withLoader={true}/>
                        </>
                </FullSizeImageView>
            </Wrapper>
            {activeMode === SceneMode.ERASE && 
                <SettingsWrapper>
                    <EraserSettingsPanel
                        isDisabled={isEraserInProgress || isLoading}
                        eraserSize={eraserSize}
                        setEraserSize={setEraserSize}/>
                </SettingsWrapper>
            }
        </>
    );
};

export default SceneActiveImage;

const Wrapper = styled(Box)(() => ({
    position: 'relative',
    flex: 3,
    height: 'calc(100% - 81px)',
    marginTop: '19px',
    marginBottom: '30px',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));


const PanoramaWrapper = styled(Box)<{ active: boolean; small: boolean }>(({ active, theme, small }) => ({
    display:'flex',
    height: '100%',
    width: small ? '70%' : '100%',
    objectPosition: 'center',
    objectFit: 'contain',
    borderRadius: small ? '15px' : '0px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,

    opacity: active ? '1' : '0',

    ...(!active && { 
        height: 0,
        width: 0,
    }),

    '& canvas': {
        borderRadius: small ? '15px' : '0px',
    }
}));

const SettingsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    // 108px - half of the  settings toolbar width
    right: 'calc(50% - 108px)',

    bottom: '40px',

    gap: '20px',

    '& svg': {
        fontSize: '20px'
    }
}));