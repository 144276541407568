import React, { PropsWithChildren } from 'react';
import { DragOverlay, DropAnimation, defaultDropAnimationSideEffects } from '@dnd-kit/core';

const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.4',
            }
        }
    })
};

const SortableListRowOverlay = ({ children }: PropsWithChildren<unknown>): React.JSX.Element => {
    return (
        <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
    );
};

export default SortableListRowOverlay;
