import * as THREE from 'three';
import {DefaultZoomRange, ZoomRange} from '@utils/camera/ZoomRange';

export class DepixCamera extends THREE.PerspectiveCamera{
    readonly minZoom: number;
    readonly maxZoom: number;
    
    constructor(fov?: number, aspect?: number, near?: number, far?: number, zoomRange?: ZoomRange) {
        super(fov, aspect, near, far);
        this.minZoom = zoomRange?.min ?? DefaultZoomRange.min;
        this.maxZoom = zoomRange?.max ?? DefaultZoomRange.max;
    }

    setZoom(newZoom: number) {
        this.zoom = THREE.MathUtils.clamp(
            newZoom,
            this.minZoom,
            this.maxZoom
        );
        this.updateProjectionMatrix();
    }

}