import React, { useEffect } from 'react';
import { Box, Stack, styled } from '@mui/material';
import SceneToolbar from './components/SceneToolbar';
import SceneForm from './components/SceneForm';
import SceneImagesList from './components/SceneImagesList';
import SceneActiveImage from './components/SceneActiveImage';
import SceneImageDownloadDialog from './components/SceneImageDownloadDialog';
import ProgressBar from '@root/components/ProgressBar';
import { useScene } from '@root/context/ScenePageContext/useScene';

const ScenePage = (): React.JSX.Element => {

    const {
        openLastSession,
        isDownloadDialogActive,
        setIsDownloadDialogActive,
        selectedImage,
        progressState,
        setProgressState,
        progressPercentage,
        generateHdrImage,
        cancelSceneJobGeneration
     } = useScene();

    useEffect(() => {
        openLastSession();
    }, []);

  return (
    <PageWrapper>
        <SceneToolbar/>
        <ProgressBar
                externalProgressValue={progressPercentage}
                externalState={progressState}
                setExternalState={setProgressState}/>
        <SceneContent>
            <LeftMenu>
                <SceneForm/>
            </LeftMenu>
            <MainContent>
                <SceneActiveImage/>
            </MainContent>
            <RightMenu>
                <SceneImagesList/>
            </RightMenu>
        </SceneContent>
        <SceneImageDownloadDialog
            active={isDownloadDialogActive}
            startGeneration={generateHdrImage}
            stopGeneration={cancelSceneJobGeneration}
            handleClose={() => setIsDownloadDialogActive(false)}
            selectedImage={selectedImage}/>
    </PageWrapper>
  );
};


export default ScenePage;

const PageWrapper = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: theme.palette.neutrals.main
}));

const SceneContent = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',
}));

const MainContent  = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    position: 'relative',

    zIndex: 1
}));

const LeftMenu  = styled(Box)(() => ({
    position: 'absolute',
    left: 0,

    zIndex: 2
}));

const RightMenu  = styled(Box)(() => ({
    position: 'absolute',
    right: 0,
    top:0,

    zIndex: 2
}));
