import { Tooltip } from '@mui/material';
import { IconButton } from '@pages/CuratePage/components/ToolPanel/IconButton';
import React from 'react';
import { MixImageIcon } from '@assets/icons/MixImageIcon';
import { CurateTools } from '@root/utils/constants/enums';
import { useCurate } from '@root/hooks/curate/useCurate';

interface MixingImageProps {
    className?: string;
    disabled?: boolean;
}

export const MixingImageButton = ({ className, disabled = false }: MixingImageProps) => {

    const {
        toggleTool,
        activeTool,
    } = useCurate();

    return (
        <Tooltip title="Mix image">
            <span>
                <IconButton
                    disabled={disabled}
                    value={CurateTools.MixImages}
                    className={className}
                    onClick={toggleTool}
                    active={activeTool === CurateTools.MixImages ? 1 : 0}>
                    <MixImageIcon />
                </IconButton>
            </span>
        </Tooltip>
    );
};
