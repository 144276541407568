import { NotificationMessagesTypes } from '@root/types/commonTypes';

export const notificationMessages: NotificationMessagesTypes = {
    model: {
        creating: {
            title: 'Creating',
            success: 'model is created',
            error: 'Something went wrong. Try again later',
        },
        importing: {
            title: 'Importing',
            success: 'The model was successful imported.',
            info: 'The model is being imported. Please do not close/refresh the tab.',
            error: 'Something went wrong. Model import failed.',
        },
        deleting: {
            title: 'Deleting',
            success: 'Model is being deleted successfully',
            info: 'The base model cannot be deleted',
            error: 'Something went wrong. The model was not deleted. Try again later',
        },
        trained: {
            title: 'Model trained',
            success: 'Your model was trained!',
        },
        training: {
            title: 'Training',
            error: 'Something went wrong. The model was not start training.',
        },
        exported: {
            title: 'Model exported!',
        },
        exporting: {
            title: 'Exporting',
            info: 'File is being prepared for export in the background. You can continue working on other tasks while the process completes.',
        },
    },
    project: {
        creating: {
            title: 'Creating',
            error: 'Something went wrong. The project was not created. Try again later',
        },
        deleting: {
            title: 'Deleting',
            success: 'Project is being deleted successfully',
            info: 'The base project cannot be deleted',
            error: 'Something went wrong. The project was not deleted. Try again later',
        },
    },
    tune: {
        generating: {
            title: 'Tune generated',
            success: 'Your tune has been generated!',

        },
    },
    curate: {
        rerendering: {
            title: 'Re-rendering',
            error: 'Something went wrong. Try again later'
        },
        generation: {
            title: 'Generation',
            error: 'Something went wrong. Try again later',
            success: 'Your image is ready'
        },
        saving: {
            title: 'Saving',
            success: 'The image has been saved as layers',
            error: 'Something went wrong. Try again later'
        },
        processing: {
            title: 'Processing',
            error: 'Something went wrong. Try again later'
        },
        moveToGenerate: {
            title: 'Move To Create Page',
            success: 'This image has been saved to the tune on the Create page',
        }
    },
    request: {
        error: {
            title: 'Error',
            error: 'Something went wrong...'
        },
    },
    image: {
        uploading: {
            title: 'Uploading',
            error: 'Something went wrong. The image was not uploaded.'
        },
        upscale: {
            title: 'Image upscale',
            success:  'Image upscale ready!',
        },   
    }
};