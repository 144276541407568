import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EraserIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}>
            <path 
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.3621 5.28992C19.6596 4.9982 20.1372 5.00287 20.429 5.30035L27.4717 12.482C27.6118 12.625 27.6895 12.8177 27.6874 13.0179C27.6854 13.2181 27.6039 13.4092 27.4608 13.5493L13.7351 26.9878C13.5941 27.1259 13.4046 27.2032 13.2073 27.2032L8.68092 27.2032C8.48965 27.2032 8.30553 27.1305 8.16579 26.9999L3.13908 22.3016C2.98875 22.1611 2.90233 21.9653 2.89987 21.7596C2.8974 21.5538 2.97909 21.356 3.12601 21.2119L19.3621 5.28992ZM19.8799 6.89538L4.74501 21.7374L8.97859 25.6944L12.8995 25.6944L25.8658 12.9994L19.8799 6.89538Z"
                fill="currentColor"/>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.7093 20.4439L11.6682 13.4028L12.7351 12.3359L19.7762 19.377L18.7093 20.4439ZM7.92676 26.4486C7.92676 26.0319 8.26451 25.6942 8.68116 25.6942H26.2838C26.7004 25.6942 27.0382 26.0319 27.0382 26.4486C27.0382 26.8652 26.7004 27.203 26.2838 27.203H8.68116C8.26451 27.203 7.92676 26.8652 7.92676 26.4486Z"
                fill="currentColor"/>
        </SvgIcon>
    );
};
