import React, { useState } from 'react';
import LoaderContext from './LoaderContext';

const LoaderContextProvider = ({ children }) => {
    const [loaderState, setLoaderState] = useState(false);

    const showLoader = () => {
        setLoaderState(true);
    };
    const hideLoader = () => {
        setLoaderState(false);
    };

    return (
        <LoaderContext.Provider
            value={{
                loaderState,
                showLoader,
                hideLoader,
            }}>
            {children}
        </LoaderContext.Provider>
    );
};

export default LoaderContextProvider;
