import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const MixImageIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            fill="none"
            {...props}>
            <path d="M18.6335 6.46073H6.94765C6.67873 6.46073 6.46073 6.67873 6.46073 6.94765V18.6335C6.46073 18.9024 6.67873 19.1204 6.94765 19.1204H11.7906C12.3429 19.1204 12.7906 19.5681 12.7906 20.1204V20.5812H6.94765C5.87199 20.5812 5 19.7092 5 18.6335V6.94765C5 5.87199 5.87199 5 6.94765 5H18.6335C19.7092 5 20.5812 5.87199 20.5812 6.94765V12.8604H20.1204C19.5681 12.8604 19.1204 12.4127 19.1204 11.8604V6.94765C19.1204 6.67873 18.9024 6.46073 18.6335 6.46073Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M19.8498 13.5C20.2532 13.5 20.5802 13.827 20.5802 14.2304V14.9267C20.5802 15.3301 20.2532 15.6571 19.8498 15.6571C19.4465 15.6571 19.1195 15.3301 19.1195 14.9267V14.2304C19.1195 13.827 19.4465 13.5 19.8498 13.5ZM19.8498 16.4575C20.2532 16.4575 20.5802 16.7845 20.5802 17.1878V17.759C20.5802 17.9108 20.5433 18.1387 20.4688 18.3185C20.3143 18.6911 19.887 18.8679 19.5143 18.7134C19.1417 18.5589 19.0658 18.3658 19.1195 17.759V17.1878C19.1195 16.7845 19.4465 16.4575 19.8498 16.4575ZM18.999 19.5C19.1535 19.8726 18.9767 20.2999 18.6041 20.4544C18.2874 20.5858 18.1971 20.5658 17.9022 20.5814L17.4732 20.5814C17.0698 20.5814 16.7428 20.2544 16.7428 19.8511C16.7428 19.4477 17.0698 19.1207 17.4732 19.1207L17.9022 19.1207C18.0686 19.1207 18.0446 19.1051 18.0446 19.1051C18.6041 19.0285 18.8445 18.983 18.999 19.5ZM13.5605 19.8511C13.5605 19.4477 13.8875 19.1207 14.2909 19.1207H14.9991C15.4025 19.1207 15.7295 19.4477 15.7295 19.8511C15.7295 20.2544 15.4025 20.5814 14.9991 20.5814L14.2909 20.5814C13.8875 20.5814 13.5605 20.2544 13.5605 19.8511Z" fill="currentColor"/>
            <path d="M13.3653 25.5391L25.0522 25.5391C25.3211 25.5391 25.5391 25.3211 25.5391 25.0522L25.5391 13.3653C25.5391 13.0964 25.3211 12.8783 25.0522 12.8783L20.2087 12.8783C19.6565 12.8783 19.2087 12.4306 19.2087 11.8783L19.2087 11.4175L25.0522 11.4175C26.1279 11.4175 27 12.2895 27 13.3653L27 25.0522C27 26.1279 26.1279 27 25.0522 27L13.3653 27C12.2895 27 11.4175 26.1279 11.4175 25.0522L11.4175 19.1265L11.8783 19.1265C12.4306 19.1265 12.8783 19.5742 12.8783 20.1265L12.8783 25.0522C12.8783 25.3211 13.0964 25.5391 13.3653 25.5391Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.148 18.3262C11.7446 18.3262 11.4176 17.9991 11.4176 17.5957L11.4176 16.9471C11.4176 16.5437 11.7446 16.2167 12.148 16.2167C12.5514 16.2167 12.8785 16.5437 12.8785 16.9471L12.8785 17.5957C12.8785 17.9991 12.5514 18.3262 12.148 18.3262ZM12.148 15.2651C11.7446 15.2651 11.4176 14.9381 11.4176 14.5347L11.4176 14.0952C11.4176 13.7334 11.4139 13.7115 11.5453 13.3947C11.6998 13.022 12.1272 12.8452 12.4998 12.9997C12.7276 13.0942 12.9321 13.273 12.8785 13.8798C12.8785 13.8798 12.8785 13.9288 12.8785 14.0952L12.8785 14.5347C12.8785 14.9381 12.5514 15.2651 12.148 15.2651ZM13.3022 12.4829C13.1476 12.1102 13.3245 11.6829 13.6971 11.5283C14.0139 11.397 14.0738 11.4169 14.2567 11.4169L14.7696 11.4169C15.173 11.4169 15.5 11.7439 15.5 12.1474C15.5 12.5508 15.173 12.8778 14.7696 12.8778L14.2567 12.8778C13.6971 12.8778 13.4567 12.8555 13.3022 12.4829ZM18.5 12.1474C18.5 12.5508 18.173 12.8778 17.7696 12.8778L17.1008 12.8778C16.6974 12.8778 16.3704 12.5508 16.3704 12.1474C16.3704 11.7439 16.6974 11.4169 17.1008 11.4169L17.7696 11.4169C18.173 11.4169 18.5 11.7439 18.5 12.1474Z" fill="currentColor"/>

        </SvgIcon>
    );
};
