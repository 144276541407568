import React, { createContext, useContext, useEffect, useState } from 'react';
import ModelsContext from '@context/ModelsContext/ModelsContext';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import LocalStorageContext from '@context/LocalStorageContext/LocalStorageContext';
import { useCurateApi } from '@hooks/curate/CurateApi';
import { prefixBaseUrl } from '@root/utils/url/url';
import { useJobs } from '@hooks/jobs/UseJobs';
import { CommonConstants, CuratePageConstants, ProgressBarConstants } from '@root/utils/constants';
import { useStatistics } from '@hooks/statistics/UseStatistics';
import { CURATE_PAGE_NAME } from '@root/utils/constants/pagesName';
import { CommonJobTypes, CurateTools, SharedJobTypes, ImageGenerationStatus, ProgressBarState } from '@root/utils/constants/enums';
import { CurateToolsFullNames } from '@root/utils/constants/curateToolsNames';
import { useTabActive } from '../helpers/useTabActive';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { useUpscaleTool } from '@root/context/UpscaleToolContext/useUpscaleTool';
import { ImageHelpers } from '@root/utils/helpers';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';

const CurateContext = createContext(null);

export const CurateProvider = ({ children }) => {
    const { updateJobStatus, enqueued, processing } = useJobs();
    const { isTabActive } = useTabActive();
    const {
        createStyleDriveConfig,
        setActiveImageUrlSD,
        setControlImageSD,
        activeImageLayerIdSD,
        setActiveImageLayerIdSD,
        clearStyleDriveToolInfo
    } = useStyleDrive();

    const { 
        createMixImageConfig,
        setFirstMixingIngredient,
        setActiveImageUrlMix,
        activeImageLayerIdMix,
        setActiveImageLayerIdMix,
        clearMixImageToolInfo
    } = useMixImage();

    const {
        createSceneShiftConfig,
        setActiveImageUrlSceneShift,
        setControlImageSceneShift,
        activeImageLayerIdSceneShift,
        setActiveImageLayerIdSceneShift,
        clearSceneShiftToolInfo
    } = useSceneShift();

    const {
        imageForUpscale,
        setImageForUpscale,
    } = useUpscaleTool();

    //Tools states.
    const [isRedoUsed, setRedoUsed] = useState(false);
    const [isUndoUsed, setUndoUsed] = useState(false);
    const [isRedoDisable, setRedoDisable] = useState(true);
    const [isUndoDisable, setUndoDisable] = useState(true);
    const [activeTool, setActiveTool] = useState('');
    const [isSketchSelected, setIsSketchSelected] = useState(false);
    const [referenceImage, setReferenceImage] = useState(null);
    const [referenceImageInfluence, setReferenceImageInfluence] = useState(0);
    const [sketchInfluence, setSketchInfluence] = useState(0);
    const [denoiseWeight, setDenoiseWeight] = useState(CuratePageConstants.DEFAULT_DENOISE_WEIGHT);

    //Form states
    const [prompt, setPrompt] = useState(CommonConstants.DEFAULT_PROMPT);
    const [modelForGeneration, setModelForGeneration] = useState('');
    const [negativePrompt, setNegativePrompt] = useState(CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER);
    const [enablePreciseMode, setEnablePreciseMode] = useState(false);
    const [isFullRerender, setIsFullRerender] = useState(true);
    const [isPartialRerenderEnabled, setIsPartialRerenderEnabled] = useState(false);

    const [seed, setSeed] = useState(CommonConstants.DEFAULT_SEED_VALUE);
    const [generateButtonState, setGenerateButtonState] = useState(false);
    const { storageProjectId } = useContext(LocalStorageContext);
    const [color, setColor] = useState(CuratePageConstants.DEFAULT_BRUSH_COLOR);
    const [brushSize, setBrushSize] = useState(CuratePageConstants.DEFAULT_BRUSH_SIZE);

    const [eraserSize, setEraserSize] = useState(CommonConstants.DEFAULT_ERASER_SIZE);
    const [isEraserSelected, setIsEraserSelected] = useState(false);

    const [eraserToolSize, setEraserToolSize] = useState(CommonConstants.DEFAULT_ERASER_SIZE);

    //Additional states for requests.
    const [inputMask, setInputMask] = useState();
    const [eraserMask, setEraserMask] = useState();
    const [canvasImageSrc, setCanvasImageSrc] = useState('');
    const [canvasImageSketchSrc, setCanvasImageSketchSrc] = useState(false);
    const [isCanvasLoad, setCanvasLoad] = useState(true);

    const [isFullScreenActive, setIsFullScreenActive] = useState(false);
    const [isMetadataDialogActive, setIsMetadataDialogActive] = useState(false);
    const [currentLayerInfo, setCurrentLayerInfo] = useState(null);

    //Curate session states.
    const [activeSession, setActiveSession] = useState('');
    const [layers, setLayers] = useState([]);
    const [lastVisibleLayerId, setLastVisibleLayerId] = useState('');
    const [isImagesLoading, setIsImagesLoading] = useState(false);

    // Generation in the background
    const [isImageReady, setIsImageReady] = useState(false);
    const [currentlyInProgress, setCurrentlyInProgress] = useState(0);
    const [uploadQueue, setUploadQueue] = useState(0);
    const [isEraserInProgress, setIsEraserInProgress] = useState(false);

    const [selectRequired, setSelectRequired] = useState(true);

    const [formMenuIsActive, setFormMenuIsActive] = useState(true);
    const [imageMenuIsActive, setImageMenuIsActive] = useState(false);

    const [isCreationToolResult, setIsCreationToolResult] = useState(false);

    // Time track
    const [startTime, setStartTime] = useState(new Date().getTime());
    const { toolViewed, timeSpentOnTool } = useStatistics();

    // Progress Bar
    const [progressState, setProgressState] = useState(ProgressBarState.Inactive);
    const [progressPercentage, setProgressPercentage] = useState(0);
    const [isProgressBarActive, setIsProgressBarActive] = useState(true);

    // TODO: temporary solution, should be removed when the metadata is fixed
    const [activeImageDimensions, setActiveImageDimensions] = useState({ width: 0, height: 0 });

    const {
        newSession,
        generateInPaint,
        generateFromSketch,
        confirmResults,
        getSessions,
        getSessionLayers,
        newLayer,
        saveCanvas,
        deleteLayerById,
        updateLayer,
        renameLayerById,
        sceneShiftApi,
        convertToSketch,
        changeLayersOrder,
        generateErase,
        generateWithStyleDrive,
        mixImage,
        upscaleLayer,
        blendImages
    } = useCurateApi();

    const { trainedModelsList } = useContext(ModelsContext);
    const { createNotification, notificationMessages } = useNotifications();

    //Session functionality.

    const createSession = async () => {
        const response = await newSession(storageProjectId, null);
        if (response && !response.errorCode) {
            setActiveSession({
                id: response,
                projectId: storageProjectId,
            });

            setLayers([]);
            setIsImagesLoading(false);

            return response;
        } else {
            return null;
        }
    };

    const openLastSession = async () => {
        if (!activeSession || activeSession?.projectId !== storageProjectId) {
            setIsImagesLoading(true);
            const sessions = await getSessions(storageProjectId);

            if (!sessions?.errorCode && sessions?.length) {
                setSelectRequired(true);
                setActiveSession(sessions[0]);
                await setLayersById(sessions[0].id);
                return sessions[0].id;
            } else {
                const id = await createSession();
                return id;
            }
        } else {
            return activeSession.id;
        }
    };

    const setLayersById = async (id) => {
        const newLayers = await getSessionLayers(id);

        if (!newLayers?.length ||
            newLayers?.errorCode ||
            !newLayers.filter(layer => layer.layerStatus === ImageGenerationStatus.READY)?.length) {
            setIsImagesLoading(false);
            return;
        }

        const sortedNewLayers = newLayers
            .sort((a, b) => a.orderIndex - b.orderIndex)
            .filter(layer => layer.layerStatus === ImageGenerationStatus.READY);

        const currentGeneratedLayers = layers.filter(layer => layer.layerStatus === ImageGenerationStatus.READY);

        if (sortedNewLayers.length > currentGeneratedLayers.length) {
            if (currentlyInProgress > 0) {
                setIsImageReady(true);
            }

            const lastGeneratedLayer = sortedNewLayers[sortedNewLayers.length - 1];

            if ((activeTool === CurateTools.StyleDrive && lastGeneratedLayer?.toolId === CurateTools.StyleDrive) ||
                (activeTool === CurateTools.MixImages && lastGeneratedLayer?.toolId === CurateTools.MixImages)  ||
                (activeTool === CurateTools.SceneShift && lastGeneratedLayer?.toolId === CurateTools.SceneShift)) {
                setIsCreationToolResult(true);
            } else if (isCreationCurateTool(activeTool)) {
                setIsCreationToolResult(false);
            }

            setSelectRequired(true);
        }
        setLayers(sortedNewLayers);
    };

    const createLayer = async (layer) => {
        const formData = new FormData();

        const path = layer.imagePath.startsWith('wwwroot/')
            ? prefixBaseUrl(layer.imagePath)
            : layer.imagePath;

        const imageFile = await fetch(path)
            .then((res) => res.blob())
            .then(
                (blob) =>
                    new File([blob], layer.imagePath, {
                        type: 'image/png',
                    }),
            );

        const sessionId = await getSessionId();
        if (!sessionId) {
            return;
        }

        formData.append('curateSessionId', sessionId);
        formData.append('image', imageFile);
        formData.append('isVisible', layer.isVisible);

        const createdLayer = await newLayer(formData);

        if (createdLayer?.id) {
            setSelectRequired(true);
            setLayers((prev) => [...prev, createdLayer]);

            if (isCreationCurateTool(activeTool)) {
                const image = await ImageHelpers.getImageFile(prefixBaseUrl(newLayer.imagePath));
                setControlImageForTool(activeTool, image);
            }
        }
    };

    const uploadImagesAsLayer = async (imageFiles) => {
        const sessionId = await getSessionId();

        if (!sessionId) {
            return;
        }

        setUploadQueue(uploadQueue + 1);

        await Promise.all(
            Array.from(imageFiles).map(async (file, i) => {
                const formData = new FormData();
                formData.append('curateSessionId', sessionId);
                formData.append('image', file);
                formData.append('isVisible', true);

                const createdLayer = await newLayer(formData);
                if (createdLayer?.id) {
                    setLayers((prev) => [...prev, createdLayer]);
                }

                setUploadQueue(uploadQueue > 1 ? uploadQueue - 1 : 0);
                setSelectRequired(true);

                return createdLayer;
            }),
        );

        if (isSketchSelected) {
            setIsSketchSelected(false);
        }
    };

    const updateLayerImagePath = async (path) => {
        if (!layers.length || !layers.find((el) => el.id === lastVisibleLayerId)) {
            return;
        }

        const formData = new FormData();
        const res = await fetch(path);
        const blob = await res.blob();
        const file = new File([blob], 'canvas.png', {
            type: 'image/png',
        });
        formData.append('image', file);

        if (lastVisibleLayerId) {
            const updated = await updateLayer(
                layers.find((el) => el.id === lastVisibleLayerId).id,
                formData,
            );

            if (!updated || updated.errorCode) {
                return;
            }

            updated.imagePath = `${updated.imagePath}?${Date.now()}`;

            const newLayers = [
                ...layers.filter((el) => el.id !== lastVisibleLayerId),
                updated,
            ];
            setLayers(newLayers.sort((a, b) => a.orderIndex - b.orderIndex)
                .filter(layer => layer.layerStatus === ImageGenerationStatus.READY));

            setCanvasImageSketchSrc(updated?.brashSketchImage ? `${prefixBaseUrl(updated?.brashSketchImage)}?${new Date().getTime()}` : '');
            setIsPartialRerenderEnabled(!!updated?.brashSketchImage);
        }
    };

    // Unused method
    const changeLayerVisibility = (layerId) => {
        setLayers((prev) => {
            const layer = prev.find((el) => el.id === layerId);
            layer.isVisible = !layer.isVisible;
            return [...prev];
        });
    };

    const selectLayerByOrderIndex = (layerOrder) => {
        if (isEraserInProgress) {
            return;
        }

        if(isSketchSelected) {
            setIsSketchSelected(false);
        }

        setSelectRequired(true);
        setLayers((prev) =>
            prev.map((el) => {
                el.isVisible = el.orderIndex <= layerOrder;
                return el;
            }),
        );
    };

    const deleteLayer = async (layerId) => {
        if(isSketchSelected && lastVisibleLayerId === layerId) {
            setIsSketchSelected(false);
        }

        if (activeImageLayerIdSD === layerId ||
            activeImageLayerIdMix === layerId ||
            activeImageLayerIdSceneShift === layerId) {

            setActiveImageForTool();
        }

        const response = await deleteLayerById(layerId);
        if (response && !response.errorCode) {
            setSelectRequired(true);
            setLayers((prev) => prev.filter((el) => el.id !== layerId));
        }
    };

    const renameLayer = async (layerId, name) => {
        const updated = await renameLayerById(layerId, name);

        if (updated?.id) {
            const newLayers = [
                ...layers.filter((el) => el.id !== layerId),
                updated,
            ];

            setLayers(newLayers.sort((a, b) => a.orderIndex - b.orderIndex)
                .filter(layer => layer.layerStatus === ImageGenerationStatus.READY));
        }
    };

    const changeOrder = async (layersId) => {
        const response = await changeLayersOrder(activeSession.id, layersId);

        if (response && !response.errorCode) {
            const layers = await getSessionLayers(activeSession.id);
            
            if (!layers?.length || layers?.errorCode) {
                return;
            }

            setLayers(layers.sort((a, b) => a.orderIndex - b.orderIndex)
                .filter(layer => layer.layerStatus === ImageGenerationStatus.READY));
        }
    };

    // Tools handlers.

    const toggleTool = (event) => {
        const selectedTool = CurateTools[CurateTools[event?.currentTarget?.value]]; 

        if (!selectedTool) {
            setActiveTool('');
            return;
        }

        if (activeTool) {
            onToolClose(
                CURATE_PAGE_NAME,
                getToolNameForStatisticsById(activeTool)
            );
        }

        if (activeTool === selectedTool) {
            setActiveTool('');
            return;
        }

        onToolStart(
            CURATE_PAGE_NAME,
            getToolNameForStatisticsById(selectedTool)
        );
        setActiveTool(selectedTool);
        setDefaults(selectedTool);
    };

    const setDefaults = (selectedTool) => {
        switch (selectedTool) {
            case CurateTools.Brush:
                setSketchInfluence(CuratePageConstants.DEFAULT_REFERENCE_IMAGE_INFLUENCE);
                setToolInfo();
                break;
            case CurateTools.Lasso:
                setToolInfo();
                break;
            case CurateTools.SceneShift:
            case CurateTools.MixImages:
                setToolInfo();
                setControlImageForTool(selectedTool);
                break;
            case CurateTools.StyleDrive:
                setToolInfo('');
                setControlImageForTool(selectedTool);
                break;
            case CurateTools.Upscale:
                setToolInfoForUpscale();
                break;
        }
    };
    
    const setToolInfo = (negativePrompt) => {
        setReferenceImage(null);
        setReferenceImageInfluence(0);

        clearMixImageToolInfo();
        clearStyleDriveToolInfo();
        clearSceneShiftToolInfo();

        setNegativePrompt(negativePrompt || CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER);
        setIsSketchSelected(false);
        setIsCreationToolResult(false);
    };

    const setToolInfoForUpscale = (desiredLayer) => {
        const activeLayer = desiredLayer || layers.find((el) => el.id === lastVisibleLayerId);

         // TODO: Usage of the activeImageDimensions should be removed when the metadata is fixed
        const imageForUpscaleTool = {
            width: activeImageDimensions.width || activeLayer.width,
            height: activeImageDimensions.height || activeLayer.height,
            id: activeLayer.imageId
        };

        setImageForUpscale(imageForUpscaleTool);
    };

    //Form handlers.

    const onPromptInputChange = (event) => {
        setPrompt(event.target.value);
    };

    const onModelForGenerationInputChange = (event) => {
        setModelForGeneration(event.target.value);
    };

    const onNegativePromptInputChange = (event) => {
        setNegativePrompt(event.target.value);
    };

    const onSeedInputChange = (event) => {
        if (!Number.isInteger(+event.target.value)) {
            setSeed(CommonConstants.DEFAULT_SEED_VALUE);
        } else {
            setSeed(+event.target.value);
        }
    };

    const onInputSeed = (e) => {
        if (e.key === 'Backspace' && isNaN(+e.target.value)) {
            setSeed('');
        }
    };

    const onInputMaskChange = (img) => {
        setInputMask(img);
    };

    const onColorChange = (e) => {
        setColor(e);
    };

    const onBrushSizeChange = (e) => {
        if (isEraserSelected) {
            setEraserSize(e.target.value);
        } else {
            setBrushSize(e.target.value);
        }
    };

    const downloadImage = async () => {
        if (!canvasImageSrc) {
            return;
        }

        if (!canvasImageSketchSrc) {
            ImageHelpers.downloadPngByUrl(canvasImageSrc);
        } else {
            const canvasImage = await ImageHelpers.getImageFile(canvasImageSrc);
            const imageSketch = await ImageHelpers.getImageFile(canvasImageSketchSrc);

            const imageData = await blendImages(canvasImage, imageSketch);

            if (imageData && !imageData.errorCode) {
                const imgSrc = 'data:image/png;base64,' + imageData;
                const a = document.createElement('a');
                a.href = imgSrc;
                a.download = 'image.png';
                a.click();
            }
        }
    };

    const saveImage = async () => {
        const formData = new FormData();
        let file;
        if (canvasImageSketchSrc) {
            file = await getBlendedImageFile();
        } else {
            file = await ImageHelpers.getImageFile(canvasImageSrc);
        }
        formData.append('file', file);

        const response = await saveCanvas(activeSession.id, formData);

        return response;
    };

    const getBlendedImageFile = async (background, foreground, asImage = false) => {
        const backgroundPath = background ? background : canvasImageSrc;
        const foregroundPath = foreground ? foreground : canvasImageSketchSrc;

        const backgroundImage = await ImageHelpers.getImageFile(backgroundPath);
        const foregroundImage = await ImageHelpers.getImageFile(foregroundPath);
        const imageData = await blendImages(backgroundImage, foregroundImage);

        if (!imageData || imageData.errorCode) {
            return;
        }

        const imgSrc = 'data:image/png;base64,' + imageData;
        if (!asImage) {
            const file = ImageHelpers.getImageFileFromBase64String(imgSrc);

            return file;
        } else {
            const blendedImage = new Image();
            blendedImage.src = imgSrc;

            return blendedImage;
        }
    };

    const findCurrentLayerIndex = () => {
        return layers.findIndex((el) => el.id === lastVisibleLayerId);
    };

    const moveToNextLayer = () => {
        const lastLayer = findCurrentLayerIndex();

        if (lastLayer === 0) {
            return;
        } else {
            selectLayerByOrderIndex(layers[lastLayer - 1].orderIndex);
        }
    };

    const moveToPreviousLayer = () => {
        const lastLayer = findCurrentLayerIndex();

        if (lastLayer === layers.length - 1) {
            return;
        } else {
            selectLayerByOrderIndex(layers[lastLayer + 1].orderIndex);
        }
    };

    const toggleFullScreen = () => {
        setIsFullScreenActive(!isFullScreenActive);
    };

    const toggleMetadataDialog = () => {
        setIsMetadataDialogActive(!isMetadataDialogActive);
    };

    const getImageForGeneration = async() => {
        if (!canvasImageSrc) {
            return null;
        }

        let  imageFile;

        if (canvasImageSketchSrc) {
            imageFile = await getBlendedImageFile();
        } else {
            imageFile = await ImageHelpers.getImageFile(canvasImageSrc);
        }

        return imageFile;
    };

    const isCreationCurateTool = (toolId) => {
        return toolId === CurateTools.StyleDrive ||
            activeTool === CurateTools.MixImages ||
            activeTool === CurateTools.SceneShift;
    };

    //Requests.

    const rerenderImage = async () => {
        if (!activeSession.id) {
            await createSession();
        }

        const maskFile = await fetch(inputMask).then((res) => res.blob());
        const  imageFile = await getImageForGeneration();

        const generationConfig = {
            curateSessionId: activeSession.id,
            modelId: modelForGeneration,
            prompt: prompt,
            negativePrompt: negativePrompt === CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER ?
                CuratePageConstants.DEFAULT_NEGATIVE_PROMPT : negativePrompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            numberOfImages: CuratePageConstants.DEFAULT_NUMBER_OF_IMAGE,
            inputImage: imageFile,
            inputMask: maskFile,
            ipadapterPlus: enablePreciseMode,
            denoiseWeight: denoiseWeight,
            ...(referenceImage && { inputReference: referenceImage }),
            ...(referenceImage && { referenceWeight: referenceImageInfluence }),
        };

        const generation = await generateInPaint(generationConfig);

        if (generation && !generation.errorCode) {
            updateStatuses();

        } else {
            createNotification(
                notificationMessages.curate.rerendering.title,
                notificationMessages.curate.rerendering.error,
            );
        }
    };

    const eraseImage = async () => {
        if (!activeSession.id) {
            await createSession();
        }

        const maskFile = await fetch(eraserMask).then((res) => res.blob());
        const imageFile = await getImageForGeneration();

        const generationConfig = {
            curateSessionId: activeSession.id,
            inputImage: imageFile,
            inputMask: maskFile,
        };

        const generation = await generateErase(generationConfig);

        if (generation && !generation.errorCode) {
            updateStatuses();

        } else {
            setIsEraserInProgress(false);
            createNotification(
                notificationMessages.curate.rerendering.title,
                notificationMessages.curate.rerendering.error,
            );
        }
    };

    const rerenderFromSketch = async () => {
        if (!activeSession.id) {
            await createSession();
        }

        const originalImage = await ImageHelpers.getImageFile(canvasImageSrc);
        const sketchImage = canvasImageSketchSrc ? await ImageHelpers.getImageFile(canvasImageSketchSrc) : '';

        const generationConfig = {
            curateSessionId: activeSession.id,
            modelId: modelForGeneration,
            prompt: prompt,
            negativePrompt: negativePrompt === CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER ?
                CuratePageConstants.DEFAULT_NEGATIVE_PROMPT : negativePrompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            numberOfImages: CuratePageConstants.DEFAULT_NUMBER_OF_IMAGE,
            originalImage: originalImage,
            ipadapterPlus: enablePreciseMode,
            regenerateWholeImage: isPartialRerenderEnabled ? isFullRerender : true,
            ...(sketchImage && { sketch: sketchImage }),
            ...(sketchInfluence && { sketchWeight: sketchInfluence }),
            ...(referenceImage && { inputReference: referenceImage }),
            ...(referenceImage && { referenceWeight: referenceImageInfluence })
        };

        const generation = await generateFromSketch(generationConfig);
        setIsSketchSelected(false);

        if (generation && !generation.errorCode) {
            updateStatuses();

        } else {
            createNotification(
                notificationMessages.curate.generation.title,
                notificationMessages.curate.generation.error,
            );
        }
    };

    const generateSceneShift = async () => {
        if (!activeSession.id) {
            await createSession();
        }

        const toolConfig = createSceneShiftConfig();

        if (!toolConfig) {
            return;
        }

        const generationConfig = {
            curateSessionId: activeSession.id,
            ...toolConfig
        };

        const generation = await sceneShiftApi(generationConfig);

        if (generation && !generation.errorCode) {
            updateStatuses();

        } else {
            createNotification(
                notificationMessages.curate.generation.title,
                notificationMessages.curate.generation.error,
            );
        }
    };

    const convertCanvasImageToSketch = async () => {
        setIsSketchSelected(true);
        const sessionId = await getSessionId();

        if (!sessionId) {
            return;
        }

        const imageFile = await getImageForGeneration();

        const generation = await convertToSketch(sessionId, imageFile);

        if (!generation || generation?.errorCode) {
            return;
        }
        setReferenceImage(imageFile);
        updateStatuses();
    };

    const setControlImageForTool = async(selectedTool, predefinedImage) => {
        const  imageFile = predefinedImage ? predefinedImage : await getImageForGeneration();

        if (!imageFile) {
            return;
        }

        switch (selectedTool) {
            case CurateTools.StyleDrive:
                setControlImageSD(imageFile);
                break;
        
            case CurateTools.MixImages:
                setFirstMixingIngredient(imageFile);
                break;
            case CurateTools.SceneShift:
                setControlImageSceneShift(imageFile);
                break;
        }
    };

    const setActiveImageForTool = (image, layer) => {
        const imageUrl = image || '';
        const layerId = layer || '';

        switch (activeTool) {
            case CurateTools.StyleDrive:
                setActiveImageUrlSD(imageUrl);
                setActiveImageLayerIdSD(layerId);
                break;
            case CurateTools.MixImages:
                setActiveImageUrlMix(imageUrl);
                setActiveImageLayerIdMix(layerId);
                break;
            case CurateTools.SceneShift:
                setActiveImageUrlSceneShift(imageUrl);
                setActiveImageLayerIdSceneShift(layerId);
                break;
        }
    };

    const generateStyleDrive = async() => {
        const toolConfig = createStyleDriveConfig();

        if (!toolConfig) {
            return;
        }

        const generationConfig = {
            curateSessionId: activeSession.id,
            modelId: modelForGeneration,
            prompt: prompt,
            negativePrompt: negativePrompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            numberOfImages: CuratePageConstants.DEFAULT_NUMBER_OF_IMAGE,
            ipadapterPlus: enablePreciseMode,
            ...toolConfig
        };

        const generation = await generateWithStyleDrive(generationConfig);

        if (!generation || generation?.errorCode) {
            return;
        }

        updateStatuses();
    };

    const generateMixImage = async () => {
        const toolConfig = createMixImageConfig();

        if (!toolConfig) {
            return;
        }

        const generationConfig = {
            curateSessionId: activeSession.id,
            modelId: modelForGeneration,
            prompt: prompt,
            negativePrompt: negativePrompt === CommonConstants.NEGATIVE_PROMPT_PLACEHOLDER ?
                CuratePageConstants.DEFAULT_NEGATIVE_PROMPT : negativePrompt,
            seed: seed === CommonConstants.DEFAULT_SEED_VALUE ? -1 : seed,
            ...toolConfig
        };

        const imageIds = await mixImage(generationConfig);

        if (!imageIds || imageIds?.errorCode) {
            return;
        }
    
        updateStatuses();
    };

    const updateStatuses = () => {
        if (progressState === ProgressBarState.Inactive ||
            progressState === ProgressBarState.Finished) {
            setProgressPercentage(ProgressBarConstants.QUEUED_MAX_VALUE);
            setProgressState(ProgressBarState.Queued);
        }
        updateJobStatus();
    };

    const generateUpscaleLayer = async (upscaleFactor) => {
        if (!activeSession.id) {
            await createSession();
        }

        const generationConfig = {
            curateSessionId: activeSession.id,
            imageId: imageForUpscale.id,
            upscaleFactor
        };

        const imageIds = await upscaleLayer(generationConfig);

        if (!imageIds || imageIds?.errorCode) {
            return;
        }
    
        updateStatuses();
    };

    // Unused method
    const confirmImages = async (imagesId) => {
        const response = await confirmResults(activeSession.id, imagesId);

        if (response && !response.errorCode) {
            createNotification(
                notificationMessages.curate.saving.title,
                notificationMessages.curate.saving.success,
            );
            const layers = await getSessionLayers(activeSession.id);
            
            if (!layers?.length || layers?.errorCode) {
                return;
            }

            setLayers(layers.sort((a, b) => a.orderIndex - b.orderIndex));
        } else {
            createNotification(
                notificationMessages.curate.saving.title,
                notificationMessages.curate.saving.error,
            );
        }
    };

    // Unused method
    const discardImages = async () => {
        const response = await confirmResults(activeSession.id, []);

        if (response && !response.errorCode) {
            setLayers((prev) => prev.slice(0, -1));
        }
    };

    const clearCanvasInfo = () => {
        setCanvasImageSrc('');
        setCanvasImageSketchSrc('');
        setCurrentLayerInfo(null);
    };

    const getSessionId = async () => {
        const id = await openLastSession();
        return id;
    };

    useEffect(() => {
        if (referenceImage) {
            setReferenceImageInfluence(CuratePageConstants.DEFAULT_REFERENCE_IMAGE_INFLUENCE);
        }
    }, [referenceImage]);


    useEffect(() => {

        const enqueuedCurate = enqueued.filter(job => !Object.values(CommonJobTypes).includes(job.jobType));
        const processingCurate = processing.filter(job => !Object.values(CommonJobTypes).includes(job.jobType));

        const enqueuedEraser = enqueued.filter(job => job.jobType === SharedJobTypes.ERASER);
        const processingEraser = processing.filter(job => job.jobType === SharedJobTypes.ERASER);

        if (!enqueuedEraser.length && !processingEraser.length) {
            setIsEraserInProgress(false);
        } else {
            setIsEraserInProgress(activeTool === CurateTools.Eraser);
        }

        if (processingCurate.length && !isEraserInProgress) {

            const progressValue = processingCurate[0].percentage && processingCurate[0].percentage > ProgressBarConstants.QUEUED_MAX_VALUE
            ? processingCurate[0].percentage
            : ProgressBarConstants.QUEUED_MAX_VALUE;
    
            setProgressPercentage(progressValue);

            if ((progressState === ProgressBarState.Queued ||
                progressState === ProgressBarState.Paused ||
                progressState === ProgressBarState.Inactive)) {

                setProgressState(ProgressBarState.InProgress);
            }
        }


        if (enqueuedCurate.length || processingCurate.length) {
            setCurrentlyInProgress(enqueuedCurate.length + processingCurate.length);
        } else {
            setCurrentlyInProgress(0);
            if (progressState !== ProgressBarState.Inactive) {
                setProgressPercentage(ProgressBarConstants.MAX_VALUE);
                setProgressState(ProgressBarState.Finished);
            }
        } 

        if (activeSession.id) {
            setLayersById(activeSession.id);
        }
    }, [enqueued, processing]);

    useEffect(() => {
        if (isEraserInProgress) {
            return;
        }

        if (currentlyInProgress > 0 && progressState === ProgressBarState.Waiting) {
            setProgressState(ProgressBarState.InProgress);

        } else if (currentlyInProgress === 0 && progressState !== ProgressBarState.Inactive) {
           setProgressState(ProgressBarState.Finished);
        }

    }, [currentlyInProgress]);
    


    const onToolStart = (pageName, toolName) => {
        setStartTime(new Date().getTime());
        toolViewed(pageName, toolName);
    };

    const onToolClose = (pageName, toolName) => {
        const endTime = new Date().getTime();
        const timeDifference = (endTime - startTime) / 1000;
        timeSpentOnTool(pageName, toolName, timeDifference);
    };

    const getToolNameForStatisticsById = (toolId) => {
        const activeToolName = toolId ? CurateToolsFullNames[toolId] : '';
        const toolName = activeToolName ? activeToolName[0].toUpperCase() + activeToolName.slice(1) : '';

        return toolName;
    };


    useEffect(() => {
        const toolName = getToolNameForStatisticsById(activeTool);

        if (isTabActive && toolName) {
            onToolStart(CURATE_PAGE_NAME, toolName);
        } else if (!isTabActive && toolName) {
            onToolClose(CURATE_PAGE_NAME, toolName);
        }
    }, [isTabActive]);

    useEffect(() => {
        
        if (!canvasImageSrc) {
            return;
        }

        if (isCreationCurateTool(activeTool) && isCreationToolResult) {

                setActiveImageForTool(canvasImageSrc, lastVisibleLayerId);
                setIsCreationToolResult(false);
        }

    }, [canvasImageSrc, activeTool]);

    useEffect(() => {
        if (eraserMask) {
            eraseImage();
        }
    }, [eraserMask]);

    useEffect(() => {
        const defaultModel = trainedModelsList.find((el) =>
            el.Name.includes(CommonConstants.DEFAULT_MODEL_NAME),
        );

        defaultModel &&
            !modelForGeneration &&
            setModelForGeneration(defaultModel.Id);
    }, [trainedModelsList]);

    useEffect(() => {
        setGenerateButtonState(!!canvasImageSrc && (
            (activeTool === CurateTools.Lasso && inputMask) ||
                activeTool === CurateTools.Brush),
        );
    }, [
        inputMask,
        canvasImageSrc,
        activeTool
    ]);

    // TODO: temporary solution, should be removed when the metadata is fixed
    useEffect(() => {
        if (activeTool === CurateTools.Upscale) {
            setToolInfoForUpscale();
        }
    }, [activeImageDimensions]);
    

    useEffect(() => {
        if (!layers?.length) {
            setLastVisibleLayerId('');
            clearCanvasInfo();
            return;
        }

        if (selectRequired) {
            setSelectRequired(false);

            setLastVisibleLayerId(
                layers.findLast((el) => el.isVisible && el.imagePath && el.layerStatus === ImageGenerationStatus.READY)?.id ?? '',
            );

            const lastVisibleLayer = layers.findLast(
                (el) => el.isVisible && el.imagePath && el.layerStatus === ImageGenerationStatus.READY,
            );

            if (lastVisibleLayer) {
                setCanvasImageSrc(prefixBaseUrl(lastVisibleLayer.imagePath));
                setCanvasImageSketchSrc(lastVisibleLayer?.brashSketchImage ?
                    `${prefixBaseUrl(lastVisibleLayer?.brashSketchImage)}?${new Date().getTime()}` :
                    '');
                setIsPartialRerenderEnabled(!!lastVisibleLayer?.brashSketchImage);

                setCurrentLayerInfo({
                    toolId: lastVisibleLayer.toolId,
                    metadata: lastVisibleLayer.toolMetadata,
                    id: lastVisibleLayer.id,
                    brashSketchImage: lastVisibleLayer?.brashSketchImage
                });

                if (activeTool === CurateTools.Upscale) {
                    setToolInfoForUpscale(lastVisibleLayer);
                }

            } else {
                clearCanvasInfo();
            }
        }
        setIsImagesLoading(false);
        setCanvasLoad(true);
    }, [layers]);

    useEffect(() => {
        if (storageProjectId !== activeSession?.projectId) {
            setLayers([]);
            setLastVisibleLayerId('');
            clearCanvasInfo();
            setActiveTool(null);
        }
    }, [storageProjectId]);

    const value = {
        isRedoUsed,
        setRedoUsed,
        isUndoUsed,
        setUndoUsed,
        isRedoDisable,
        setRedoDisable,
        isUndoDisable,
        setUndoDisable,
        setInputMask: onInputMaskChange,

        inputMask,

        openLastSession,

        activeSession,

        updateLayerImagePath,
        layers,
        lastVisibleLayerId,

        prompt,
        onPromptInputChange,
        negativePrompt,
        onNegativePromptInputChange,
        modelForGeneration,
        onModelForGenerationInputChange,
        setModelForGeneration,
        seed,
        onSeedInputChange,
        onInputSeed,
        color,
        setColor: onColorChange,
        brushSize,
        onBrushSizeChange,

        eraserSize,
        isEraserSelected,
        setIsEraserSelected,
        generateButtonState,
        rerenderImage,
        rerenderFromSketch,
        generateSceneShift,
        getSessionId,
        isImageReady,
        setIsImageReady,

        canvasImageSrc,
        saveImage,
        downloadImage,
        isCanvasLoad,
        setCanvasLoad,

        isFullScreenActive,
        toggleFullScreen,
        isMetadataDialogActive,
        toggleMetadataDialog,
        currentLayerInfo,
        uploadQueue,
        setCurrentlyInProgress,
        currentlyInProgress,
        selectLayerByOrderIndex,

        deleteLayer,
        renameLayer,
        changeOrder,

        createLayer,
        uploadImagesAsLayer,

        convertCanvasImageToSketch,
        isSketchSelected,
        setIsSketchSelected,

        referenceImage,
        setReferenceImage,
        referenceImageInfluence,
        setReferenceImageInfluence,
        sketchInfluence,
        setSketchInfluence,
        
        moveToNextLayer,
        moveToPreviousLayer,

        eraseImage,
        enablePreciseMode,
        setEnablePreciseMode,
        setSelectRequired,
        denoiseWeight,
        setDenoiseWeight,

        formMenuIsActive,
        setFormMenuIsActive,
        imageMenuIsActive,
        setImageMenuIsActive,
        generateStyleDrive,
        generateMixImage,
        progressState,
        setProgressState,
        isProgressBarActive,
        setIsProgressBarActive,
        isFullRerender,
        setIsFullRerender,
        isPartialRerenderEnabled,
        setIsPartialRerenderEnabled,
        generateUpscaleLayer,
        toggleTool,
        activeTool,
        setActiveTool,
        canvasImageSketchSrc,
        getBlendedImageFile,
        selectRequired,
        setEraserMask,
        isEraserInProgress,
        setIsEraserInProgress,
        isImagesLoading,
        progressPercentage,
        eraserToolSize,
        setEraserToolSize,

        setActiveImageDimensions
    };

    return (
        <CurateContext.Provider value={value}>
            {children}
        </CurateContext.Provider>
    );
};

export const useCurate = () => {
    const curate = useContext(CurateContext);

    if (curate == null) {
        throw new Error('useCurate cannot be used without its provider');
    }

    return curate;
};
