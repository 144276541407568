import { useRequest } from '@root/hooks/request/UseRequest';
import { Model3dApiItems } from '@root/types/apiTypes';
import { SupportedRequestError } from '@root/types/commonTypes';
import { Model3dPoseDto, CreateModel3dPoseDto, Model3dDto } from '@root/types/dto';
import { getRequestResult } from '@root/utils/helpers';

const MODEL_3D_POSES_END_POINT = '/model-3d-poses';
const MODELS_3D_DATA_END_POINT = '/models-3d-data';

export const  useModel3dApi = (): Model3dApiItems => {

    const { requestGet, requestPostWithForm, requestPostWithJson, requestDelete } = useRequest();

    // Model 3d pose

    const addModelPose = async (data: CreateModel3dPoseDto): Promise<Model3dPoseDto | SupportedRequestError> => {
        const response = await requestPostWithJson(`${MODEL_3D_POSES_END_POINT}`,
            { data }
        );
        return response?.data;
    };
    
    const getModelPoseById = async (id: number): Promise<Model3dPoseDto | SupportedRequestError>  => {
        const response = await requestGet(`${MODEL_3D_POSES_END_POINT}/${id}`);
        return response?.data;
    };

    const deleteModelPoseById = async (id: number): Promise<string | SupportedRequestError> => {
        const response = await requestDelete(`${MODEL_3D_POSES_END_POINT}/${id}`);
        return getRequestResult(response, { propertyName:'id' });
    };

    // Model 3d data

    const getAllModels3d = async (): Promise<Model3dDto[] | SupportedRequestError>  => {
        const response = await requestGet(MODELS_3D_DATA_END_POINT);
        return response?.data;
    };

    const getModel3dById = async (id: number): Promise<Model3dDto | SupportedRequestError> => {
        const response = await requestGet(`${MODELS_3D_DATA_END_POINT}/${id}`);
        return response?.data;
    };

    const createModel3d = async (model: File, modelName: string): Promise<Model3dDto | SupportedRequestError> => {
        const data = new FormData();

        data.append('model', model);
        data.append('name', modelName);
    
        const response = await requestPostWithForm(MODELS_3D_DATA_END_POINT, {
            data
        });
        return response?.data;
    };

    const deleteModel3dById = async (id: number): Promise<string | SupportedRequestError> => {
        const response = await requestDelete(`${MODELS_3D_DATA_END_POINT}/${id}`);
        return getRequestResult(response, { propertyName:'id' });
    };

    return {
        addModelPose,
        getModelPoseById,
        deleteModelPoseById,

        getAllModels3d,
        getModel3dById,
        createModel3d,
        deleteModel3dById
    };
};
