import { AxiosResponse } from 'axios';
import { RequestErrorCode } from '../constants/enums';

type RequestResultConfig = {
    propertyName?: string;
}

export const getRequestResult = (response: AxiosResponse | any, config?: RequestResultConfig) => {
    if (!response.data) {
        return {
            errorCode: RequestErrorCode.UNKNOWN_ERROR,
            message: RequestErrorCode.UNKNOWN_ERROR
        };
    }

    if (response?.data?.errorCode in RequestErrorCode) {
        return response?.data;
    } else if (response?.data && !response?.data?.errorCode) {

        const result = config?.propertyName ? response?.data[config?.propertyName] : response.data;
        return result;
    }
};