import React, {createContext, useContext, useState} from 'react';

const DragContext = createContext(null);


export const DragProvider = ({ children }) => {
    const [draggedImage, setDraggedImage] = useState(null);
    const [draggedObject, setDraggedObject] = useState(null);

    const value = {
        draggedImage,
        setDraggedImage,
        draggedObject,
        setDraggedObject
    };

    return <DragContext.Provider value={value}>{children}</DragContext.Provider>;
};

export const useDrag = () => {
    const context = useContext(DragContext);
    if (!context) throw new Error('Can not use useDrag out of DragContext. 😃');
    return context;
};