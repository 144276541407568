import React from 'react';
import { Box } from '@mui/material';

import Notification from './Notification';
import { useNotifications } from '@context/NotificationsContext/useNotifications';

const NotificationsScope = () => {
    const { notifications, appFocused } = useNotifications();
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: '10',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '15px',
                maxHeight: '80%',
                overflow: 'auto',
            }}>
            {notifications.filter((notification) => appFocused || notification.broadcast === 'app').map((el) => {
                return <Notification notification={el} key={el.id} />;
            })}
        </Box>
    );
};

export default NotificationsScope;
