import React from 'react';
import { ButtonBase, Typography, styled } from '@mui/material';
import { QuickActionButtonProps } from './QuickActionButton.types';

const QuickActionButton = ({
    buttonAction,
    label,
    disabled
}: QuickActionButtonProps): React.JSX.Element => {
    return (
        <QuickButtonBase onClick={buttonAction}>
            <QuickButtonLabel disabled={disabled}  variant='subtitle1' component="p">
                {label}
            </QuickButtonLabel>
        </QuickButtonBase>
    );
};

export default QuickActionButton;

const QuickButtonBase = styled(ButtonBase)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',

    background: theme.palette.neutrals.main,
    borderRadius: '15px 0 0 15px',
    textDecoration: 'none',

    width: '35px',
    height: '116px',
    border: `1px solid ${theme.palette.primary.light1}`,
    borderRight: 'none',
    transition: 'all 0.5s',
}));

const QuickButtonLabel = styled(Typography)<{component?: any, disabled: boolean}>(({ disabled, theme }) => ({
    color: disabled ? theme.palette.primary.inactive : theme.palette.primary.light1,
    whiteSpace: 'nowrap',
    transform: 'rotate(-90deg)',
    transition: 'all 0.5s',
    paddingTop: '5px',
}));
