import React, { useContext, useEffect, useState } from 'react';
import { Box, IconButton, Typography, styled, } from '@mui/material';
import GenerateContext from '@root/context/GenerateContext/GenerateContext';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import { AspectRatioInfo, SupportedAspectRatio, SupportedAspectRatios } from '@root/utils/constants/enums';
import ToggleField from '@root/components/ToggleField';
import { AspectRatioPortraitIcon } from '@root/assets/icons/AspectRatioPortraitIcon';
import { AspectRatioLandscapeIcon } from '@root/assets/icons/AspectRatioLandscapeIcon';

const CHANGED_BY_SKETCH_LABEL = 'sketch';
const CHANGED_BY_PHOTO_LABEL = 'photo';

enum ImageOrientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait'
}

const AspectRatioField = (): React.JSX.Element => {
    const {
        aspectRatio,
        onAspectRatioValueChange,
        isAspectRatioChangedBySketch,
        isAspectRatioChangedByControlImage
    } = useContext(GenerateContext);

    const [changedByLabel, setChangedByLabel] = useState<string>('');
    const [optionsList, setOptionsList] = useState<string[]>([]);
    const [selectedAspectRatio, setSelectedAspectRatio] =
        useState<string>(SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioLabel);
    const [selectedOrientation, setSelectedOrientation] = useState<string>(ImageOrientation.LANDSCAPE);


    const onAspectRatioChange = (option: string): void => {
        setSelectedAspectRatio(option);
        const index = Object.keys(SupportedAspectRatios).find(
            (key) => SupportedAspectRatios[+key as SupportedAspectRatio].aspectRatioLabel === option);

        if (index) {
            onAspectRatioValueChange(+index);
        }
    };

    const getListOfLabels = (object: AspectRatioInfo): string[] => {
        const labelsInfo = Object.keys(object).reduce((result: any, key: any) => {
            result[key] = object[key as SupportedAspectRatio].aspectRatioLabel;
            return result;
          }, {});  
          return Object.values(labelsInfo);
    };

    useEffect(() => {
        const arrayOfLabels = getListOfLabels(SupportedAspectRatios);
        setOptionsList(arrayOfLabels);
    }, []);

    useEffect(() => {
        if (!isAspectRatioChangedBySketch && !isAspectRatioChangedByControlImage) {
            setChangedByLabel('');
            return;
        }

        if (isAspectRatioChangedBySketch) {
            setChangedByLabel(CHANGED_BY_SKETCH_LABEL);
        } else if (isAspectRatioChangedByControlImage) {
            setChangedByLabel(CHANGED_BY_PHOTO_LABEL);
        }

        setSelectedAspectRatio(SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioLabel);

        if (aspectRatio !== SupportedAspectRatio.OneByOne) {
            setSelectedOrientation(aspectRatio < SupportedAspectRatio.OneByOne ?
                ImageOrientation.LANDSCAPE :
                ImageOrientation.PORTRAIT);
        }

    }, [isAspectRatioChangedBySketch, isAspectRatioChangedByControlImage, aspectRatio]);

    const AspectRatioButton = ({ value }: {value: ImageOrientation}): JSX.Element => {
        return (
            <ActionButton
            className={value}
            disableRipple
            active={selectedOrientation === value}>
            { value == ImageOrientation.LANDSCAPE ? <AspectRatioLandscapeIcon/> : <AspectRatioPortraitIcon/>}
            </ActionButton>
        );
    };

    return (
        <FieldWrapper>
            <AspectRatioContent>
                <AspectRatioInfoSection>
                    <InputLabel variant="body16">{'aspect ratio'}</InputLabel>
                    <OrientationSection>
                        <ToggleField
                            selectedOption={selectedOrientation}
                            optionsList={Object.values(ImageOrientation)}
                            withLabels={false}
                            elementsList={[
                                <AspectRatioButton value = {ImageOrientation.LANDSCAPE} key='landscape'/>,
                                <AspectRatioButton value = {ImageOrientation.PORTRAIT} key='portrait'/>]}
                            setSelectedOption={setSelectedOrientation}/>
                    </OrientationSection>
                </AspectRatioInfoSection>
            </AspectRatioContent>
            <Wrapper>
                <ToggleField
                        selectedOption={selectedAspectRatio}
                        optionsList={selectedOrientation === ImageOrientation.LANDSCAPE ? 
                            optionsList.slice(0, SupportedAspectRatio.OneByOne) :
                            optionsList.slice(SupportedAspectRatio.OneByOne - 1)}
                        setSelectedOption={onAspectRatioChange}/>
            </Wrapper>
                    <InfoBlock visible={!!changedByLabel}>
                        <TooltipIcon></TooltipIcon>
                        <RatioTooltip>{`aspect ratio changed according to the ${changedByLabel}`}</RatioTooltip>
                    </InfoBlock>
        </FieldWrapper>
    );
};

export default AspectRatioField;

const AspectRatioInfoSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '28px'
}));

const AspectRatioContent = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
}));

const FieldWrapper = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    alignItems: 'start',
    flexDirection: 'column',
    maxWidth: '300px',
    gap: '10px'
}));

const InfoBlock = styled(Box)<{visible: boolean}>(({ visible }) => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    gap: '10px',
    width: '100%',
    alignItems: 'center',
    opacity: visible ? 1 : 0
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    paddingRight: '5px',
    color: theme.palette.primary.light1,
}));

const OrientationSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    width: '70px',
}));

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '150px',
    maxWidth: '300px',
    justifyContent: 'center',
    width: '100%',
}));

const RatioTooltip = styled(Typography)(({theme}) => ({
    fontFamily: 'Roboto400',
    fontSize: '12px',
    color: theme.palette.primary.light1,
    width: '100%'
}));

const TooltipIcon = styled(InformationIcon)(({ theme }) => ({
    width: '17px',
    height: '17px',
    color: theme.palette.primary.main
}));


const ActionButton = styled(IconButton)<{ active?: boolean }>(({ theme, active }) => ({
    color: active ? theme.palette.primary.contrastText : theme.palette.primary.inactive,
    padding: '0px',

    '&.landscape': {
        '& svg': {
            height: '11px',
            width: '18px'
          }
    },

    '&.portrait': {
        '& svg': {
            width: '12px',
            height: '17px'
          }
    },
    
    '&:hover': {
        background: 'transparent',
    }
  }));