import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CheckboxUncheckedIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 18 18'}
            {...props}>
                <rect x="0.75" y="0.75" width="16.5" height="16.5" rx="3.25" stroke="currentColor" strokeWidth="1.5" fill={'none'}/>
        </SvgIcon>
    );
};
