import React from 'react';
import {Tooltip, styled} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { UpscaleStatus } from '@root/utils/constants/enums';
import { CommonConstants } from '@root/utils/constants';


export const ScaleButton = ({className, upscaleOption, onClick, disabled}) => {

    const originalScale = upscaleOption.value === 'original';
    const isLoaded = upscaleOption.status === UpscaleStatus.Ready;
    const isLoading = upscaleOption.status === UpscaleStatus.Processing
        || upscaleOption.status === UpscaleStatus.Enqueued;

    return (
        <Tooltip
            title={
                (!originalScale && disabled)
                    ? CommonConstants.UPSCALE_WARNING_MESSAGE
                    : ''
            }>
            <span>
                <StyledButton
                    className={className}
                    variant={'text'}
                    disabled={!originalScale && disabled}
                    active={upscaleOption.state}
                    isLoaded={isLoaded}
                    onClick={onClick}
                    loading={isLoading}
                    loadingPosition={'end'}
                    endIcon={<></>}
                >
                    {originalScale ? 'Original' : `${upscaleOption.value}x`}
                </StyledButton>
            </span>
        </Tooltip>
    );
};

const extraProps = ['active', 'isLoaded'];
const StyledButton = styled(LoadingButton, {
    shouldForwardProp: (name) => !extraProps.includes(name)})(({active, isLoaded, theme, disabled}) => ({
  '--color': active ? theme.palette.primary.contrastText : isLoaded ?
                  theme.palette.primary.cta1 :
                  theme.palette.primary.inactive,
    height: '15px',
    minWidth: '35px',
    padding: '0',
    cursor: disabled ? 'default' : 'pointer',
    pointerEvents: disabled ? 'none' : 'auto',


    textTransform: 'capitalize',
    border: 'none',

    color: 'var(--color)',

    '&:hover': {
        background: 'none',
    },

    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '16px',
    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },

    '& .MuiLoadingButton-loadingIndicator': {
        right: '-5px',
        bottom: '2px',
        height: '14px',
        width: '14px'
    }
}));
