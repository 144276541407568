import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { mainRoutes } from '@routes/routes';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { IconButton } from '@mui/material';

import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

import ThemeContext from './context/ThemeContext/ThemeContext';

import Loader from './components/Loader';
import NotificationsScope from './components/NotificationsScope';

import AuthContextProvider from './context/AuthContext/AuthContextProvider';
import TunesContextProvider from './context/TunesContext/TunesContextProvider';
import ProjectsContextProvider from './context/ProjectsContext/ProjectsContextProvider';
import ModelsContextProvider from './context/ModelsContext/ModelsContextProvider';
import ActiveModelContextProvider from './context/ActiveModelContext/ActiveModelContextProvider';
import LoaderContextProvider from './context/LoaderContext/LoaderContextProvider';
import { NotificationsContextProvider } from '@context/NotificationsContext/useNotifications';
import GenerateContextProvider from './context/GenerateContext/GenerateContextProvider';
import { DragProvider } from '@context/DragContext/useDrag';
import { QuickActionContextProvider } from '@context/QuickActionContext/useQuickAction';
import { ModalContextProvider } from '@context/ModalContext/useModal';
import { RequestProvider } from '@hooks/request/UseRequest';
import { StyleDriveProvider } from './context/StyleDriveContext/useStyleDrive';
import { UpscaleToolProvider } from './context/UpscaleToolContext/useUpscaleTool';
import { MixImageProvider } from './context/MixImageContext/useMixImage';
import { CurateProvider } from '@hooks/curate/useCurate';
import { JobsContextProvider } from './hooks/jobs/UseJobs';
import RouteChangeListener from '@root/components/RouteChangeListener';
import { LicenseContextProvider } from './context/LicenseContext/useLicenseValidation';
import { SceneShiftProvider } from './context/SceneShiftContext/useSceneShift';

import './App.css';

function App() {
    const { toggleTheme, theme } = useContext(ThemeContext);
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <NotificationsContextProvider>
                    <RequestProvider>
                        <LoaderContextProvider>
                            <LicenseContextProvider>
                                <AuthContextProvider>
                                    <JobsContextProvider>
                                        <ModelsContextProvider>
                                            <ActiveModelContextProvider>
                                                <ProjectsContextProvider>
                                                    <TunesContextProvider>
                                                        <QuickActionContextProvider>
                                                            <ModalContextProvider>
                                                                <DragProvider>
                                                                    <GenerateContextProvider>
                                                                        <StyleDriveProvider>
                                                                            <MixImageProvider>
                                                                                <UpscaleToolProvider>
                                                                                    <SceneShiftProvider>
                                                                                        <CurateProvider>
                                                                                            <RouteChangeListener />
                                                                                            <Routes>
                                                                                                <Route path="*">
                                                                                                    {
                                                                                                        mainRoutes
                                                                                                    }
                                                                                                </Route>
                                                                                            </Routes>
                                                                                            <Loader />
                                                                                            <NotificationsScope />
                                                                                        </CurateProvider>
                                                                                    </SceneShiftProvider>
                                                                                </UpscaleToolProvider>
                                                                            </MixImageProvider>
                                                                        </StyleDriveProvider>
                                                                    </GenerateContextProvider>
                                                                </DragProvider>
                                                            </ModalContextProvider>
                                                        </QuickActionContextProvider>
                                                    </TunesContextProvider>
                                                </ProjectsContextProvider>
                                            </ActiveModelContextProvider>
                                        </ModelsContextProvider>
                                    </JobsContextProvider>
                                </AuthContextProvider>
                            </LicenseContextProvider>
                        </LoaderContextProvider>
                    </RequestProvider>
                </NotificationsContextProvider>
            </BrowserRouter>
            <IconButton
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    left: '20px',
                    display: 'none',
                }}
                onClick={toggleTheme}
                color="inherit">
                {theme.palette.mode === 'dark' ? (
                    <Brightness7Icon />
                ) : (
                    <Brightness4Icon />
                )}
            </IconButton>
        </ThemeProvider>
    );
}

export default App;
