import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, styled } from '@mui/material';

import { getDate } from '../../../utils/getDate';


import ModelsContext from '../../../context/ModelsContext/ModelsContext';
import { ModelStatus } from '@root/utils/constants/enums';
const CHECK_MODEL_STATUS_INTERVAL_TIME = 10000;

const SecondaryNavButtonListRow = ({ el, navigationBase }) => {
    const navigate = useNavigate();
    const [status, setStatus] = useState('');
    const { checkModelStatus } = useContext(ModelsContext);

    const checkStatusState =
        !status ||
        status === ModelStatus.Trained ||
        status === ModelStatus.Merged ||
        status === ModelStatus.Draft ||
        status === ModelStatus.Error;

    useEffect(() => {
        const interval = setInterval(() => {
            if (checkStatusState) {
                return;
            }
            checkModelStatus(el.Id).then((res) => {
                if (status !== res) {
                    setStatus(res);
                }
            });
        }, CHECK_MODEL_STATUS_INTERVAL_TIME);

        if (checkStatusState) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [status]);

    useEffect(() => {
        setStatus(el.StatusId);
    }, [el]);

    return (
        <Wrapper
            onClick={() => navigate(`${navigationBase}/${el.Id}`)}>
            <RowLabel
                variant="body14">
                {el.Name.replaceAll('.safetensors', '')}
            </RowLabel>
            <Date variant="body14">{getDate(el.DateCreated)}</Date>
        </Wrapper>
    );
};

export default SecondaryNavButtonListRow;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',

    gap: '15px',
    minHeight: '38px',
}));

const RowLabel = styled(Typography)(() => ({
    flex: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'clip',
    maxWidth: '115px'
}));

const Date = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.inactive,
    fontSize: '12px',
    lineHeight: '14px',
    whiteSpace: 'nowrap'
}));
