import React from 'react';
import {Box, IconButton, LinearProgress, styled, Tooltip, Typography} from '@mui/material';
import {CloseIcon} from '@assets/icons/CloseIcon';
import {JobWithAction} from '@utils/job/Job';
import {JobStatus} from '@utils/job/JobStatus';

interface StatusDisplayProps {
    task: JobWithAction;
    inactiveSection?: boolean;
    withQueuePosition?: boolean;
    className?: string;
}

export const StatusDisplay = ({task, className, inactiveSection = false, withQueuePosition}: StatusDisplayProps): React.JSX.Element => {
    const handleClick = () => {
        if (task.cancel) {
            task.cancel();
        }
    };

    return (
        <Wrapper className={className}>
            <Header>

                <Box>
                    { withQueuePosition &&
                        <Tooltip title="The task position in the general queue">
                            <span>
                                <QueuePositionLabel variant={'body14'} >
                                    {task.queuePosition}
                                </QueuePositionLabel>
                            </span>
                        </Tooltip>
                    }
                    <Typography variant={'body14'} >
                        {task.name}
                    </Typography>
                </Box>
                <InfoAndActionWrapper>
                    {
                        task.percentage != null &&
                        <InputLabel active={+inactiveSection} variant={'body14'}>{task.percentage}%</InputLabel>
                    }
                    <CancelButton onClick={handleClick} disabled={!task.cancel}>
                        <CloseIcon fontSize={'medium'}/>
                    </CancelButton>
                </InfoAndActionWrapper>
            </Header>

            {
                task.percentage == null && task.jobStatusId == JobStatus.PROCESSING ?
                    <StyledLinearProgress color={'primary'}/> :
                    <StyledLinearProgress variant="determinate" value={task.percentage ?? 0} color={'primary'}/>
            }
        </Wrapper>
    );
};

const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const InfoAndActionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const InputLabel = styled(Typography)<{active: number}>(({ theme, active }) => ({
    color: active ? theme.palette.primary.light1 : theme.palette.secondary.main,
}));

const QueuePositionLabel = styled(Typography)(({ theme }) => ({
    paddingRight: '10px',
    color: theme.palette.primary.main,
    cursor: 'default'
}));

const CancelButton = styled(IconButton)(({ theme }) => ({
  padding: '0',
  color: theme.palette.secondary.main
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.neutrals.light1
}));
