import React, { useContext } from 'react';
import { Favorite, FavoriteBorder } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import { useDrag } from '@context/DragContext/useDrag';
import TunesContext from '@context/TunesContext/TunesContext';
import SelectCheckbox from '@root/components/SelectCheckbox';
import { prefixBaseUrl } from '@root/utils/url/url';
import { TuneBlockImageProps } from './TuneBlockImage.types';
import { ImageHelpers } from '@root/utils/helpers';


const TuneBlockImage = ({ item, likeImage, setActiveIndex }: TuneBlockImageProps): React.JSX.Element => {
    const { selectMode, selectImage, isImageSelected, isImageLiked } =
        useContext(TunesContext);
    const { setDraggedImage } = useDrag();
    
    const openModal = (): void => {
        setActiveIndex();
    };

    const handleLikeImage = (): void => {
        likeImage(item.Id);
    };

    const onDragStart = async(): Promise<void> => {
        const file = item.Path ? await ImageHelpers.getImageFile(prefixBaseUrl(item.Path)) : null;
        setDraggedImage(file);
    };

    const onDragEnd = (): void => {
        setDraggedImage(null);
    };

    const handleSelectChange = (selected: boolean): void => {
        selectImage(item.Id, selected);
    };

    return (
        <ImageWrapper>
            <Image
                src={prefixBaseUrl(item.previewPath ? item.previewPath : item.Path)}
                alt={item.Path}
                loading="lazy"
                onClick={openModal}
                draggable
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
            />

            <IconButton
                onClick={handleLikeImage}
                className="icon"
                sx={{
                    opacity: isImageLiked(item.Id) ? '1' : '0',
                    position: 'absolute',
                    bottom: '0',
                    right: '0',
                    transition: 'all 0.3s ease-out;',

                    '& svg': {
                        fontSize: '18px',
                    },
                }}>
                {isImageLiked(item.Id) ? <Favorite /> : <FavoriteBorder />}
            </IconButton>
            <ImageCheckbox
                className={'tune-image-checkbox icon'}
                isHidden={!selectMode}
                checked={isImageSelected(item.Id)}
                onChange={handleSelectChange}
            />
        </ImageWrapper>
    );
};

export default TuneBlockImage;

const ImageWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',
    cursor: 'pointer',
    width: 'fit-content',
    height: 'fit-content',
    maxWidth: `calc((100% - ${theme.spacing(1.8)})/4)`,
    minWidth: '50px',

    background: theme.palette.primary.light,
    borderRadius: '15px',
    overflow: 'hidden',

    ' &:hover .icon': {
        opacity: 1,
    },
}));

const Image = styled('img')(() => ({
    maxHeight: '153px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    minHeight: '60px',
}));

const ImageCheckbox = styled(SelectCheckbox)<{isHidden: boolean}>(({ isHidden }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: isHidden ? 0 : 1,
    '&:hover': {
        boxShadow: 'none',
    },
}));
