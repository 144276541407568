import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BrushIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}>
            <path d="M14.18 19.8742C13.9184 19.2293 13.4407 18.6954 12.8287 18.3639C12.2167 18.0325 11.5085 17.9241 10.8254 18.0574C10.1423 18.1907 9.52677 18.5574 9.0843 19.0947C8.64183 19.6319 8.39992 20.3062 8.4 21.0022C8.4 21.3103 8.33672 21.6152 8.21408 21.8978C8.09144 22.1805 7.91206 22.4349 7.68704 22.6454C7.46203 22.8559 7.19619 23.0179 6.90599 23.1214C6.61579 23.225 6.30743 23.2678 6 23.2472C6.49445 24.1062 7.25863 24.7779 8.17392 25.158C9.08921 25.5382 10.1044 25.6056 11.0619 25.3497C12.0194 25.0938 12.8656 24.5289 13.4692 23.7428C14.0728 22.9567 14.4 21.9933 14.4 21.0022C14.4 20.6032 14.322 20.2222 14.18 19.8742ZM14.18 19.8742C15.369 19.4691 16.5062 18.9254 17.568 18.2542M12.525 18.2292C12.9302 17.0377 13.4746 15.8981 14.147 14.8342M17.567 18.2542C19.4633 17.0561 21.0865 15.4725 22.331 13.6062L26.1421 7.45511C26.2906 7.23368 26.3576 6.96756 26.3317 6.70221C26.3058 6.43686 26.1885 6.18873 26 6.00021C25.8115 5.81168 25.5634 5.69445 25.298 5.66854C25.0326 5.64262 24.7665 5.70963 24.5451 5.85811L18.796 10.0722C16.9295 11.3164 15.3456 12.9392 14.147 14.8352" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill={'none'}/>
        </SvgIcon>
    );
};
