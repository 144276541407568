import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const LinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
      {...props}>
      <path d="M11.9999 17.9721L10.2083 19.7637C9.41634 20.5555 8.34227 21.0003 7.22235 21.0003C6.10244 21.0003 5.02837 20.5555 4.23636 19.7637C3.44457 18.9717 2.99976 17.8976 2.99976 16.7777C2.99976 15.6578 3.44457 14.5837 4.23636 13.7917L7.81955 10.2085C8.61156 9.41673 9.68563 8.97192 10.8055 8.97192C11.9255 8.97192 12.9995 9.41673 13.7915 10.2085" stroke="white" strokeWidth="1.5" strokeLinecap="round" fill={'none'}/>
      <path d="M12 6.0282L13.7916 4.23661C14.5836 3.44481 15.6577 3 16.7776 3C17.8975 3 18.9716 3.44481 19.7636 4.23661C20.5554 5.02861 21.0002 6.10268 21.0002 7.2226C21.0002 8.34252 20.5554 9.41658 19.7636 10.2086L16.1804 13.7918C15.3884 14.5836 14.3143 15.0284 13.1944 15.0284C12.0745 15.0284 11.0004 14.5836 10.2084 13.7918" stroke="white" strokeWidth="1.5" strokeLinecap="round" fill={'none'}/>
    </SvgIcon>
  );
};
