import React from 'react';
import { IconButton, styled } from '@mui/material';
import { DragImageIcon } from '@root/assets/icons/DragImageIcon';
import { useSortableListRow } from '../SortableListRow/SortableListRow';

const ListRowDragButton = (): React.JSX.Element => {
    const { attributes, listeners, ref } = useSortableListRow();

    return (
        <DragImageButton {...attributes} {...listeners} ref={ref}>
            <DragImageIcon/>
        </DragImageButton>
    );
};

export default ListRowDragButton;

const DragImageButton = styled(IconButton)(({ theme }) => ({
    width: '14px',
    height: '20px',
    opacity: 0,
    padding: '0px',
    
    marginRight: '7px',
    color: theme.palette.neutrals.inactive1,
    
    '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderRadius: '4px'
    }
}));
