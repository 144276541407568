import {useUpscaleApi} from '@hooks/upscale/UpscaleApi';

interface UseUpscale {
    upscaleImage: (data: object) => Promise<boolean>;
    getUpscaledImageById: (id: number | string) => Promise<any>;
}

export const useUpscale = (): UseUpscale => {
    const { upscale, getUpscaledImage } = useUpscaleApi();

    return {
        upscaleImage: upscale,
        getUpscaledImageById: getUpscaledImage
    };
};

