import React, { useEffect, useState } from 'react';
import { Box, Slider, styled, Typography } from '@mui/material';
import { TwoComponentSliderProps } from './TwoComponentSlider.types';
import Thumb from './Thumb';
import LargeThumb from './LargeThumb';

const TwoComponentSlider = ({
    value,
    setValue,
    leftLabel,
    rightLabel,
    small = false,
    withLabels,
    withMarks = false
}: TwoComponentSliderProps): React.JSX.Element => {
    const [displayValue, setDisplayValue] = useState<number>(value);

    const onChange = (_: Event, newValue: number | number[]) => {
        setValue(newValue as number);
    };

    useEffect(() => {
        setDisplayValue(value);
    }, [value]);

    const Mark = ({ value }: { value: number }) => {
        return (
            <MarkWrapper>
                <Typography variant="subtitle2" align={'center'}>
                    {value}%
                </Typography>
            </MarkWrapper>
        );
    };

    return (
        <Wrapper className={small ? 'small' : 'large'}>
            { withLabels ?
                <SliderInfo>
                    <SliderLabel> {100 - displayValue}% </SliderLabel>
                    { leftLabel &&
                        <SliderLabel> {leftLabel} </SliderLabel>
                    }
                </SliderInfo>
                :
                <Mark value={100 - displayValue} />
            }
            <InfluenceSlider
                small={small}
                slots={small ? { thumb: Thumb } : { thumb: LargeThumb }}
                value={displayValue}
                valueLabelDisplay="off"
                step={withMarks ? 5 : 1}
                marks={withMarks}
                min={0}
                max={100}
                onChange={onChange}
            />
            { withLabels ?
                <SliderInfo>
                    <SliderLabel> {displayValue}% </SliderLabel>
                    { rightLabel &&
                        <SliderLabel>{rightLabel} </SliderLabel>
                    }
                </SliderInfo>
                :
                <Mark value={displayValue} />
            }
        </Wrapper>
    );
};

export default TwoComponentSlider;

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',

    '&.small': {
        width: '300px',
        gap:'16px',
    },

    '&.large': {
        padding: '0 30px',
        minWidth: '300px',
        maxWidth: '400px',
        gap: theme.spacing(2),
        width: '100%',
    },
}));

const SliderInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const SliderLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    color: theme.palette.primary.inactive,
    fontSize: '12px',
    lineHeight: '14px',
    '@media (max-width:1500px)': {
        fontSize: '10px',
        lineHeight: '12px',
    },
}));

const MarkWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    flex: '1',
    gap: '5px',
}));

const InfluenceSlider = styled(Slider)<{ small: boolean }>(({ theme, small }) => ({
    color: theme.palette.primary.light1,
    height: '2px',
    width: small ? '177px' : '100%',

    '& .MuiSlider-rail': {
        opacity: 1
    },

    '& .MuiSlider-track': {
        height: '0px',
    },

    '& .MuiSlider-markActive, & .MuiSlider-mark': {
        backgroundColor: theme.palette.neutrals.inactive1,
        opacity: 1
    },


    '& .MuiSlider-thumb': {
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',

        '&:before': {
            boxShadow: 'none',
        },

        '& svg': {
            width: small ? '20px' : '26px',
            height: small ? '20px' : '26px',
        },

        '&:after': {
            height: small ? '20px' : '42px',
            width: small ? '20px' : '42px',
        },

        '&:hover, &.Mui-focusVisible': {
            boxShadow: 'none',
        },
        '&.Mui-active': {
            boxShadow: 'none',
        },
    },
}));
