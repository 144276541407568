import React from 'react';
import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import SelectCheckbox from '@root/components/SelectCheckbox';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { ImagesViewType } from '@root/utils/constants/enums';
import { ListViewIcon } from '@root/assets/icons/ListViewIcon';
import { GridViewIcon } from '@root/assets/icons/GridViewIcon';
import { ModelListToolbarProps } from './types/ModelListToolbar.types';

const ModelListToolbar = ({
    selectMode,
    isAllSelected,
    deleteSelectedImages,
    selectAll,
    viewType,
    setView,
    isUnsaved
}: ModelListToolbarProps): React.JSX.Element => {
    
    const setListView = (): void => {
        setView(ImagesViewType.List);
    };

    const setGridView = (): void => {
        setView(ImagesViewType.Grid);
    };
    
    return (
        <Wrapper>
            { isUnsaved && (
                <InfoSection>
                    <InfoLabel>
                        {'If you switch to another page, your model changes will be lost. Please press \'Retrain\' to save changes.' }
                    </InfoLabel>
                </InfoSection>
            )}
            <Stack
                direction={'row'}
                gap={1.5}
                justifyContent="center"
                alignItems="center">
                <Checkbox
                    checked={isAllSelected()}
                    onChange={selectAll}
                    hidden={!selectMode}
                    
                />
                {selectMode && (
                    <Typography variant="subtitle2" color={'primary'}>
                        Select all
                    </Typography>
                )}
            </Stack>
            <ActionButton hidden={!selectMode} onClick={deleteSelectedImages}>
                <DeleteImageIcon  />
            </ActionButton>
            <ViewSection>
                <span>
                    <ViewButton
                        selected={viewType === ImagesViewType.Grid}
                        onClick={setGridView}
                        disableRipple>
                        <GridViewIcon />
                    </ViewButton>
                </span>
                <span>
                    <ViewButton
                        selected={viewType === ImagesViewType.List}
                        onClick={setListView}
                        disableRipple>
                        <ListViewIcon />
                    </ViewButton>
                </span>
            </ViewSection>
        </Wrapper>
    );
};

export default ModelListToolbar;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
}));

const ViewSection = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px',
    marginLeft:'80px',
    gap: '8px'
}));

const InfoSection = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    height: '24px',
    position: 'absolute',
    left: 0,
    padding: '20px'
}));

const ActionButton = styled(IconButton)<{ hidden: boolean }>(({ theme, hidden }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.primary.main,
    opacity: hidden ? 0 : 1,
    transition: 'all 0.3s ease-out',

    pointerEvents: hidden ? 'none' : 'auto',
    '& svg': {
        width: '24px',
        height: '24px',
    },

    '&:hover': {
        background: 'transparent',
    },

    marginLeft: '32px',
    ':last-child': {
        marginLeft: '14px',
    },
}));

const ViewButton = styled(IconButton)<{selected: boolean}>(({ theme, selected }) => ({
    width: '24px',
    height: '24px',
    color: selected ? theme.palette.primary.contrastText : theme.palette.primary.light1,
    transition: 'all 0.3s ease-out',
    backgroundColor: selected ? theme.palette.primary.light : 'none',
    borderRadius: '6px'

}));

const Checkbox = styled(SelectCheckbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        height: '20px',
        width: '20px',
        color: theme.palette.primary.main,
    },
    '& svg': {
        height: '20px',
        width: '20px'
    }
}));


const InfoLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.inactive,
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '14px',
    '@media (max-width:1500px)': {
        fontSize: '10px',
        lineHeight: '12px',
    },
}));