import { useRequest } from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { LoginDto } from '@root/types/dto';
import { RequestErrorCode } from '@root/utils/constants/enums';

interface UseAuthApi {
    signIn: (data: object) => Promise<LoginDto | SupportedRequestError>
}

export const useAuthApi = (): UseAuthApi => {
    const { requestPost } = useRequest();

    const signIn = async (data: object) => {
        const user = await requestPost('/auth/login', {data})
            .then((response) => {
                if (response?.data?.errorCode) {
                    return response?.data;
                }

                const accessToken = response?.data?.accessToken;
                const refreshToken = response?.data?.refreshToken;
                const roleId = response?.data?.roleId;
                if (!accessToken || !refreshToken || !roleId) {
                    return  {
                        errorCode: RequestErrorCode.AUTHORIZATION_ERROR,
                        message : RequestErrorCode.AUTHORIZATION_ERROR,
                    };
                }

                return {
                    accessToken,
                    refreshToken,
                    roleId
                } as LoginDto;
            });
        return user;
    };

    return {
        signIn
    };
};