export enum ModelStatus {
    Draft = 1,
    Queued,
    Training,
    Trained,
    Error,
    QueuedForMerging,
    Merging,
    Merged
}
