import React from 'react';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { ArrowBackIosNewRounded, ArrowForwardIosRounded } from '@mui/icons-material';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { CameraViewConstants, CommonConstants } from '@root/utils/constants';
import { CameraViewActionsProps } from './CameraViewActions.types';

const CameraViewActions = ({
    activeModel,
    modelDeleteIsEnable,
    positionDeleteIsEnable,
    positionLabel,
    currentModelIndex,
    isDisabled,
    moveToPreviousModel,
    moveToNextModel,
    moveToPreviousPosition,
    moveToNextPosition,
    deleteModel,
    addModel,
    savePosition,
    deletePosition
}: CameraViewActionsProps): React.JSX.Element => {
    
    return (
    <ActionsSection>
        <ActionRow>
            <StyledLabel>{'3D model :'}</StyledLabel>
            <SelectWithArrows>
            <ActionButton
                onClick={moveToPreviousModel}
                disabled={isDisabled}
                disableRipple>
                <ArrowBackIosNewRounded />
            </ActionButton>
            { activeModel ?
                <>
                    <InfoLabel>
                        { activeModel.name !== CameraViewConstants.DEFAULT_MODEL_NAME ?
                            `${CameraViewConstants.CUSTOM_CAMERA_VIEW_MODEL_PREFIX} ${currentModelIndex}` :
                            activeModel.name
                        }
                    </InfoLabel>
                    { modelDeleteIsEnable && 
                        <ActionIcon onClick={deleteModel} disableRipple><DeleteImageIcon /></ActionIcon>
                    }
                </>
                :
                <>
                    <label htmlFor="3dModelUpload">
                        <input
                            disabled={isDisabled}
                            style={{ display: 'none' }}
                            id="3dModelUpload"
                            type="file"
                            accept=".fbx, .obj, .gltf, .glb, .stl"
                            onChange={addModel}
                        />
                        <InfoLabel disabled={isDisabled} action={true}>{'+ add new'}</InfoLabel>
                    </label>
                </>
            }
            <ActionButton
                onClick={moveToNextModel}
                disabled={isDisabled}
                disableRipple>
                <ArrowForwardIosRounded />
            </ActionButton>
            </SelectWithArrows>
        </ActionRow>

        <ActionRow>
            <StyledLabel>{'position :'}</StyledLabel>
            <SelectWithArrows>
                <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToPreviousPosition}
                    disableRipple>
                    <ArrowBackIosNewRounded />
                </ActionButton>
                <InfoLabel
                    action={!!activeModel && !positionLabel}
                    {...(!positionLabel && { onClick: savePosition })}>
                        { activeModel ? (positionLabel || 'save position') : CommonConstants.INFO_NOT_FOUND_LABEL}
                </InfoLabel>
                { positionDeleteIsEnable && 
                    <ActionIcon onClick={deletePosition} disableRipple><DeleteImageIcon /></ActionIcon>
                }
                <ActionButton
                    disabled={!activeModel || isDisabled}
                    onClick={moveToNextPosition}
                    disableRipple>
                    <ArrowForwardIosRounded />
                </ActionButton>
            </SelectWithArrows>
        </ActionRow>
    </ActionsSection>
  );
};

export default CameraViewActions;


const ActionsSection = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    transition: 'all 0.5s',
    marginTop: '20px'
}));

const ActionRow = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: '28px',

    '&:hover': {
        'button': {
          opacity: '1 !important',
        }
    }
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.light1,
    fontSize: '16px',
    lineHeight: '18px',
    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },
    minWidth: '77px',
}));

const SelectWithArrows = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '195px',
    gap: '30px',
    marginLeft: '28px'
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    width: '15px',
    height: '15px',
    color: theme.palette.primary.contrastText,
  
    '& svg': {
      width: '15px',
      height: '15px',
    }
  }));

  const ActionIcon = styled(IconButton)(({ theme }) => ({
    width: '21px',
    height: '21px',
    color: theme.palette.primary.contrastText,

    position: 'absolute',
    right: '60px',
    opacity: '0',
  
    '& svg': {
      width: '21px',
      height: '21px',
    }
  }));

  const InfoLabel = styled(Typography)<{action?: boolean, disabled?: boolean}>(({ theme, action, disabled }) => ({
    fontFamily: 'Roboto400',

    ...(!disabled && {
        color: action ? theme.palette.primary.main : theme.palette.primary.contrastText,
        cursor: action ? 'pointer' : 'auto'
    }),

    ...(disabled && {
        color: theme.palette.primary.light1,
        cursor: 'auto'
    }),

    fontSize: '14px',
    lineHeight: '16px',
    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },
    textAlign: 'center',
}));