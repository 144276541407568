const ThemeTypography = {
    fontFamily: 'Roboto400',
    fontSize: 15,
    body12: {
        fontFamily: 'Roboto400',
        fontSize: '12px',
        lineHeight: '14px',
        '@media (max-width:1500px)': {
            fontSize: '10px',
            lineHeight: '12px',
        },
    },

    body14: {
        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',
        '@media (max-width:1500px)': {
            fontSize: '10px',
            lineHeight: '12px',
        },
    },

    subtitle1: {
        fontFamily: 'Roboto400',
        fontSize: '14px',
        lineHeight: '16px',
        '@media (max-width:1500px)': {
            fontSize: '12px',
            lineHeight: '14px',
        },
    },

    body16: {
        fontFamily: 'Roboto400',
        fontSize: '16px',
        lineHeight: '18px',
        '@media (max-width:1500px)': {
            fontSize: '14px',
            lineHeight: '16px',
        },
    },
    body18: {
        fontFamily: 'Roboto400',
        fontSize: '18px',
        lineHeight: '28px',
        '@media (max-width:1500px)': {
            fontSize: '15px',
            lineHeight: '31px',
        },
    },
    subtitle2: {
        fontFamily: 'Roboto400',
        fontSize: '18px',
        lineHeight: '28px',
        '@media (max-width:1500px)': {
            fontSize: '15px',
            lineHeight: '31px',
        },
    },
    body20: {
        fontFamily: 'Roboto400',
        fontSize: '20px',
        lineHeight: '24px',
        '@media (max-width:1500px)': {
            fontSize: '18px',
            lineHeight: '20px',
        },
    },
    body24: {
        fontFamily: 'Roboto400',
        fontSize: '24px',
        lineHeight: '30px',
        '@media (max-width:1500px)': {
            fontSize: '18px',
            lineHeight: '24px',
        },
    },
    tableHeader: {
        fontFamily: 'Roboto700',
        fontSize: '18px',
        lineHeight: '30px',
        '@media (max-width:1500px)': {
            fontSize: '15px',
            lineHeight: '24px',
        },
    },
    header: {
        fontFamily: 'Roboto500',
        fontSize: '28px',
        lineHeight: '33px',
        '@media (max-width:1500px)': {
            fontSize: '26px',
            lineHeight: '30px',
        },
    },

    button: {
        fontFamily: 'Roboto500',
        fontSize: '18px',
        '@media (max-width:1500px)': {
            fontSize: '15px',
            lineHeight: '18px',
        },
    },
};

export default ThemeTypography;
