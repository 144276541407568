import React from 'react';
import { Navigate } from 'react-router-dom';
import MainPage from '@root/pages/MainPage/MainPage';
import AuthPage from '@root/pages/AuthSystem/AuthPage';
import GenerationLayout from '@root/pages/Generations/GenerationLayout';
import GeneratePage from '@root/pages/Generations/GeneratePage/GeneratePage';
import ModelsListPage from '@root/pages/Models/ModelsListPage/ModelsListPage';
import ModelsTrainPage from '@root/pages/Models/ModelsTrainPage/ModelsTrainPage';
import ProjectsListPage from '@root/pages/Projects/ProjectListPage/ProjectsListPage';
import CuratePage from '../pages/CuratePage/CuratePage';
import ScenePage from '@root/pages/ScenePage';
import AdminPage from '@root/pages/AdminPage';
import { ScenePageProvider } from '@root/context/ScenePageContext/useScene';
import { GuardType, NavigationPaths } from '@root/utils/constants/enums';

import {
    GENERATE_PAGE_NAME, 
    AUTHENTICATION_PAGE_NAME, 
    USER_LIST_PAGE_NAME, 
    CURATE_PAGE_NAME,
    TRAIN_PAGE_NAME,
    MODEL_PAGE_NAME,
    MY_PROJECTS_PAGE_NAME,
    MY_MODELS_PAGE_NAME,
    SCENE_PAGE_NAME
 } from '@root/utils/constants/pagesName';

type Route = {
    mainElement: JSX.Element | string;
    elementName?: string;
    label?: string;
    pageName?: string;
    child?: Route[];
    path?: string;
    navigationBase?: string;
    guard?: GuardType;
};

const routesConfig: Route[] = [
    {
        path: '*',
        mainElement: <Navigate to={`/${NavigationPaths.Auth}`} replace />,
    },
    {
        path: NavigationPaths.Auth,
        mainElement: <AuthPage />,
        pageName: AUTHENTICATION_PAGE_NAME,
    },
    {
        guard: GuardType.LoggedInOnly,
        mainElement: <MainPage />,
        label: 'MainNavigation',
        child: [
            {
                path: NavigationPaths.CreatePage,
                mainElement: <GenerationLayout />,
                elementName: 'Create',
                label: 'CreatePage',
                child: [
                    {
                        path: '',
                        mainElement: <GeneratePage />,
                        elementName: 'Create',
                        pageName: GENERATE_PAGE_NAME,
                    },
                    {
                        path: ':id',
                        mainElement: <GeneratePage />,
                        pageName: GENERATE_PAGE_NAME,
                    },
                ],
            },
            {
                path: NavigationPaths.RefinePage,
                mainElement: <CuratePage />,
                label: 'RefinePage',
                elementName: 'Refine',
                pageName: CURATE_PAGE_NAME,
            },
            {
                path: NavigationPaths.Models,
                mainElement: <ModelsTrainPage />,
                label: 'ModelsPage',
                elementName: 'Enhance',
                pageName: TRAIN_PAGE_NAME,
            },
            {
                path: NavigationPaths.Scene,
                mainElement: <ScenePageProvider><ScenePage /></ScenePageProvider>,
                label: 'ScenePage',
                elementName: 'Scene',
                pageName: SCENE_PAGE_NAME,
            },
            {
                path: `${NavigationPaths.Models}/:id`,
                mainElement: <ModelsTrainPage />,
                pageName: MODEL_PAGE_NAME,
            },
            {
                guard: GuardType.AdminOnly,
                path: NavigationPaths.Users,
                mainElement: <AdminPage />,
                pageName: USER_LIST_PAGE_NAME,
            },
        ],
    },
    {
        guard: GuardType.LoggedInOnly,
        mainElement: <MainPage />,
        label: 'SecondaryNavigation',
        child: [
            {
                path: NavigationPaths.Projects,
                mainElement: <ProjectsListPage />,
                elementName: 'My projects',
                navigationBase: `/${NavigationPaths.CreatePage}`,
                pageName: MY_PROJECTS_PAGE_NAME,
            },
            {
                path: NavigationPaths.ModelsList,
                mainElement: <ModelsListPage />,
                label: 'ModelsPage',
                elementName: 'My models',
                navigationBase: `/${NavigationPaths.Models}`,
                pageName: MY_MODELS_PAGE_NAME,
            },
        ],
    },
];

export default routesConfig;
export type { Route };
