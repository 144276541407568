import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';
import ActiveModelContext from '../../../../context/ActiveModelContext/ActiveModelContext';

import { ImagesViewType, ModelStatus } from '@root/utils/constants/enums';
import ModelListToolbar from './ModelListToolbar';
import ImageSection from './ImageSection';
import { ModelImageFile, UploadedModelImageFile, UploadedRetrainFile } from '@root/types/commonTypes';
import { ImagesGridProps } from './types/ImagesGrid.types';
import ImageUploader from './ImageUploader';

const ImagesGrid = ({ onCreate, uploadImagesToDB, isFormUnsaved }: ImagesGridProps): React.JSX.Element => {
    const { id } = useParams();
    const location = useLocation();

    const {
        activeModelImages,
        activeModel,
        deleteImage,
        setActiveModelImages,
        removedFiles,
        uploadedFiles,
        setRemovedFiles,
        setUploadedFiles,
        isDraft,
        isDefaultPage,
        setIsDefaultPage
    } = useContext(ActiveModelContext);

    const [uploadedImages, setUploadedImages] = useState<UploadedModelImageFile[]>([]);
    const [selectMode, setSelectMode] = useState<boolean>(false);
    const [selectedImageIds, setSelectedImageIds] = useState<number[]>([]);
    const [sectionView, setSectionView] = useState<ImagesViewType>(ImagesViewType.Grid);

    const isRetrainMode = id && activeModel?.StatusId === ModelStatus.Trained && activeModel.ParentId;

    const handleInitialImportImage = async (fileList: File[]): Promise<void> => {
        let modelId = id || null;
        if (!id) {
            modelId = await onCreate();
        }
        if (modelId) {
            addPreviewImages(fileList);
            await uploadImagesToDB(fileList, modelId);
            setUploadedImages([]);
        }
    };

    const handleImportImage = async (fileList: File[]): Promise<void> => {
        if (id) {
            addPreviewImages(fileList);
            if (!isRetrainMode) {
                await uploadImagesToDB(fileList, id);
                setUploadedImages([]);
            }
        }
    };

    const addPreviewImages = (fileList: File[]): void => {
        const chosenFiles = Array.prototype.slice.call(fileList);
        const uploaded = uploadedImages as UploadedModelImageFile[];
        const uploadedForRetrain = isRetrainMode ? uploadedFiles as UploadedRetrainFile[] : [];

        chosenFiles.forEach((file) => {
            const id = URL.createObjectURL(file);
            const newImage = {
                ...(isRetrainMode && { Id: id}),
                FileName: file.name,
                lastModified: file.lastModified,
                file: URL.createObjectURL(file),
                Prompt: '',
            };
            uploaded.unshift(newImage);

            if (isRetrainMode) {
                const newFile: UploadedRetrainFile = {
                    Id: id,
                    file: file,
                    Prompt: '',
                };
                uploadedForRetrain.unshift(newFile);
            }

        });

        setUploadedImages([...uploaded]);

        if (uploadedForRetrain.length) {
            setUploadedFiles([...uploadedForRetrain]);
        }
    };

    const handleDeleteImage = async (id: number | string): Promise<void> => {
        if(!isRetrainMode) {
            const result = await deleteImage(id);

            if (result && !result.errorCode) {
                setActiveModelImages((prev: ModelImageFile[]) =>
                    prev.filter((el: ModelImageFile) => el.Id !== id && el),
                );
            }
        } else {
            if (typeof id === 'string') {
                setUploadedImages((prev: UploadedModelImageFile[]) =>
                    prev.filter((el: UploadedModelImageFile) => el.Id !== id && el),
                );
            } else {
                setActiveModelImages((prev: ModelImageFile[]) =>
                    prev.filter((el: ModelImageFile) => el.Id !== id && el),
                );
            }
        }
    };

    const deleteSelectedImages = (): void => {
        const removed = selectedImageIds.filter(val => typeof val === 'number');
        selectedImageIds.forEach((id) => handleDeleteImage(id));

        setRemovedFiles([...removedFiles, ...removed]);
        setSelectedImageIds([]);
    };

    const getImagesList = (): any[] => {
        let listOfImages = [];

        if (!isRetrainMode) {
            listOfImages = activeModelImages;
        } else {
         listOfImages = activeModelImages.concat(uploadedImages);   
        }

        return listOfImages;
    };

    const selectAll = (checked: boolean): void  => {
        const listOfImages = getImagesList();

        if (checked) {
            const allImageIds = [].concat(
                ...listOfImages.map((image: any) => image.Id));
            setSelectedImageIds(allImageIds);
        } else {
            setSelectedImageIds([]);
        }
    };

    const isImageSelected = (imageId: number): boolean  => {
        return selectedImageIds.includes(imageId);
    };

    const selectImage = (imageId: number, selected: boolean): void => {
        const listOfImages = getImagesList();

        const foundImage = !!listOfImages.find((image: any) => image.Id === imageId);
        if (!foundImage) {
            return;
        }

        if (selected) {
            setSelectedImageIds((ids) => [...ids, imageId]);
        } else {
            setSelectedImageIds((ids) => ids.filter((id) => id !== imageId));
        }
    };

    const isAllSelected = useCallback(() => {
        const listOfImages = getImagesList();

        return !listOfImages.length
            ? false
            : listOfImages.every((image: any) =>
                selectedImageIds.includes(image.Id),
            );
    }, [activeModelImages, selectedImageIds, uploadedImages]);

    useEffect(() => {
        setUploadedImages([]);
    }, [location]);

    useEffect(() => {
        setIsDefaultPage(!activeModelImages.length && !uploadedImages.length);
    }, [activeModel, uploadedImages, activeModelImages]);

    useEffect(() => {
        setSelectMode(!!selectedImageIds.length);
    }, [selectedImageIds]);

    return (
        <Wrapper>
        { isDefaultPage &&
            <DefaultContent>
                { activeModel.StatusId !== ModelStatus.Trained &&
                    activeModel.StatusId !== ModelStatus.Merged && (
                    <ImageUploader 
                        isDefault={true} 
                        handleImportImage={handleInitialImportImage}/>
                )}
            </DefaultContent> 
        }
        { !isDefaultPage && (
            <>
                <Header>
                    <ModelListToolbar
                        isUnsaved={isRetrainMode && (isFormUnsaved || isDraft)}
                        deleteSelectedImages={deleteSelectedImages}
                        isAllSelected={isAllSelected}
                        viewType={sectionView}
                        selectAll={selectAll}
                        selectMode={selectMode}
                        setView={setSectionView}
                    />
                </Header>
                <Content>
                    { (!!uploadedImages.length || !!activeModelImages.length) &&         
                        <CustomImageList 
                            className={sectionView === ImagesViewType.Grid ? 'grid-view' : 'list-view'}>

                            { (activeModel.StatusId === ModelStatus.Draft || isRetrainMode) && (
                                <ImageUploader 
                                    isDefault={false} 
                                    handleImportImage={handleImportImage}/>
                            )}
                            { !!uploadedImages.length &&                                 
                                uploadedImages.map((item: UploadedModelImageFile) => (
                                    <ImageSection
                                        isRetrain={isRetrainMode}
                                        key={item.lastModified}
                                        img={item}
                                        preview={true}
                                        selectMode={selectMode}
                                        isImageSelected={isImageSelected}
                                        selectImage={selectImage}
                                        viewType={sectionView}
                                    />
                                ))
                            }
                            { !!activeModelImages.length &&                                 
                                activeModelImages.map((img: ModelImageFile) => (
                                    <ImageSection
                                        isRetrain={isRetrainMode}
                                        key={img.Id}
                                        img={img}
                                        selectMode={selectMode}
                                        isImageSelected={isImageSelected}
                                        selectImage={selectImage}
                                        viewType={sectionView}
                                    />
                                ))
                            }
                        </CustomImageList>
                    }
                </Content>
            </>
            )
        }
        </Wrapper>
    );
};

export default ImagesGrid;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
}));

const DefaultContent = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Header = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',

    position: 'relative',
    paddingTop: '10px',
    right: '0'
}));

const Content = styled(Box)(() => ({
    display:'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'overlay',
}));

const CustomImageList = styled(Box)(() => ({
    gap: '20px',
    width: '100%',
    marginTop: '20px',
    marginBottom: '80px',
    padding: '20px',
    overflowY: 'auto',

    '&.list-view': {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '50% 50%',
        overflowX: 'hidden',
    },

    '&.grid-view': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: '100%',
    }
}));
