import React, { DragEvent, useEffect, useState } from 'react';
import { useDrag } from '@root/context/DragContext/useDrag';
import { Box, styled, Typography } from '@mui/material';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { FileUploader } from 'react-drag-drop-files';
import { CommonConstants } from '@root/utils/constants';
import { DragAndDropObjectPanelProps } from './DragAndDropObjectPanel.types';

const DragAndDropObjectPanel = ({
    onObjectUpload,
    label,
    isEnabled = true
}: DragAndDropObjectPanelProps): React.JSX.Element => {
    const { draggedImage, draggedObject } = useDrag();

    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [isLabelWithItemName, setIsLabelWithItemName] = useState<boolean>(false);
    const [complexLabelParts, setComplexLabelParts] = useState<[string, string, string]>(['', '', '']);

    useEffect(() => {
        const parts = label.split(CommonConstants.COMPLEX_LABEL_SEPARATOR);
        if (parts?.length === CommonConstants.DEFAULT_NUMBER_OF_COMPLEX_LABEL_PARTS) {
            setIsLabelWithItemName(true);
            setComplexLabelParts([parts[0], parts[1], parts[2]]);
        }
    }, []);

    const handleDragOver = (event: DragEvent<HTMLDivElement>): void => {
        event.stopPropagation();
        event.preventDefault();
    };

    const handleDrop = async (): Promise<void> => {
        handleUpload(draggedImage);
    };

    const handleUpload = async (file: File| Blob): Promise<void> => {
        setImageUrl(file ? URL.createObjectURL(file) : '');
        onObjectUpload(draggedObject);
    };

    const ImagePlaceholder = (): React.JSX.Element => {
        return (
            <ImagePlaceholderWrapper>
                <StyledAddImageIngredientIcon />
                {
                    isLabelWithItemName ?
                    <PlaceholderTypography variant="caption">
                        { complexLabelParts[0] }
                        <span className='item-name'>{ complexLabelParts[1] }</span>
                        { complexLabelParts[2] }
                    </PlaceholderTypography>
                    :
                    <PlaceholderTypography variant="caption">
                        {label}
                    </PlaceholderTypography>
                }
            </ImagePlaceholderWrapper>
        );
    };

  return (
    <Wrapper>
        <FileUploader
            disabled={true}>
            <ImageWrapper active={!!imageUrl}>
                { imageUrl ? 
                    <ImageSrc src={imageUrl} />
                    :
                    <ImagePlaceholder />
                }
            </ImageWrapper>
        </FileUploader>
        <Dropzone
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            enabled={isEnabled}
        />
    </Wrapper>
  );
};

export default DragAndDropObjectPanel;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

const ImageSrc = styled('img')(() => ({
    width: '100%',
    height:'100%',
    objectFit: 'cover',
}));

const ImageWrapper = styled(Box)<{ active: boolean }>(({ theme, active }) => ({
    maxWidth: '300px',
    minWidth: '100px',
    maxHeight: '197px',
    minHeight: '100px',

    backgroundColor: theme.palette.primary.light,

    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'inherit',
    aspectRatio: active ? '1' : 'auto'
}));

const ImagePlaceholderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: theme.palette.primary.grey,

    width: '300px',

    '@media (max-width:1800px)': {
        width: '200px',
    },

    aspectRatio: 'auto',
    height: '110px',
}));


const StyledAddImageIngredientIcon = styled(AddImageIngredientIcon)(({ theme }) => ({
    display: 'flex',
    marginTop: '24px',
    margin: '24px 14px 14px 14px',

    height: '41px',
    width: '41px',
    color:  theme.palette.primary.grey
}));

const Dropzone = styled(Box)<{enabled: boolean}>(({ enabled }) => ({
    width: '100%',
    height: '85%',
    position: 'absolute',
    display: enabled ? 'flex' : 'none',
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    alignItems:'center',
    paddingBottom:'10px',
    maxWidth: '170px',
    textAlign: 'center',
    color:  theme.palette.primary.inactive,

    '& .item-name': {
        color: theme.palette.secondary.main
    }
}));