import TwoComponentSlider from '@root/components/TwoComponentSlider';
import React from 'react';
import { MixImageIngredientPanel } from '../MixImageIngredientPanel';
import { Box, IconButton, Stack, styled } from '@mui/material';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';

const MixImageFooter = (): React.JSX.Element => {

    const {
        firstIngredientWeight,
        setFirstIngredientWeight,
        isFooterExpanded,
        setIsFooterExpanded
    } = useMixImage();

    const buttonAction = (): void => {
        setIsFooterExpanded(!isFooterExpanded);
    };

  return (
    <MixImageFooterContainer expanded={isFooterExpanded}>
            <ExpandButtonContainer active={isFooterExpanded}>
            <MenuIcon
                onClick={buttonAction}
                disableRipple>
                {isFooterExpanded ? (
                    <KeyboardArrowRightOutlined />
                ) : (
                    <KeyboardArrowLeftOutlined />
                )}
            </MenuIcon>
            </ExpandButtonContainer>
        <MixImageContainer expanded={isFooterExpanded}>
            <Section expanded={isFooterExpanded}>
                <MixImageIngredientPanel isExpanded={isFooterExpanded} isFirstIngredient={true} />
            </Section>
            <TwoComponentSlider
                small={true}
                withMarks={true}
                value={firstIngredientWeight}
                setValue={setFirstIngredientWeight}/>
            <Section expanded={isFooterExpanded}>
                <MixImageIngredientPanel isExpanded={isFooterExpanded} isFirstIngredient={false} />
            </Section>
        </MixImageContainer>
    </MixImageFooterContainer>
  );
};

export default MixImageFooter;

const MixImageFooterContainer = styled(Stack)<{expanded: boolean}>(({ expanded }) => ({
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    width: '100%',
    height: expanded ? '226px' : '105px',

    transition: 'height 0.5s',
    bottom: 0,
    position: 'absolute',
    zIndex: '10'
}));

const MixImageContainer = styled(Stack)<{expanded: boolean}>(({ expanded, theme }) => ({
    flexDirection: 'row',
    justifyContent: expanded ? 'center' : 'center',
    alignItems: 'center',
    gap: '20px',
    height: expanded ? '192px' : '71px',
    width: expanded ? '976px' : '528px',

    background: theme.palette.neutrals.main,
    borderTopRightRadius: '28px',
    borderTopLeftRadius: '28px',

    padding: expanded ? '20px' : '5px',
    transition: 'all 0.5s',
    boxShadow: expanded ? '0px -10px 20px 0px rgba(0, 0, 0, 0.35)' : 'none',
    zIndex: 5
}));

const Section = styled(Stack)<{expanded: boolean}>(({ expanded }) => ({
    width: expanded ? '35%' : '22%',
}));

const ExpandButtonContainer = styled(Box)<{active: boolean}>(({ theme, active }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    zIndex: 4,

    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    background: theme.palette.neutrals.main,
    border: `1px solid ${theme.palette.primary.light}`,
    borderBottom: 'none',

    width: '64px',
    height: '34px',

    transition: 'all 0.5s',
    transitionDelay: active ? '0s' : ' 0.5s'
}));

const MenuIcon = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& svg': {
        fill: theme.palette.primary.light1,
        transform: 'rotate(90deg)',

        '&:hover': {
            fill: theme.palette.secondary.main,
        }
    }
}));
