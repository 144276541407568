import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    IconButton,
    Paper,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from '@mui/material';
import { getDate } from '../../../../utils/getDate';
import { CustomTable } from '../../../../assets/theme/ThemeComponents';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import ProjectsContext from '../../../../context/ProjectsContext/ProjectsContext';
import { NavigationPaths, RequestErrorCode } from '@root/utils/constants/enums';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import { EditIcon } from '@root/assets/icons/EditIcon';
import { CloseIcon } from '@root/assets/icons/CloseIcon';
import { SaveIcon } from '@root/assets/icons/SaveIcon';
import { ProjectDto } from '@root/types/dto';
import { ErrorMessages, ProjectConstants } from '@root/utils/constants';
import { ErrorTooltip } from '@root/components/ErrorTooltip';

export interface ProjectTableProps {
    data: ProjectDto[];
}

const ProjectTable = ({ data }: ProjectTableProps): React.JSX.Element => {
    const navigate = useNavigate();
    const { createNotification, notificationMessages } = useNotifications();
    const { deleteProjectById, renameProject, validateProjectName } = useContext(ProjectsContext);

    const [unsavedProjectId, setUnsavedProjectId] = useState<number| null>(null);
    const [unsavedProjectName, setUnsavedProjectName] = useState<string | null>(null);
    const [selectedProjectName, setSelectedProjectName] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {

        return () => {
            stopEditMode();
        };
    }, []);

    const deleteProject = (event: React.MouseEvent, id: number, name: string): void => {
        event.stopPropagation();

        if (name === ProjectConstants.DEFAULT_PROJECT_NAME) {
            createNotification(
                notificationMessages.project.deleting.title,
                notificationMessages.project.deleting.info || '',
            );
        } else {
            deleteProjectById(id);
        }
    };

    const startEditMode = (event: React.MouseEvent, id: number, name: string): void => {
        event.stopPropagation();

        setUnsavedProjectId(id);
        setUnsavedProjectName(name);
        setSelectedProjectName(name);
        setErrorMessage('');
    };

    const stopEditMode = (event?: React.MouseEvent): void => {
        event?.stopPropagation();

        setUnsavedProjectId(null);
        setUnsavedProjectName(null);
        setSelectedProjectName(null);
        setErrorMessage('');
    };

    const saveProject = async (event: React.MouseEvent): Promise<void> => {
        event.stopPropagation();

        const validationErrorMessage = validateProjectName(unsavedProjectName);
        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
            return;
        }

        if (unsavedProjectName === selectedProjectName) {
            stopEditMode();
            return;
        }

        const projectInfo = {
            name: `${unsavedProjectName}`,
            id: `${unsavedProjectId}`
        };

        const result = await renameProject(projectInfo);

        if (result?.errorCode) {
            setErrorMessage(result?.errorCode === RequestErrorCode.PROJECT_ALREADY_EXISTS ? 
                ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.ALREADY_EXISTS :
                ErrorMessages.PROJECT_NAME_ERROR_MESSAGE.RENAME_ERROR);
        } else {
            stopEditMode();
        }
    };

    const onRowClick = (id: number): void => {
        if (unsavedProjectId) {
            return;
        }

        navigate(`/${NavigationPaths.CreatePage}/${id}`);
    };

    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorMessage) {
            setErrorMessage('');
        }

        setUnsavedProjectName(event.target.value);
    };

    const blurHandler = (): void => {
        const validationErrorMessage = validateProjectName(unsavedProjectName) as string;
        if (validationErrorMessage) {
            setErrorMessage(validationErrorMessage);
        }
    };

    return (
        <TableContainer component={Paper}>
            {data.length ? (
                <CustomTable>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <TableHeader>{'Project name'}</TableHeader>
                            </TableCell>
                            <TableCell align="center">
                                <TableHeader>{'Date'}</TableHeader>
                            </TableCell>
                            <TableCell align="center">
                                <TableHeader>{'Number of images'}</TableHeader>
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {data.map((project: ProjectDto) => (
                            <ProjectTableRow
                                key={project.Id}
                                onClick={() => onRowClick(project.Id)}>

                                { unsavedProjectId !== project.Id 
                                    ?
                                        <StyledTableCell component="th" scope="row">
                                            <RowTypography>{ project.Name }</RowTypography>
                                        </StyledTableCell>
                                    :
                                        <TableCellWithInput component="th" scope="row">
                                            <ErrorTooltip title={errorMessage} placement="right" open={!!errorMessage} arrow>
                                                <CustomInput
                                                    id={project.Id.toString()}
                                                    multiline={false}
                                                    variant="outlined"
                                                    onChange={onChangeHandler}
                                                    onBlur={blurHandler}
                                                    value={unsavedProjectName}
                                                    error={!!errorMessage}
                                                />
                                            </ErrorTooltip>
                                        </TableCellWithInput>
                                }

                                <TableCell align="center">
                                    <RowTypography>{ getDate(project.DateCreated) }</RowTypography>
                                </TableCell>
                                <TableCell align="center">
                                    <RowTypography>{ project.ImagesNumber }</RowTypography>
                                </TableCell>

                                <ActionTableCell align="right">
                                    { project.Name !== ProjectConstants.DEFAULT_PROJECT_NAME && 
                                    <>
                                        { unsavedProjectId !== project.Id
                                            ?
                                                <>
                                                    <IconButtonCss
                                                        disableRipple
                                                        onClick={(event) =>
                                                            startEditMode(event, project.Id, project.Name)}>
                                                        <EditIcon/>
                                                    </IconButtonCss>
                                                    <IconButtonCss
                                                        disableRipple
                                                        onClick={(event) =>
                                                            deleteProject(event, project.Id, project.Name)
                                                        }>
                                                        <DeleteImageIcon/>
                                                    </IconButtonCss>
                                                </>
                                            :
                                                <>
                                                    <IconButtonCss
                                                        disableRipple
                                                        onClick={saveProject}>
                                                        <SaveIcon/>
                                                    </IconButtonCss>
                                                    <IconButtonCss
                                                        disableRipple
                                                        onClick={stopEditMode}>
                                                        <CloseIcon/>
                                                    </IconButtonCss>
                                                </>
                                        }
                                    </>
                                    }
                                </ActionTableCell>
                            </ProjectTableRow>
                        ))}
                    </TableBody>
                </CustomTable>
            ) : (
                ''
            )}
        </TableContainer>
    );
};

export default ProjectTable;


const ProjectTableRow = styled(TableRow)(() => ({
    textDecoration: 'none',
}));

const ActionTableCell = styled(TableCell)(() => ({
    width: '20%',
    paddingRight: '65px'
}));

const StyledTableCell = styled(TableCell)(() => ({
    width: '360px'
}));

const TableHeader = styled(Typography)(() => ({
    fontFamily: 'Roboto700',
    fontSize: '18px',
    lineHeight: '30px',
    '@media (max-width:1500px)': {
        fontSize: '15px',
        lineHeight: '24px',
    },
}));


const TableCellWithInput = styled(TableCell)(() => ({
    padding: '11px 16px'
}));

const RowTypography = styled(Typography)(() => ({
    fontFamily: 'Roboto400',
    fontSize: '16px',
    lineHeight: '22px'
}));

const IconButtonCss = styled(IconButton)(({ theme }) => ({
    width: '20px',
    height: '20px',

    color: theme.palette.neutrals.inactive1,

    '& svg': {
        fontSize: '20px',
    },

    '&:hover': {
        background: 'transparent',
        color: theme.palette.primary.contrastText,
    },

    ':last-child': {
        marginLeft: '20px'
    },
}));


const CustomInput = styled(TextField)(({ theme }) => ({
    width: '100%',

    '& input': {
        height: '11px',
        padding: '11px',
        fontSize: '16px',
        color: theme.palette.primary.contrastText,
    }
}));
