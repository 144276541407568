import React, { useEffect, useState } from 'react';
import InfoModal from '../InfoModal';
import { Box, Button, Typography, styled } from '@mui/material';
import { LicenseStatus } from '@root/utils/constants/enums';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const LicenseValidationErrorModal = (): React.JSX.Element  => {
	const { validationStatus } = useLicenseValidation();
	const [isVisible, setIsVisible] = useState<boolean>(false);

	const handleClose = (): void => {
		setIsVisible(false);
	};

	useEffect(() => {
		setIsVisible(!!(validationStatus && validationStatus?.licenseStatus === LicenseStatus.FAILED));
	}, [validationStatus]);
  
	return (
		<InfoModal
			isActive={isVisible}
			onClose={handleClose}
			allowAutoClosing={false}
			headerTitle={'License validation error '}>
			<ErrorInfoSection>
				<MessageLabel>{'The license validation was not successful.'}</MessageLabel>
				<MessageLabel>
					{'Part of the functionality is unavailable.'}
				</MessageLabel>
				<ContentRow>
					<ActionButton variant="contained"
						onClick={handleClose}>
						{'OK'}
					</ActionButton>
				</ContentRow>
			</ErrorInfoSection>
		</InfoModal>
	);
};

export default LicenseValidationErrorModal;

const ErrorInfoSection = styled(Box)(() => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
    height: '100%',
    
    '& .MuiTypography-root': {
        fontFamily: 'Roboto400',
        fontSize: '16px',
        lineHeight: '22px'
    }
}));

const MessageLabel = styled(Typography)(() => ({
	justifyContent:'center',
	display: 'flex',
    alignItems: 'center',
	height: '100%'
}));

const ContentRow = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
}));


const ActionButton = styled(Button)(() => ({
    height: '36px',
    fontFamily: 'Roboto500',
    fontSize: '14px !important',
    lineHeight: '19.6px !important'
}));
