import React from 'react';
import { styled } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { NavButtonProps } from './NavButton.types';

const NavButton = ({ label, to }: NavButtonProps) => {
    return (
        <ButtonWrapper to={to}>
            {label}
        </ButtonWrapper>
    );
};

export default NavButton;

const ButtonWrapper = styled(NavLink)(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',

    height: '100%',
    width: '73px',

    color: theme.palette.primary.inactive,
    padding: '20px 0px',
    borderBottom: '2px solid transparent',

    fontSize: '18px',
    fontFamily: 'Roboto500',
    transition: 'all 0.5s',

    '&.active': {
        color: theme.palette.primary.main,
        borderBottom: `2px solid ${theme.palette.primary.main}`,
    },

    '@media (max-width:1500px)': {
        fontSize: '16px',
    },

    '&:hover:not(.active)': {
        color: theme.palette.secondary.main,
    }
}));