import React from 'react';
import { Box, Modal, Typography, styled } from '@mui/material';
import { CloseIcon } from '@root/assets/icons/CloseIcon';
import ToolbarButton from '@root/pages/ScenePage/components/ToolbarButton';
import { InfoModalProps } from './InfoModal.types';

const InfoModal = ({
    children,
    isActive,
    onClose,
    headerTitle,
    allowAutoClosing = true
}: InfoModalProps): React.JSX.Element => {

    const handleOnClose = (): void => {
        if (!allowAutoClosing) {
            return;
        }

        onClose();
    };

    return (
        <DownloadModal
            open={isActive}
            onClose={handleOnClose}>
            <Wrapper>
                <ModalHeader>
                    { headerTitle && <Typography>{headerTitle}</Typography> }
                    <ToolbarButton
                        clickEvent={onClose}>
                        <CloseIcon/>
                    </ToolbarButton>
                </ModalHeader>
                <ModalContent>
                    {children}
                </ModalContent>
            </Wrapper>
        </DownloadModal>
    );
};

export default InfoModal;

const DownloadModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    outline: 'none !important',
    boxShadow: `0px 4px 4px 0px ${theme.palette.secondary.grey}`
}));

const Wrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    outline: 'none !important',
    position: 'relative',
    flexDirection: 'column',

    height: '263px',
    width: '640px',

    borderRadius: '16px',
    background: theme.palette.neutrals.main,
    padding: '48px',
    gap: '20px'
}));

const ModalHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '34px',

    '& .MuiTypography-root': {
        fontFamily: 'Roboto500',
        fontSize: '20px',
        lineHeight: '22px',
    
        color: theme.palette.primary.contrastText
    }
}));

const ModalContent = styled(Box)(() => ({
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    gap: '10px'
}));
