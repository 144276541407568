import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const BackgroundReplaceIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}>
            <rect
                x="5"
                y="5"
                width="22"
                height="22"
                rx="3"
                stroke="currentColor"
                fill="none"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeDasharray="4 4"
            />
            <rect
                x="10.75"
                y="10.75"
                width="10.5"
                height="10.5"
                rx="2.25"
                stroke="currentColor"
                strokeWidth="1.5"
                fill={'none'}
            />
        </SvgIcon>
    );
};
