export enum SupportedAspectRatio {
    TwoByOne = 1,
    SixteenByNine,
    SevenByFour,
    ThreeByTwo,
    FourByThree,
    FiveByFour,
    OneByOne,
    FourByFive,
    ThreeByFour,
    TwoByThree,
    FourBySeven,
    NineBySixteen,
    OneByTwo
}

export type AspectRatioInfo = {
    [key in SupportedAspectRatio]: AspectRatioDetails;
};

type AspectRatioDetails = {
    aspectRatioToCss: string;
    aspectRatioLabel: string;
};

export const SupportedAspectRatios: AspectRatioInfo = {
    [SupportedAspectRatio.TwoByOne]: {
        aspectRatioToCss: '2/1',
        aspectRatioLabel: '2:1'
    },
    [SupportedAspectRatio.SixteenByNine]: {
        aspectRatioToCss: '16/9',
        aspectRatioLabel: '16:9'
    },
    [SupportedAspectRatio.SevenByFour]: {
        aspectRatioToCss: '7/4',
        aspectRatioLabel: '7:4'
    },
    [SupportedAspectRatio.ThreeByTwo]: {
        aspectRatioToCss: '3/2',
        aspectRatioLabel: '3:2'
    },
    [SupportedAspectRatio.FourByThree]: {
        aspectRatioToCss: '4/3',
        aspectRatioLabel: '4:3'
    },
    [SupportedAspectRatio.FiveByFour]: {
        aspectRatioToCss: '5/4',
        aspectRatioLabel: '5:4'
    },
    [SupportedAspectRatio.OneByOne]: {
        aspectRatioToCss: '1/1',
        aspectRatioLabel: '1:1'
    },
    [SupportedAspectRatio.FourByFive]:
    { aspectRatioToCss: '4/5',
    aspectRatioLabel: '4:5'
    },
    [SupportedAspectRatio.ThreeByFour]:
    { aspectRatioToCss: '3/4',
    aspectRatioLabel: '3:4'
    },
    [SupportedAspectRatio.TwoByThree]: {
        aspectRatioToCss: '2/3',
        aspectRatioLabel: '2:3'
    },
    [SupportedAspectRatio.FourBySeven]: {
        aspectRatioToCss: '4/7',
        aspectRatioLabel: '4:7'
    },
    [SupportedAspectRatio.NineBySixteen]: {
        aspectRatioToCss: '9/16',
        aspectRatioLabel: '9:16'
    },
    [SupportedAspectRatio.OneByTwo]: {
        aspectRatioToCss: '1/2',
        aspectRatioLabel: '1:2'
    },
};
