import React from 'react';
import { Divider, Stack, styled } from '@mui/material';
import { DownloadIcon } from '@root/assets/icons/DownloadIcon';
import { EraserIcon } from '@root/assets/icons/EraserIcon';
import { FullScreenOpenIcon } from '@root/assets/icons/FullScreenOpenIcon';
import { PanToolIcon } from '@root/assets/icons/PanToolIcon';
import { useScene } from '@root/context/ScenePageContext/useScene';
import ToolbarButton from '../ToolbarButton';
import { SceneMode } from '@root/utils/constants/enums';

const SceneToolbar = (): React.JSX.Element => {

    const {
        isToolbarDisabled,
        activeMode,
        setActiveMode,
        toggleFullScreen,
        setIsDownloadDialogActive
    } = useScene();

    const onModeButtonClick = (event: React.MouseEvent): void => {
        const buttonValue: number = +(event?.currentTarget as HTMLButtonElement).value || SceneMode.NONE;

        if (buttonValue in SceneMode && buttonValue !== activeMode) {
            setActiveMode(buttonValue);
        } else {
            setActiveMode(SceneMode.NONE);
        }
    };

    return (
        <Wrapper>
            <ToolbarButton
                value={SceneMode.PAN}
                isActive={activeMode === SceneMode.PAN}
                isDisabled={isToolbarDisabled}
                tooltipText={'Pan'}
                clickEvent={onModeButtonClick}>
                <PanToolIcon/>
            </ToolbarButton>

            <ToolbarButton
                value={SceneMode.ERASE}
                isActive={activeMode === SceneMode.ERASE}
                isDisabled={isToolbarDisabled}
                tooltipText={'Eraser'}
                clickEvent={onModeButtonClick}>
                <EraserIcon/>
            </ToolbarButton>

            <ToolbarButton
                isDisabled={isToolbarDisabled}
                tooltipText={'Full screen'}
                clickEvent={toggleFullScreen}>
                <FullScreenOpenIcon/>
            </ToolbarButton>

            <ToolsDivider/>

            <ToolbarButton
                isDisabled={isToolbarDisabled}
                tooltipText={'Download'}
                clickEvent={() => setIsDownloadDialogActive(true)}>
                <DownloadIcon/>
            </ToolbarButton>

            <ToolsDivider/>
        </Wrapper>
    );
};

export default SceneToolbar;

const Wrapper = styled(Stack)(({ theme }) => ({
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    minHeight: '70px',

    padding: '0px 24px',
    borderBottom: `1px solid ${theme.palette.primary.light1}`,
    gap: '18px',
    zIndex: 20
}));


const ToolsDivider = styled(Divider)(({ theme }) => ({
    background: theme.palette.primary.light1,
    width: '1px',
    height: '30px'
}));
