import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import GenerateContext from '@root/context/GenerateContext/GenerateContext';
import { useModel3dApi } from '@root/hooks/api/useModel3dApi';
import { Model3dErrorCode, SupportedAspectRatio, SupportedAspectRatios } from '@root/utils/constants/enums';
import Scene from '@root/lib/Scene';
import InfluenceSlider from '@root/components/InfluenceSlider';
import { CameraViewConstants } from '@root/utils/constants';
import { getFileExtension } from '@root/utils/helpers';
import CameraViewActions from './CameraViewActions';
import CameraViewFileUploader from './CameraViewFileUploader';
import { CreateModel3dPoseDto, Model3dDto, Model3dPoseDto } from '@root/types/dto';
import ContentLoader from '@root/components/ContentLoader';
import { SceneCameraPosition } from '@root/types/commonTypes';
import { CameraViewProps } from './CameraView.types';
import CameraViewErrorPanel from './CameraViewErrorPanel';
import { MAX_FILE_SIZE } from '@root/utils/constants/cameraViewConstants';

const CameraView = ({ weight, onWeightChange }: CameraViewProps): React.JSX.Element => {

    const container = useRef<HTMLDivElement>(null);
    const canvasElement = useRef(null);

    const {
        enableCameraView,
        cameraView,
        setCameraView,
        aspectRatio,
    } = useContext(GenerateContext);

    const {
        getAllModels3d,
        addModelPose,
        deleteModelPoseById,
        createModel3d,
        deleteModel3dById,
        getModel3dById
    } = useModel3dApi();

    const [cameraPos, setCameraPos] = useState<Model3dPoseDto[]>([]);
    const [currentPositionIndex, setCurrentPositionIndex] = useState<number>(0);
    const [currentModelIndex, setCurrentModelIndex] = useState<number>(0);
    const [isCustomInput, setCustomInput] = useState<boolean>(false);
    const [multiplier, setMultiplier] = useState<number>(0);
    const [label, setLabel] = useState<string>('');

    const [modelDeleteIsEnable, setModelDeleteIsEnable] = useState<boolean>(true);
    const [modelsList, setModelsList] = useState<Model3dDto[]>([]);
    const [activeModel, setActiveModel] = useState<Model3dDto | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [uploadedFileName, setUploadedFileName] = useState<string>('');
    const [errorType, setErrorType] = useState<Model3dErrorCode| null>(null);

    const moveToNextPosition = (): void => {
        let currentPosition = 0;

        if (currentPositionIndex === cameraPos.length - 1) {
            currentPosition = 0;
            setMultiplier(multiplier + 360);
        } else {
            currentPosition = currentPositionIndex + 1;
        }

        setCurrentPositionIndex(currentPosition);
    };

    const moveToPreviousPosition = (): void  => {
        let currentPosition = 0;

        if (currentPositionIndex === 0) {
            currentPosition = cameraPos.length - 1;
            setMultiplier(multiplier - 360);
        } else {
            currentPosition = currentPositionIndex - 1;
        }

        setCurrentPositionIndex(currentPosition);
    };

    const moveToNextModel = (): void => {
        let modelIndex = 0;
        setIsLoading(false);

        if (currentModelIndex === modelsList.length - 1) {
            modelIndex = -1;
        } else if (currentModelIndex === -1) {
            modelIndex = 0;
        } else {
            modelIndex = currentModelIndex + 1;
        }

        selectModelByIndex(modelIndex);
    };

    const moveToPreviousModel = (): void => {
        let modelIndex = 0;
        setIsLoading(false);

        if (currentModelIndex === 0) {
            modelIndex = -1;
        } else if (currentModelIndex === -1) {
            modelIndex = modelsList.length - 1;
        } else {
            modelIndex = currentModelIndex - 1;
        }

        selectModelByIndex(modelIndex);
    };

    const selectModelByIndex = (modelIndex: number): void => {
        setCurrentModelIndex(modelIndex);

        if (modelIndex > -1) {
            setActiveModel(modelsList[modelIndex]);
        } else {
            setActiveModel(null);
        }
    };

    const handleCanvasInput = (event: MouseEvent): void => {
        setCustomInput(true);
        cameraView.controls.handleMouseDown(event);
    };

    const onWheel = (event: WheelEvent): void => {
        preventDefaultEvent(event);
        cameraView.controls.zoomCamera(event.deltaY);
    };

    const preventDefaultEvent = (event: any): void => {
        event.preventDefault();
    };

    const resizeViewport = (): void => {
        if (!container.current) {
            return;
        }

        const values = SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioToCss.split('/');
        const width = (container.current.clientHeight * +values[0]) / +values[1];
        const height = container.current.clientHeight;

        cameraView.onWindowResize(width, height);
    };

    const generateTitle = (): string => {
        let i = 1;
        let newLabel = '';
        let found = true;

        do {
            newLabel = CameraViewConstants.CUSTOM_CAMERA_VIEW_TITLE_PREFIX + i.toString();
            found = !!cameraPos.find((pos) => pos.label === newLabel);
            i += 1;
        } while (found);

        return newLabel;
    };

    const savePosition = async (): Promise<void> => {
        if (!activeModel) {
            return;
        }

        const newRawCameraPosition = cameraView.getCameraPosition();
        const addPositionConfig = {
            label: generateTitle(),
            model3dDataId: activeModel.id,
            position: newRawCameraPosition.modelPosition,
            rotation: {
                x: newRawCameraPosition.xRotation,
                y: newRawCameraPosition.yRotation,
                z: 0
            },
            zoom: newRawCameraPosition.zoom
        } as CreateModel3dPoseDto;

        const newPose = await addModelPose(addPositionConfig);
        if ((newPose as Model3dPoseDto)?.id) {
            await getAllModels(false);

            setCameraPos((pos) => [...pos, newPose as Model3dPoseDto ]);
            setCurrentPositionIndex(cameraPos.length);
            setCustomInput(false);
        }
    };

    const deletePosition = async (): Promise<void> => {
        const positionId = cameraPos[currentPositionIndex].id;
        await deleteModelPoseById(positionId);

        setCameraPos((prev) => prev.filter((el) => el.id !== positionId));
        setCurrentPositionIndex(currentPositionIndex - 1);
    };

    const addNewModel = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const files = event.target.files;
        if (!files) {
            return;
        }
        const file = files[0];

        if (file?.size > MAX_FILE_SIZE) {
            setErrorType(Model3dErrorCode.FileSize);
            return;
        }
        setUploadedFileName(file?.name);
        await uploadModel(file);

    };

    const uploadModel = async (file: File): Promise<void> => {
        setIsLoading(true);
        const result = await createModel3d(file, file.name);
        const newModelInfo = result as Model3dDto;

        if (newModelInfo?.id) {

            const addPositionConfig = {
                label: CameraViewConstants.DEFAULT_CAMERA_VIEW_TITLE_PREFIX,
                model3dDataId: newModelInfo.id,
                position: CameraViewConstants.DEFAULT_MODEL_POSITION,
                rotation: CameraViewConstants.DEFAULT_MODEL_ROTATION,
                zoom: CameraViewConstants.DEFAULT_MODEL_ZOOM
            } as CreateModel3dPoseDto;

            await addModelPose(addPositionConfig);

            const addedModel = await getModel3dById(newModelInfo.id);

            if ((addedModel as Model3dDto)?.id) {
                setModelsList([...modelsList, addedModel as Model3dDto]);
                setActiveModel(addedModel as Model3dDto);
                setCurrentModelIndex(modelsList.length);

                await getAllModels(false);
            }
        }

    };

    const deleteModel = async (): Promise<void> => {
        if (!activeModel) {
            return;
        }

        await deleteModel3dById(activeModel.id);

        moveToNextModel();
        setModelsList((prev) => prev.filter((el) => el.id !== activeModel.id));
    };


    const sortByDateCreated = (array: any) => {
        return array.sort(
            (a:any, b: any) => Date.parse(a.dateCreated) - Date.parse(b.dateCreated),
        );
    };

    const showActiveModel = (positions?: Model3dPoseDto[], index?: number): void => {
        if (!activeModel) {
            return;
        }

        const createdDate = new Date(activeModel.dateCreated);
        const url = `${(window as any)._env_.REACT_APP_BASE_URL}${activeModel.path}?` + createdDate.valueOf();

        setIsLoading(true);
        const selectedPosition = positions && (index || index === 0)  ? positions[index] : cameraPos[currentPositionIndex];
        cameraView.addModel(
            url,
            activeModel.name === CameraViewConstants.DEFAULT_MODEL_NAME ? 'json' : getFileExtension(activeModel.name),
            selectedPosition?.rotation || CameraViewConstants.DEFAULT_MODEL_POSITION,
            selectedPosition?.position || CameraViewConstants.DEFAULT_MODEL_ROTATION,
            activeModel.name === CameraViewConstants.DEFAULT_MODEL_NAME,
            setIsLoading,
            setErrorType
        );
        setMultiplier(0);
    };

    const getAllModels = async (setActive: boolean): Promise<void> => {
        const allModels = await getAllModels3d();

        if ((allModels as Model3dDto[])?.length) {
            const sortedModels = sortByDateCreated(allModels);
            setModelsList(sortedModels);

            if (setActive) {
                setActiveModel((allModels as Model3dDto[]).find(
                    (model) => model.name === CameraViewConstants.DEFAULT_MODEL_NAME
                ) || null);
            }
        }
    };

    const isSameCameraPosition = (pos1: Model3dPoseDto, pos2: SceneCameraPosition): boolean => {
        return (
            pos1?.rotation?.x === pos2.xRotation &&
            pos1?.rotation?.y === pos2.yRotation &&
            pos1?.zoom === pos2.zoom
        );
    };

    const submitError = (): void => {
        if (errorType === Model3dErrorCode.Upload) {
            deleteModel();
            moveToNextModel();
        } else if (errorType === Model3dErrorCode.Render) {
            moveToNextModel();
        }
        setErrorType(null);
    };

    useEffect(() => {
        getAllModels(true);

        return () => {
            setCameraView('');
        };
    }, []);

    useEffect(() => {
        if (!activeModel) {
            setCameraPos([]);
            return;
        }

        setCustomInput(false);
        setCameraPos(activeModel.model3dPoses);
        setCurrentPositionIndex(0);
        setModelDeleteIsEnable(activeModel.name !== CameraViewConstants.DEFAULT_MODEL_NAME);

        if (cameraView) {
            showActiveModel(activeModel.model3dPoses, 0);
        }

    }, [activeModel]);


    // Setup scene.
    useEffect(() => {
        if (!enableCameraView || cameraView || !container?.current) return;
        const values = SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioToCss.split('/');
        //Set timeout because of animation time
        setTimeout(() => {
            if (!container?.current) {
                return;
            }
            const threeScene = new Scene(
                canvasElement.current,
                (container.current.clientHeight * +values[0]) / +values[1],
                container.current.clientHeight,
            );

            setCameraView(threeScene);
        }, 600);
    }, [enableCameraView]);

    useEffect(() => {
        if (!cameraView || !activeModel) {
            return;
        }

        showActiveModel();

        window.addEventListener('resize', resizeViewport);
        window.addEventListener('mousemove', (e) =>
            cameraView.handleMouseMove(e),
        );
        window.addEventListener('mouseup', () => cameraView.handleMouseUp());

        return () => {
            window.removeEventListener('resize', resizeViewport);
            window.removeEventListener('mousemove', (e) =>
                cameraView.handleMouseMove(e),
            );
            window.removeEventListener('mouseup', () =>
                cameraView.handleMouseUp(),
            );
        };
    }, [cameraView]);

    useEffect(() => {
        if (!container?.current || !cameraView) {
            return;
        }

        const instance = container.current;

        instance.addEventListener('wheel', onWheel);
        instance.addEventListener('mousedown', handleCanvasInput);
        instance.addEventListener('contextmenu', preventDefaultEvent);
        return () => {
            instance.removeEventListener('wheel', onWheel);
            instance.removeEventListener('mousedown', handleCanvasInput);
            instance.removeEventListener('contextmenu', preventDefaultEvent);

            cameraView?.clearScene();
        };
    }, [container, cameraView]);

    useEffect(() => {
        if (!cameraView || currentPositionIndex === null || !activeModel) {
            return;
        }

        setCustomInput(false);

        const isSame = isSameCameraPosition(
            cameraPos[currentPositionIndex],
            cameraView.getCameraPosition()
        );

        if (isSame) {
            return;
        }

        cameraView.startModelRotation(
            cameraPos[currentPositionIndex]?.rotation.x,
            cameraPos[currentPositionIndex]?.rotation.y + multiplier,
            cameraPos[currentPositionIndex]?.zoom,
            cameraPos[currentPositionIndex]?.position,
        );
    }, [currentPositionIndex]);

    useEffect(() => {
        if (currentPositionIndex !== null &&
            cameraPos.length > currentPositionIndex &&
            !isCustomInput) {

            setLabel(cameraPos[currentPositionIndex].label);
        } else {
            setLabel('');
        }
    }, [currentPositionIndex, cameraPos, isCustomInput]);

    useEffect(() => {
        if (!cameraView) {
            return;
        }

        resizeViewport();
    }, [aspectRatio]);
    

    return (
        <Stack>
            <CameraViewContent enabled={enableCameraView}>
                <CameraViewActions
                    moveToPreviousModel={moveToPreviousModel}
                    moveToNextModel={moveToNextModel}
                    moveToPreviousPosition={moveToPreviousPosition}
                    moveToNextPosition={moveToNextPosition}
                    deleteModel={deleteModel}
                    addModel={addNewModel}
                    activeModel={activeModel}
                    modelDeleteIsEnable={modelDeleteIsEnable}
                    positionDeleteIsEnable={label?.startsWith(CameraViewConstants.CUSTOM_CAMERA_VIEW_TITLE_PREFIX)}
                    positionLabel={label}
                    savePosition={savePosition}
                    deletePosition={deletePosition}
                    currentModelIndex={currentModelIndex}
                    isDisabled={isLoading || !!errorType}/>
                <CanvasWrapper active={activeModel && !isLoading && !errorType ? true : false}>
                    <CanvasContainer
                        ref={container}>
                        <PreviewBox ratio={SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioToCss} />
                        <canvas ref={canvasElement} id="three-canvas-1"></canvas>
                    </CanvasContainer>
                </CanvasWrapper>
                <LoaderWrapper active={activeModel && isLoading && !errorType ? true : false}>
                    <LoaderContainer>
                            <PreviewBox ratio={SupportedAspectRatios[aspectRatio as SupportedAspectRatio].aspectRatioToCss} />
                            <LoadingInfo>
                                <ContentLoader state={isLoading}/>
                                <InfoTypography>{'Rendering model'}</InfoTypography>
                            </LoadingInfo>
                    </LoaderContainer>
                </LoaderWrapper>
                { !activeModel && !errorType &&
                    <UploaderWrapper>
                        <CameraViewFileUploader
                            addModel={uploadModel}
                            currentFileName={uploadedFileName}
                            onError={setErrorType}
                            isLoading={isLoading}/>
                    </UploaderWrapper>
                }
                { errorType &&
                    <UploaderWrapper>
                            <CameraViewErrorPanel
                                submitAction={submitError}
                                errorType={errorType}
                            ></CameraViewErrorPanel>
                    </UploaderWrapper>
                }
            <InfluenceSlider
                    id={'camera view'}
                    value={weight} 
                    OnChange={onWeightChange} 
                    label={'influence'}
                    step={1}
                    range={[1, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>
            </CameraViewContent>
        </Stack>
    );
};

export default CameraView;


const CameraViewContent = styled(Box)<{ enabled: boolean }>(({ enabled }) => ({
    overflow: 'hidden',
    opacity: enabled ? 1 : 0,
    height: enabled ? 'auto' : '0px',
    transition: 'all 0.5s',
    width: '300px',
    gap: '20px'
}));

const PreviewBox = styled(Box)<{ratio: string}>(({ ratio, theme }) => ({
    height: '100%',
    aspectRatio: ratio,

    position: 'absolute',
    background: 'transparent',
    border: `0.5px dashed ${theme.palette.primary.inactive}`,
}));

const CanvasWrapper = styled(Box)<{ active: boolean }>(( { active }) => ({
    overflow: 'hidden',
    height: active ? '300px' : 0,
    opacity: active ? '1' : '0',

}));

const LoaderWrapper = styled(Box)<{ active: boolean }>(( { active }) => ({
    overflow: 'hidden',
    height:'300px',
    ...(!active && { display: 'none' })

}));


const UploaderWrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    height: '200px',
}));

const CanvasContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '30px',

    overflow: 'hidden',
    height: '250px',

    '& canvas': {
        position: 'relative',
    },

    transition: 'all 0.5s',
}));


const LoaderContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginTop: '30px',
    alignItems: 'center',

    overflow: 'hidden',
    height: '250px',

    transition: 'all 0.5s',
}));

const LoadingInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    gap: '14px'

}));

const InfoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '11px',
    lineHeight: '12px',
    textAlign: 'center',

    color:  theme.palette.primary.contrastText,
}));
