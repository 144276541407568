import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddNewCanvasIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
            {...props}>
            <path d="M6 11V11.4054V21.5C6 23.0752 6 23.8627 6.30654 24.4644C6.57619 24.9936 7.00644 25.4238 7.53565 25.6934C8.13728 26 8.92485 26 10.5 26H20.5946H21" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M14.8333 8H13.5C11.9248 8 11.1373 8 10.5357 8.30654C10.0064 8.57619 9.57619 9.00644 9.30654 9.53565C9 10.1373 9 10.9248 9 12.5V18.5C9 20.0752 9 20.8627 9.30654 21.4644C9.57619 21.9936 10.0064 22.4238 10.5357 22.6934C11.1373 23 11.9248 23 13.5 23H19.5C21.0752 23 21.8627 23 22.4644 22.6934C22.9936 22.4238 23.4238 21.9936 23.6934 21.4644C24 20.8627 24 20.0752 24 18.5V17.1667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M23 6V12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
            <path d="M20 9H26" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
        </SvgIcon>
    );
};
