import { Box, Typography, styled } from '@mui/material';

export const LogoSectionWrapper = styled(Box)(() => ({
    position: 'absolute',
    top: '10%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '7px 0px',
    gap: 0,
    '@media (max-height:1000px)': {
        top: '5%',
    },
    '@media (max-height:600px)': {
        top: '3%',
        left: '10%',
    },
}));

export const Logo = styled('img')(() => ({
    height: '53px',
    width: '101px',
    objectFit: 'contain',
}));

export const Version = styled(Typography)(() => ({
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: '100%',
    bottom: '5px',
}));
