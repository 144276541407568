import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { getRequestResult } from '@root/utils/helpers';

interface UseStatisticsApi {
    register: (data: object) => Promise<string | SupportedRequestError>
}

export const useStatisticsApi = (): UseStatisticsApi => {
    const { requestPostWithJson } = useRequest();

    const register = async (data: object): Promise<string | SupportedRequestError> => {
        const response = await requestPostWithJson('/statistics', {data});
        return getRequestResult(response, { propertyName: 'message' });
    };

    return {
        register
    };
};