import React from 'react';
import { Box, Switch, Typography, styled } from '@mui/material';
import { SwitchProps } from './SwitchField.types';

const SwitchField = ({ title, OnChange, checked, disabled }: SwitchProps): JSX.Element => { 

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
      OnChange(event.target.checked);
    };

  return (
    <Wrapper>
      <InputLabel variant="body16" align={'center'}>
        {title}
      </InputLabel>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={handleOnChange}
      />
    </Wrapper>
  );
};


const Wrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  minWidth: '150px',
  maxWidth: '300px',
  width: '100%',

  marginBottom: '20px'
}));

const InputLabel = styled(Typography)(({ theme }) => ({
  fontFamily: 'Roboto500',
  paddingRight: '5px',
  color: theme.palette.primary.light1,
}));


export default SwitchField;