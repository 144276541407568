import React from 'react';
import { Box, TextField, styled, Tooltip, Typography, Divider } from '@mui/material';
import { InputFieldProps } from './InputField.types';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import CopyValueButton from '../CopyValueButton';

const InputField = ({
    id,
    name,
    label,
    value,
    onChange,
    onBlur,
    isRequired ,
    isMultiline,
    isDisabled,
    tooltipLabel,
    placeholder,
    helperText,
    maxLength,
    category,
    error,
    withCopyOption,
    isReadOnly,
    onKeyDown
}: InputFieldProps): React.JSX.Element => {

    const inputComponents = {
        primary: PrimaryInput,
        secondary: SecondaryInput
    };

    const CustomInput = category ? inputComponents[category] : inputComponents.primary;

  return (
    <Wrapper>
        { label && <InputLabel variant='body16'>{label}</InputLabel> }

        <InputWrapper>
            <CustomInput
                id={id}
                name={name}
                required={isRequired}
                multiline={isMultiline}
                disabled={isDisabled}
                variant="outlined"
                value={value}
                autoComplete='off'
                helperText={helperText}
                defaultValue={placeholder}
                withCopyOption={withCopyOption}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                error={error}
                InputProps={{
                    readOnly: isReadOnly,
                    endAdornment: <>
                    {
                        tooltipLabel ? (
                            <InputTooltip title={tooltipLabel}><span><TooltipIcon></TooltipIcon></span></InputTooltip>
                        ) :
                        withCopyOption && (
                            <CopyValueSection>
                                <ContentDivider orientation="vertical"/>
                                <CopyValueButton value={value} isLink={false}/>
                            </CopyValueSection>
                        )
                    }
                    </>,
                    inputProps: { maxLength: maxLength }
                }}
            />
        </InputWrapper>
    </Wrapper>
  );
};

export default InputField;

const Wrapper = styled(Box)(() => ({
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
}));

const InputWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',

    width: '100%',
    minHeight: '40px',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    marginBottom: '6px',
    color: theme.palette.primary.light1,
}));

const PrimaryInput = styled(TextField)<{withCopyOption?: boolean}>(({ theme, withCopyOption }) => ({
    width: 'calc(100% - 42px)',

    '& .MuiOutlinedInput-root': {
        minHeight: '40px',
        fontSize: '14px',
        fontFamily: 'Roboto400',
        width: '300px',

        padding: '0',
        paddingLeft: '13px',
        paddingRight: '14px',
        paddingTop: '10px',
        paddingBottom: '10px',
    },

    '& .MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.main
    },

    '& input': {
        padding: '0',
        ...(withCopyOption && { marginRight: '18px' }),
        ...(withCopyOption && { width: 'calc(100% - 40px)' }),
    }
}));

const InputTooltip = styled(Tooltip)(() => ({
    width: '14px',
    height: '14px ',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    boxSizing: 'border-box',
}));

const TooltipIcon = styled(InformationIcon)(({ theme }) => ({
    width: '14px',
    height: '14px',
    color: theme.palette.primary.main
}));

const CopyValueSection = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '40px',
    margin: '-10px',
    paddingRight: '10px',

    '& svg': {
        height: '16px',
        width: '16px',
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));

const ContentDivider = styled(Divider)(({ theme }) => ({
    color:  theme.palette.primary.light1,
    height: '39px',
    width: '0',

    margin: '0',
    padding: '0',
}));

const SecondaryInput = styled(TextField)(({ theme }) => ({
    '& .MuiOutlinedInput-root': {
        fontSize: '16px',
        fontFamily: 'Roboto400',

        minHeight: '30px',
        width: '100%',
        padding: '5px 11px',

        color: theme.palette.neutrals.contrastText,
        borderColor: 'transparent',
        borderRadius: '6px',

        'fieldset': {
            borderColor: theme.palette.primary.light1,
        },

        '&:hover fieldset': {
            borderColor: theme.palette.primary.light1,
        },

        '& input:-webkit-autofill': {
            WebkitTextFillColor: theme.palette.neutrals.contrastText,
            WebkitBoxShadow: `0 0 0 30px ${theme.palette.neutrals.main} inset`,
            caretColor: theme.palette.neutrals.contrastText,
            borderRadius: '0px'
        }
    },

    '& input': {
        padding: '0',
    },
}));