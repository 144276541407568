import React, { useContext } from 'react';
import { Box, IconButton, Stack, Typography, styled } from '@mui/material';
import TunesContext from '@context/TunesContext/TunesContext';
import { DownloadIcon } from '@assets/icons/DownloadIcon';
import { DeleteImageIcon } from '@root/assets/icons/DeleteImageIcon';
import SelectCheckbox from '@root/components/SelectCheckbox';

interface ProjectActionsProps {
    className?: string;
}

export const ProjectActions = ({ className }: ProjectActionsProps) => {
    const {
        selectMode,
        selectAll,
        isAllSelected,
        deleteSelectedImages,
        downloadSelectedImages,
    } = useContext(TunesContext);

    return (
        <Wrapper className={className}>
            <Stack
                direction={'row'}
                gap={1.5}
                justifyContent="center"
                alignItems="center">
                <Checkbox
                    checked={isAllSelected()}
                    onChange={selectAll}
                    hidden={!selectMode}
                />
                {selectMode && (
                    <Typography variant="subtitle2" color={'primary'}>
                        Select all
                    </Typography>
                )}
            </Stack>
            <ActionButton hidden={!selectMode} onClick={downloadSelectedImages}>
                <DownloadIcon />
            </ActionButton>
            <ActionButton hidden={!selectMode} onClick={deleteSelectedImages}>
                <DeleteImageIcon />
            </ActionButton>
        </Wrapper>
    );
};

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '24px'
}));

const ActionButton = styled(IconButton)<{ hidden: boolean }>(({ theme, hidden }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.primary.main,
    opacity: hidden ? 0 : 1,
    transition: 'all 0.3s ease-out',

    pointerEvents: hidden ? 'none' : 'auto',
    '& svg': {
        width: '24px',
        height: '24px',
    },

    '&:hover': {
        background: 'transparent',
    },

    marginLeft: '32px',
    ':last-child': {
        marginLeft: '14px',
    },
}));

const Checkbox = styled(SelectCheckbox)(({ theme }) => ({
    '&.MuiCheckbox-root': {
        height: '20px',
        width: '20px',
        color: theme.palette.primary.main,
    },
    '& svg': {
        height: '20px',
        width: '20px'
    }
}));
