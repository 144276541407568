import React, { useContext } from 'react';
import { ModelsListProps } from './ModelsList.types';
import { Box, styled } from '@mui/material';
import { ModelDto } from '@root/types/dto';
import ModelSection from '../ModelSection';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import { ModelSectionPlaceholder } from '../ModelSection/ModelSection';
import { notificationMessages } from '@root/utils/constants/notificationMessages';

const ModelsList = ({ models }: ModelsListProps): React.JSX.Element => {
    const { importInProgress } = useContext(ModelsContext);
  return (
    <Wrapper>
    { !!models.length && (
        <CustomModelsList>
            {
                importInProgress &&
                <ModelSectionPlaceholder statusLabel={notificationMessages.model.importing.title}/>
            }
            {
                models.map((item: ModelDto) => (
                    <ModelSection
                        key={item.Id}
                        model={item}
                    />
                ))
            }
        </CustomModelsList>
    )}
    </Wrapper>
  );
};

export default ModelsList;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'hidden'
}));

const CustomModelsList = styled(Box)(() => ({
    gap: '50px',
    width: '100%',
    marginTop: '30px',

    display: 'grid',
    gridTemplateColumns: 'auto auto auto',

    overflowX: 'hidden',
    overflowY: 'auto',
}));
