import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, styled } from '@mui/material';

import ActiveModelContext from '../../../context/ActiveModelContext/ActiveModelContext';
import ModelsContext from '../../../context/ModelsContext/ModelsContext';
import { TrainModelForm } from './components/TrainModelForm';
import ImagesGrid from './components/ImagesGrid';
import { CommonConstants, ErrorMessages } from '@root/utils/constants';
import { ImageLabel, UploadedRetrainFile } from '@root/types/commonTypes';
import LoaderContext from '@root/context/LoaderContext/LoaderContext';
import { RequestErrorCode } from '@root/utils/constants/enums';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const ModelsTrainPage = (): React.JSX.Element => {
    const { createNewModel } = useContext(ModelsContext);
    const { checkLicenseStatus } = useLicenseValidation();
    const {
        getModelConfig,
        handleSetActiveModelImages,
        handleTrainModel,
        uploadImages,
        uploadImage,
        activeModelConfig,
        deleteImage,
        updateLabel,
        removedFiles,
        updatedLabels,
        uploadedFiles,
        setRemovedFiles,
        setUpdatedLabels,
        setUploadedFiles,
        updateModel,
        setIsDraft,
        isDefaultPage
    } = useContext(ActiveModelContext);
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { refreshModels } = useContext(ModelsContext);
    const [trainFormErrorMessage, setTrainFormErrorMessage] = useState<string>('');

    const { id } = useParams();
    const location = useLocation();

    const [isFormUpdateRequired, setIsFormUpdateRequired] = useState<boolean>(false);


    const getModelInfo = (): void => {
        getModelConfig(id);
        handleSetActiveModelImages(id);
    };

    const trainNewModel = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        await handleTrainModel();
    };

    const retrainExistingModel = async (): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        showLoader();

        if (isFormUpdateRequired) {
            const result = await updateModel();
            if (result?.errorCode) {
                setTrainFormErrorMessage(result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS ? 
                    ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS :
                    ErrorMessages.MODEL_ERROR_MESSAGE.UPDATE_ERROR);
            } else {
                await refreshModels();
            }
        }
        await Promise.all(removedFiles.map(async (id: number) => await deleteImage(id)));
        await Promise.all(updatedLabels.map(async (label: ImageLabel) => await updateLabel(label)));

        if (id && uploadedFiles.length) {
            await Promise.all(uploadedFiles.map(async (file: UploadedRetrainFile) => await uploadImageToDB(file.file, file.Prompt, id)));
        }
        await handleTrainModel();

        hideLoader();
    };

    const handleCreateNewModel = async (): Promise<string | null> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return null;
        }
       
        const newModel = {
            baseModelId: activeModelConfig.parentModelId,
            conceptInstanceToken: activeModelConfig.conceptInstanceToken,
            modelName: activeModelConfig.modelName ||
                `${CommonConstants.DEFAULT_NEW_MODEL_NAME} ${new Date().toUTCString()}`,
        };
        const result = await createNewModel(newModel);

        if (result?.errorCode) {
            setTrainFormErrorMessage(result?.errorCode === RequestErrorCode.MODEL_ALREADY_EXISTS ? 
                ErrorMessages.MODEL_ERROR_MESSAGE.ALREADY_EXISTS :
                ErrorMessages.MODEL_ERROR_MESSAGE.CREATE_ERROR);
                return null;
        } else {
            return result;
        }
    };
    
    const uploadImagesToDB = async (fileList: File[], modelId: string): Promise<void> => {
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        const formData = new FormData();

        for (let i = 0; i < fileList.length; i++) {
            formData.append('images', fileList[i]);
        }

        formData.append('json', `{ "ModelId": ${modelId} }`);
        await uploadImages(formData);
    };

    const uploadImageToDB = async (file: File, label: string, modelId: string): Promise<void> => {
        const formData = new FormData();

        formData.append('image', file);
        formData.append('json', `{ "ModelId": ${modelId}, "label": "${label}" }`);
        await uploadImage(formData);
    };

    useEffect(() => {
        setTrainFormErrorMessage('');
        getModelInfo();
        setRemovedFiles([]);
        setUpdatedLabels([]);
        setUploadedFiles([]);
        setIsDraft(false);
    }, [location]);

    return (
        <Container>
            <TrainModelMenu isDefault={isDefaultPage}>
                <TrainModelForm
                    setErrorMessage={setTrainFormErrorMessage}
                    errorMessage={trainFormErrorMessage}
                    onSubmit={trainNewModel}
                    onRetrain={retrainExistingModel}
                    setIsChanged={setIsFormUpdateRequired}
                />
            </TrainModelMenu>
            <TrainModelContent isDefault={isDefaultPage}>
                <ImagesGrid
                    uploadImagesToDB={uploadImagesToDB}
                    onCreate={handleCreateNewModel}
                    isFormUnsaved={isFormUpdateRequired}
                />
            </TrainModelContent>
        </Container>
    );
};

export default ModelsTrainPage;

const Container = styled(Box)(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',

    gap: '44px',
    paddingRight: '20px',
}));

const TrainModelMenu = styled(Box)<{isDefault: boolean}>(({ isDefault }) => ({
    flexGrow: 0,
    height: 'calc(100% - 80px)',

    ...(isDefault && { position: 'absolute' }),
    ...(isDefault && { left: '0' }),
}));

const TrainModelContent = styled(Box)<{isDefault: boolean}>(({ isDefault }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    height: '100%',

    paddingTop: '30px',
    ...(!isDefault && { paddingRight: '40px' }),
    gap: '30px',
}));