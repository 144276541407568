import React from 'react';
import { styled, IconButton } from '@mui/material';
import { ForwardButtonProps } from './ForwardButton.types';
import { ArrowForwardIosRounded } from '@mui/icons-material';

const ForwardButton = ({ moveToNext, isDisabled = false }: ForwardButtonProps): React.JSX.Element => {
    return (
        <ImageControlButtonRight disabled={isDisabled} onClick={moveToNext}>
            <ArrowForwardIosRounded />
        </ImageControlButtonRight>
    );
};

export default ForwardButton;

const ImageControlButton = styled(IconButton)(() => ({
    position: 'absolute',
    top: '50%',

    '& svg': {
        width: '2rem',
        height: '2rem'
    }
}));

const ImageControlButtonRight = styled(ImageControlButton)(() => ({
    right: '-60px'
}));