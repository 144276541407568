import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, MenuItem, styled } from '@mui/material';
import { BrushGenerationSettings } from './BrushTool';
import { useCurate } from '@hooks/curate/useCurate';
import { useMixImage } from '@root/context/MixImageContext/useMixImage';
import { FormWrapper } from '@root/components/FormWrapper';
import { useStyleDrive } from '@root/context/StyleDriveContext/useStyleDrive';
import ModelsContext from '@root/context/ModelsContext/ModelsContext';
import ReferenceImageField from '@root/components/ReferenceImageField';
import PromptSection from '@root/components/PromptSection';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import SelectField from '@root/components/SelectField';
import PreciseModeField from '@root/components/PreciseModeField';
import InfluenceSlider from '@root/components/InfluenceSlider';
import { CurateTools, HotKeysList, SupportedHotKeys } from '@root/utils/constants/enums';
import useKeyPress from '@root/hooks/helpers/useKeyPress';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';
import { useSceneShift } from '@root/context/SceneShiftContext/useSceneShift';

const CuratePageForm = () => {
    const {
        rerenderImage,
        generateButtonState,
        rerenderFromSketch,
        activeTool,
        generateSceneShift,
        isSketchSelected,
        referenceImage,
        setReferenceImage,
        referenceImageInfluence,
        setReferenceImageInfluence,
        sketchInfluence,
        setSketchInfluence,
        convertCanvasImageToSketch,
        modelForGeneration,
        onModelForGenerationInputChange,
        prompt,
        onPromptInputChange,
        seed,
        onSeedInputChange,
        onInputSeed,
        negativePrompt,
        onNegativePromptInputChange,
        currentLayerInfo,
        enablePreciseMode,
        setEnablePreciseMode,
        denoiseWeight,
        setDenoiseWeight,
        formMenuIsActive,
        setFormMenuIsActive,
        generateStyleDrive,
        generateMixImage,
        isFullRerender,
        setIsFullRerender,
        isPartialRerenderEnabled
    } = useCurate();

    const { checkLicenseStatus } = useLicenseValidation();

    const generateHotKey = useKeyPress(
        SupportedHotKeys[HotKeysList.CTRL_G].key,
        SupportedHotKeys[HotKeysList.CTRL_G].withCtrl,
        SupportedHotKeys[HotKeysList.CTRL_G].blockDefaultEvent
    );

    const { trainedModelsList } = useContext(ModelsContext);
    const { readyToMix } = useMixImage();
    const { isReadyForGeneration } = useStyleDrive();
    const {
        isSceneShiftGenerationEnabled,
        sceneShiftPrompt,
        onSceneShiftPromptChange
    } = useSceneShift();

    const [size, setSize] = useState(true);

    const toolsWithPromptMain = activeTool in CurateTools &&
        activeTool !== CurateTools.Upscale &&
        activeTool !== CurateTools.Eraser;

    const buttonAction = () => {
        setFormMenuIsActive(!formMenuIsActive);
    };

    useEffect(() => {
        if (generateHotKey && 
            (generateButtonState ||
                (activeTool === CurateTools.MixImages && readyToMix) ||
                (activeTool === CurateTools.StyleDrive && isReadyForGeneration))) {

            onSubmit();
        }
    }, [generateHotKey]);

    const onSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }
        const isValid = await checkLicenseStatus();

        if (!isValid) {
            return;
        }

        activeTool === CurateTools.MixImages && generateMixImage();
        activeTool === CurateTools.Lasso && rerenderImage();
        activeTool === CurateTools.Brush && rerenderFromSketch();
        activeTool === CurateTools.SceneShift && generateSceneShift();
        activeTool === CurateTools.StyleDrive && generateStyleDrive();
    };


    useEffect(() => {
        setSize(toolsWithPromptMain);
        setFormMenuIsActive(toolsWithPromptMain);
    }, [activeTool]);

    return (
        <Wrapper size={size ? 1 : 0}>
            <FormWrapper
                large
                onSubmit={onSubmit}
                component="form"
                active={formMenuIsActive ? 1 : 0}>
                <Content>

                    {toolsWithPromptMain && <>
                        {
                            activeTool === CurateTools.SceneShift
                            ? 
                                <PromptSection
                                    prompt={sceneShiftPrompt}
                                    onPromptInputChange={onSceneShiftPromptChange}
                                ></PromptSection>
                            :
                                <>
                                    <PromptSection
                                        prompt={prompt}
                                        onPromptInputChange={onPromptInputChange}
                                        negativePrompt={negativePrompt}
                                        onNegativePromptInputChange={onNegativePromptInputChange}
                                        seed={seed}
                                        onSeedInputChange={onSeedInputChange}
                                        onInputSeed={onInputSeed}
                                        isNegativePromptRequired={false}
                                        isPromptRequired={false}
                                    ></PromptSection>
                                    <SelectField
                                        id="modelForGeneration"
                                        name="modelForGeneration"
                                        value={modelForGeneration}
                                        onChange={onModelForGenerationInputChange}
                                        label={'model for generation'}
                                        isRequired={true}
                                        isDisabled={false}
                                        content={
                                            trainedModelsList.map((model) => {
                                                return (
                                                    <MenuItem key={model.Id} value={model.Id}>
                                                        {model.Name.replaceAll('.safetensors', '')}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    />
                                </>
                        }
                        </>
                    }

                    {(activeTool === CurateTools.Lasso || activeTool === CurateTools.StyleDrive) && 
                    <SectionWrapper>
                        { activeTool !== CurateTools.StyleDrive &&
                            <ReferenceImageField
                                setInfluenceImage={setReferenceImage}
                                influenceImage={referenceImage}
                                influence={referenceImageInfluence}
                                setInfluence={setReferenceImageInfluence}
                            ></ReferenceImageField>
                        }
                        <PreciseModeField
                            enablePreciseMode={enablePreciseMode}
                            setEnablePreciseMode={setEnablePreciseMode}
                        ></PreciseModeField>

                        { activeTool === CurateTools.Lasso &&
                            <InfluenceSlider
                                id={'denoiseweight'}
                                value={denoiseWeight} 
                                OnChange={setDenoiseWeight}
                                label={'processing intensity'}
                                step={1}
                                range={[0, 100]}
                                endAdornment={'%'}
                                isReversed={false}
                            ></InfluenceSlider>
                        }
                    </SectionWrapper>
                    }

                    {activeTool === CurateTools.Brush && 
                        <BrushGenerationSettings
                            influenceImage={referenceImage}
                            setInfluenceImage={setReferenceImage}
                            influence={referenceImageInfluence}
                            setInfluence={setReferenceImageInfluence}
                            sketchInfluence={sketchInfluence}
                            setSketchInfluence={setSketchInfluence}
                            onConvertToSketchChecked={convertCanvasImageToSketch}
                            isConvertToSketchChecked={isSketchSelected}
                            enablePreciseMode={enablePreciseMode}
                            setEnablePreciseMode={setEnablePreciseMode}
                            isFullRerender={isFullRerender}
                            setIsFullRerender={setIsFullRerender}
                            isPartialRerenderEnabled={isPartialRerenderEnabled}
                            isSketch={currentLayerInfo?.toolId === CurateTools.Sketch}
                        ></BrushGenerationSettings>
                        
                    }
                </Content>
                <Footer>
                    {(activeTool === CurateTools.Lasso || activeTool === CurateTools.Brush ||
                        isSketchSelected) && (
                        <GenerateButton
                            disabled={!generateButtonState}
                            type="submit"
                            variant="contained">
                            GENERATE
                        </GenerateButton>
                    )}
                    {activeTool === CurateTools.MixImages && (
                        <GenerateButton
                            variant="contained"
                            type="submit"
                            disabled={!readyToMix}>
                            Mix Images
                        </GenerateButton>
                    )}
                    {(activeTool === CurateTools.StyleDrive || activeTool === CurateTools.SceneShift) && (
                        <GenerateButton
                            variant="contained"
                            type="submit"
                            disabled={!isReadyForGeneration && !isSceneShiftGenerationEnabled}>
                            GENERATE
                        </GenerateButton>
                    )}
                </Footer>
            </FormWrapper>
            <ExpandMenuButton 
                isActive={formMenuIsActive}
                buttonAction={buttonAction}/>
        </Wrapper>
    );
};

export default CuratePageForm;

const Wrapper = styled(Box)(({size }) => ({
    display: 'flex',
    flex: 1,
    maxWidth: '413px',
    opacity: !size ? 0 : 1,
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    paddingBottom: '10px',

    overflowY: 'auto',
    overflowX: 'hidden',
    gap: '32px',
}));

const SectionWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
}));

const GenerateButton = styled(Button)(() => ({
    width: '300px',
    transition: 'all 0.5s'
}));

const Footer = styled(Box)(() => ({
    display: 'flex',
    height: '94px',
    padding: '25px 0px',
}));
