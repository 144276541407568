import React from 'react';
import { Box, styled } from '@mui/material';
import { ContentLoaderProps } from './ContentLoader.types';

const ContentLoader = ({ state }: ContentLoaderProps): React.JSX.Element => {
  return (
    <LoaderScreen state={state}>
        <CustomLoader/>
    </LoaderScreen>
  );
};

export default ContentLoader;

const LoaderScreen = styled(Box)<{ state: boolean }>(({ state }) => ({
    width: '24px',
    height: '24px',
    display: state ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '70%',
    zIndex: '100',
}));

const CustomLoader = styled(Box)(({ theme }) => ({
    display: 'inline',
    width: '18px',
    height: '18px',
    border: `2px solid ${theme.palette.primary.light1}`,
    borderRadius: '50%',
    borderTop: `2px solid ${theme.palette.primary.contrastText}`,
    animation: 'content_spin 1s linear infinite',

    '@keyframes content_spin': {
        '0%': {
        transform: 'rotate(0deg)'
        },
        '100%' : {
        transform: 'rotate(360deg)'
        }
    }
}));
