import React, { useState } from 'react';
import { Box, Stack, Tooltip, Typography, styled } from '@mui/material';
import { CssSlider } from '@root/assets/theme/ThemeComponents';
import { InfluenceSliderProps } from './InfluenceSlider.types';
import { InformationIcon } from '@root/assets/icons/InformationIcon';

const InfluenceSlider = ({ 
    OnChange, 
    value,
    id,
    label,
    step,
    range,
    marks = [],
    enableLabelDisplay = false,
    isReversed = false,
    endAdornment,
    tooltipLabel
}: InfluenceSliderProps): JSX.Element => {

    const [reversedValue, setReversedValue] = useState(0);

    const handleOnChange = (_: Event, newValue: number | number[]): void => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue;
        if(!isReversed) {
            OnChange(value);
        } else {
            OnChange(range[1] - value);
            setReversedValue(value);
        }
    };

  return (
    <Box>
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <InputLabel variant="body16">{label} : </InputLabel>
            <Typography variant="body16">{value}{endAdornment}</Typography>
            { tooltipLabel &&
                <SliderTooltip title={tooltipLabel} placement="right"><span><InformationIcon></InformationIcon></span></SliderTooltip>
            }
        </Stack>
        <Wrapper small={!tooltipLabel}>
            <CssSlider
                id={id}
                name={id}
                value={!isReversed ? value : reversedValue} 
                step={step}
                min={range[0]}
                max={range[1]}
                valueLabelDisplay={enableLabelDisplay ? 'auto' : 'off'}
                marks={marks?.length ? marks : false}
                onChange={handleOnChange}
            />
        </Wrapper>
    </Box>
  );
};

export default InfluenceSlider;

const Wrapper = styled(Box)<{small: boolean}>(({ small }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: small ? '150px' : '200px',
    maxWidth: '300px',
    justifyContent: 'center',
    width: '100%',
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    paddingRight: '5px',
    color: theme.palette.primary.light1,
}));

const SliderTooltip = styled(Tooltip)(({ theme }) => ({
    width: '14px',
    height: '18px ',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    boxSizing: 'border-box',
    marginLeft: 'auto',

    '& svg': {
        width: '14px',
        height: '14px',
        color: theme.palette.primary.main
    }
}));
