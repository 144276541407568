import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, styled } from '@mui/material';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';
import TuneBlockImage from './TuneBlockImage';
import FullSizeImage from '../fullSizeImage/FullSizeImage';
import TunesContext from '../../../../../context/TunesContext/TunesContext';
import SelectCheckbox from '@root/components/SelectCheckbox';
import { ImageStatus } from '@root/utils/constants/enums';
import ProjectsContext from '@root/context/ProjectsContext/ProjectsContext';
import { useTuneImagesNavigation } from '@root/hooks/helpers/useTuneImagesNavigation';
import { prefixBaseUrl } from '@root/utils/url/url';
import FullScreenSection from '../FullScreenSection/FullScreenSection';
import { ErrorMessages } from '@root/utils/constants';


const CHECK_IMAGES_STATUS_INTERVAL_TIME = 5000;
const ERROR_MESSAGE_DISPLAY_TIME = 5000;

const TuneBlock = ({ tuneStatus, id, setPrompt }) => {
    const {
        getTune,
        getTuneStatus,
        likeImage,
        activeTuneId,
        setActiveTuneId,
        deleteImage,
        selectMode,
        selectTune,
        deleteTune,
        isTuneSelected,
        setIsFavoriteSectionActive,
        setIsFullScreenMode,
        tunes,
        setActiveImageSrc,
        isFullScreenMode,
        activeImageSrc,
        tuneErrorMessage,
        setTuneErrorMessage
    } = useContext(TunesContext);


    const {
        setStartImage,
        moveToNextImage,
        moveToPreviousImage,
        setStartImageIndex,
        startImageIndex,
        setCurrentImage,
        currentImage,
        currentTuneControlImages,
        currentTuneConfig,
        currentImageIndex,
        currentTuneIndex,
        setCurrentTune,
        isNextDisabled,
        isPrevDisabled,
        currentTune
    } = useTuneImagesNavigation();

    const { setProjectImagesNumber } = useContext(ProjectsContext);

    const [images, setImages] = useState(null);
    const [status, setStatus] = useState('');
    const [tuneConfig, setTuneConfig] = useState('');
    const [activeIndex, setActiveIndex] = useState(null);
    const [display, setDisplay] = useState(true);

    const getImages = useCallback(() => {
        getTune(id).then((tune) => {
            setTuneConfig(tune?.config);
            setImages(tune?.images);
        });
    }, [getTune]);

    const setTuneError = () => {
        if (tuneErrorMessage) {
            return;
        }

        setTuneErrorMessage(ErrorMessages.TUNE_ERROR_MESSAGE.CREATE_ERROR);

        setTimeout(() => {
            setTuneErrorMessage('');
        }, ERROR_MESSAGE_DISPLAY_TIME);
    };

    const checkTuneStatus = () => {
        if (status === ImageStatus.Error) {
            setDisplay(false);
            setTuneError();
        }

        return status === ImageStatus.Ready || status === ImageStatus.Error;
    };

    const handleCloseFullSize = () => {
        setStartImageIndex(0);
        setActiveImageSrc('');

        setActiveIndex(null);
        setStartImage(null);
        setCurrentImage(null);
    };

    const handleOpenFullSize = (index) => {
        setActiveIndex(index);
        setStartImage(images[index]);
        setStartImageIndex(index);
    };


    const handleLikeImage = async (id, searchArray, setData) => {
        const image = searchArray.find((image) => image.Id === id);
        const likedImage = await likeImage(image);

        setData((prev) => {
            const newArray = prev.map((el) => {
                if (el.Id === id) {
                    el.Liked = likedImage;
                }
                return el;
            });
            return newArray;
        });
    };

    const likeActiveImage = async (id) => {
        handleLikeImage(id, currentTune, setCurrentTune);
    };

    const LikeTuneImage = async (id) => {
        handleLikeImage(id, images, setImages);
    };

    const toggleFullSizeMode = () => {
        setStartImage(currentImage ? currentImage : images[activeIndex]);
        setStartImageIndex(startImageIndex ? startImageIndex : activeIndex);
        setIsFullScreenMode(true);
        setIsFavoriteSectionActive(false);
    };

    const handleSetPrompt = () => {
        setPrompt(tuneConfig.prompt);
    };
    
    const handleDeleteActiveImage = async (id) => {
        const success = await deleteImage(id);

        if (success?.projectImagesNumber && tunes[currentTuneIndex].images.length) {
            setProjectImagesNumber(success.projectImagesNumber);
                if (currentImageIndex === tunes[currentTuneIndex].images.length - 1) {
                    moveToPreviousImage();
                } else {
                    moveToNextImage();
                }
        }
    };

    const handleSelectChange = (selected) => {
        selectTune(id, selected);
    };

    useEffect(() => {
        setStatus(tuneStatus);
    }, []);

    useEffect(() => {
        if (!display) {
            deleteTune(id, false);
        }
    }, [display]);

    useEffect(() => {
        if (status === ImageStatus.Error) {
            setImages([]);
            return;
        }
        checkTuneStatus() && getImages();
    }, [status, getImages]);

    useEffect(() => {

        const interval = setInterval(() => {
            if (checkTuneStatus()) {
                return;
            }
            getTuneStatus(id).then((res) => {
                res && !res.errorCode ? setStatus(res) : setStatus(ImageStatus.Error);
            },
            );
        }, CHECK_IMAGES_STATUS_INTERVAL_TIME);

        if (checkTuneStatus()) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [images]);

    useEffect(() => {
        if (!activeTuneId) {
            return;
        }

        activeTuneId === id && getImages();
        setActiveTuneId('');
    }, [activeTuneId]);

    return (
        display ? 
        <Wrapper>
            <SideActions>
                <TuneAction onClick={handleSetPrompt}>
                    <Tooltip title="Apply prompt from this tune">
                        <ArrowCircleLeftOutlined />
                    </Tooltip>
                </TuneAction>

                <TuneCheckbox
                    className={'tune-checkbox'}
                    hidden={!selectMode}
                    checked={isTuneSelected(id)}
                    onChange={handleSelectChange}
                />
            </SideActions>
            <InnerWrapper>
                    <StyledStack>
                        {checkTuneStatus() && images && images.length ? (
                            images.map((item, i) => {
                                return (
                                    <TuneBlockImage
                                        key={i}
                                        item={item}
                                        setActiveIndex={() => handleOpenFullSize(i)}
                                        likeImage={LikeTuneImage}
                                    />
                                );
                            })
                        ) : (
                            <TuneLoader>
                                <span className="loader"></span>
                            </TuneLoader>
                        )}
                    </StyledStack>
            </InnerWrapper>
            {!!images && currentImage && (
                <FullSizeImage
                    onClose={handleCloseFullSize}
                    configuration={currentTuneConfig[currentImageIndex]}
                    activeImage={currentImage}
                    toggleFullSizeMode={toggleFullSizeMode}
                    onPrevious={!isPrevDisabled ? moveToPreviousImage : null}
                    onNext={!isNextDisabled ? moveToNextImage : null}
                    likeImage={likeActiveImage}
                    onDelete={handleDeleteActiveImage}
                    controlDepthImage={currentTuneControlImages.depth}
                    controlSketchImage={currentTuneControlImages.sketch}
                />
            )}
            { currentImage &&
                <FullScreenSection
                    isFullScreenActive={isFullScreenMode}
                    setIsFullScreenActive={setIsFullScreenMode}
                    moveToNextImage={moveToNextImage}
                    moveToPreviousImage={moveToPreviousImage}
                    activeImageUrl ={activeImageSrc ? activeImageSrc : prefixBaseUrl(currentImage.Path)}
                ></FullScreenSection>
            }
        </Wrapper> 
        :
        <></>
    );
};

export default React.memo(TuneBlock);

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    padding: '35px 0px',
    width: '100%',

    '&:hover': {
        '.tune-actions': {
            opacity: '1',
        },

        '.MuiCheckbox-root': {
            opacity: '1 !important',
        }
    }
}));

const StyledStack = styled(Stack)(() => ({
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '100%',
    gap: '15px'
}));

const InnerWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
}));

const SideActions = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
}));

const TuneCheckbox = styled(SelectCheckbox)(() => ({
    padding: '0'
}));

const TuneLoader = styled(Box)(() => ({
    width: '100%',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export const TuneAction = styled(IconButton)(({theme}) => ({
  width: '24px',
  height: '24px',
  
  color: theme.palette.primary.main
}));