import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const CloseIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            {...props}>
            <path d="M7 17L17 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M17 17L7 7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
        </SvgIcon>
    );
};
