import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, TextField, Typography } from '@mui/material';
import { Logo, LogoSectionWrapper } from './Components';

import logoImage from '@assets/images/logoImage.svg';

import AuthContext from '../../context/AuthContext/AuthContext';
import { NavigationPaths } from '@root/utils/constants/enums';

const AuthPage = () => {
    const navigate = useNavigate();

    const { handleLogin, isSignedIn, isRequestError, setRequestError } =
        useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();

        setUsernameError(false);
        setPasswordError(false);

        setUsernameError(!username);
        setPasswordError(!password);

        if (username && password) {
            handleLogin(username, password);
        }
    };

    const onUsernameInputChange = (event) => {
        setUsername(event.target.value);
        setUsernameError(false);
    };

    const onPasswordInputChange = (event) => {
        setPassword(event.target.value);
        setPasswordError(false);
    };

    useEffect(() => {
        setRequestError(false);
    }, []);

    useEffect(() => {
        isSignedIn && navigate(`/${NavigationPaths.CreatePage}`);
    }, [isSignedIn]);

    useEffect(() => {
        setUsernameError(isRequestError);
        setPasswordError(isRequestError);
    }, [isRequestError]);

    return (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '25px',
            }}>
            <LogoSectionWrapper>
                 <Logo src={logoImage} />
                {/* {window._env_.REACT_APP_BUILD_VERSION && (
                    <Version variant="body12">
                        {`v. ${window._env_.REACT_APP_BUILD_VERSION}`}
                    </Version>
                )} */}
            </LogoSectionWrapper>
            <Typography
                sx={{
                    fontSize: '24px',
                }}
                component="h3">
                Sign in
            </Typography>
            <form id="signInForm" onSubmit={handleSubmit}>
                <Box
                    sx={{
                        width: '398px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '25px',
                    }}>
                    <TextField
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        required
                        placeholder="User Name"
                        label="User Name"
                        autoComplete="username"
                        value={username}
                        onChange={onUsernameInputChange}
                        error={usernameError}
                    />
                    <TextField
                        sx={{
                            width: '100%',
                        }}
                        variant="outlined"
                        required
                        type="password"
                        placeholder="Password"
                        label="Password"
                        value={password}
                        onChange={onPasswordInputChange}
                        autoComplete="current-password"
                        error={passwordError}
                        helperText={
                            (usernameError || passwordError) &&
                            'Incorrect user name or password'
                        }
                    />
                    <Button
                        type="submit"
                        sx={{
                            width: '100%',
                            mt: 4,
                        }}
                        variant="contained">
                        sign in
                    </Button>
                </Box>
            </form>

            <Box
                sx={{
                    width: '398px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#87B4EA',
                    fontSize: '14px',
                    mt: 2,
                }}>
            </Box>
        </Box>
    );
};

export default AuthPage;
