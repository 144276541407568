import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 21" 
            {...props}>
            <path d="M3.5 18.375H17.5M4.95775 11.5387C4.5845 11.9125 4.3749 12.4192 4.375 12.9474V15.7501H7.19512C7.72362 15.7501 8.23025 15.5401 8.60388 15.1656L16.9164 6.84868C17.2898 6.47492 17.4995 5.96823 17.4995 5.43993C17.4995 4.91162 17.2898 4.40493 16.9164 4.03118L16.0956 3.20868C15.9105 3.02348 15.6907 2.87659 15.4488 2.7764C15.2069 2.6762 14.9477 2.62467 14.6858 2.62476C14.424 2.62484 14.1647 2.67653 13.9229 2.77687C13.681 2.87721 13.4614 3.02424 13.2764 3.20955L4.95775 11.5387Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill='none'/>
        </SvgIcon>
    );
};