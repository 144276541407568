import {useRequest} from '@hooks/request/UseRequest';
import { SupportedRequestError } from '@root/types/commonTypes';
import { getRequestResult } from '@root/utils/helpers';
import {Image} from '@utils/image/image';

interface UseImageApi {
    getImage: (imageId: number | string) => Promise<Image | SupportedRequestError>;
    likeImage: (imageId: number | string) => Promise<boolean  | SupportedRequestError>;
    deleteImage: (imageId: number | string) => Promise<{message: string; projectImagesNumber: number} | SupportedRequestError>;
}

export const useImageApi = (): UseImageApi => {
    const { requestGet, requestDelete, requestPut } = useRequest();

    const getImage = async (imageId: string | number): Promise<Image | SupportedRequestError> => {
        const response = await requestGet(`/images/${imageId}`);
        return getRequestResult(response, { propertyName:'image' });
    };

    const likeImage = async (id: string | number): Promise<boolean  | SupportedRequestError> => {
        const response = await requestPut(`/images/${id}`);
        return getRequestResult(response, { propertyName:'Image will expire and be deleted on' });
    };

    const deleteImage = async (id: string | number): Promise<{message: string; projectImagesNumber: number} | SupportedRequestError> => {
        const response = await requestDelete(`/images/${id}`);
        return response?.data;
    };

    return {
        getImage,
        likeImage,
        deleteImage
    };
};