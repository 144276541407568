import React from 'react';
import { FormControlLabel, styled, Checkbox, Typography } from '@mui/material';
import { FormCheckboxProps } from './FormCheckbox.types';
import { CheckboxCheckedIcon } from '@root/assets/icons/CheckboxCheckedIcon';
import { CheckboxUncheckedIcon } from '@root/assets/icons/CheckboxUncheckedIcon';

const FormCheckbox = ({
    id,
    label,
    checked,
    disabled = false,
    onChange
}: FormCheckboxProps): JSX.Element => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        onChange(event.target.checked);
    };
    return (
        <StyledFormControlLabel
        disabled={disabled}
        id={id}
        name={id}
        control={
            <Checkbox
                disableRipple
                checked={checked}
                onChange={handleChange}
                checkedIcon={<CheckboxCheckedIcon/>}
                icon={<CheckboxUncheckedIcon/>}
            />
        }
        label={<InputLabel variant="body16">{label}</InputLabel>}
    />
    );
};

const StyledFormControlLabel = styled(FormControlLabel)<{ disabled: boolean }>
(({ theme, disabled }) => ({
    height: '24px',

    '&:hover': {
        '.MuiCheckbox-root': {
            backgroundColor: 'transparent',
            color: disabled
                ? theme.palette.primary.light1
                : theme.palette.secondary.main
        }
    },

    '& .MuiCheckbox-root': {
        color: theme.palette.primary.light1,
        backgroundColor: 'transparent',
        padding: '0px',
        margin: '9px',
        height: '24px',
        width: '24px',
    },

    '&.MuiCheckbox-root, .Mui-checked': {
        color: disabled ?
            `${theme.palette.primary.inactive}  !important` :
            `${theme.palette.secondary.main} !important`
    },

    '& svg': {
        height: '18px',
        width: '18px'
    }
}));

const InputLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    color: theme.palette.primary.light1,
}));

export default FormCheckbox;
