import { useState, useEffect } from 'react';

export interface ExitAlert {
  name: string;
  message: string;
}

interface ExitAlertWithHandler extends ExitAlert {
  handler: (event: any) => void;
}

interface UseExitPrompt {
  addExitAlert: (alert: ExitAlert) => void;
  removeExitAlert: () => void;
}

export default function useExitPrompt(): UseExitPrompt {
  const [exitAlert, setExitAlert] = useState<ExitAlertWithHandler | null>(null);

  const addExitAlert = (newAlert: ExitAlert) => {
    const handler = generateHandler(newAlert.message);
    setExitAlert({
      ...newAlert,
      handler
    });
  };

  const removeExitAlert = () => {
    setExitAlert(null);
  };

  useEffect(() => {
    if (exitAlert) {
      window.addEventListener('beforeunload', exitAlert.handler);
    }

    return () => {
      if (exitAlert) {
        window.removeEventListener('beforeunload', exitAlert.handler);
      }
    };
  }, [exitAlert]);

  return { addExitAlert, removeExitAlert };
}

const generateHandler = (message: string) =>  (event: any) => {
  const e = event || window.event;
  if (e) {
    e.preventDefault();
    e.returnValue = message;
  }
  return message;
};
