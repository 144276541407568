import { Box, styled } from '@mui/material';

export const FormWrapper = styled(Box)<{active: number; large?: boolean}>(({
    theme,
    active,
    large = false
}) => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  
    width: active ? '367px' : '0px',
    maxHeight: active ? `calc(100vh - ${large ? '170px' : '100px'})` : '54px',
    height: 'fit-content',
    minHeight: '60px',

    padding: !active ? '' : '30px 0px 0px 34px',
    background: theme.palette.neutrals.main,
  
    borderTop: 'none',
    borderRight: `${active ? 1 : 0}px solid ${theme.palette.primary.light}`,
    borderBottom: `${active ? 1 : 0}px solid ${theme.palette.primary.light}`,
    borderRadius: '0',
    borderBottomRightRadius: active ? '16px' : '0px',
  
    transition: 'all 0.5s',
    zIndex: 10,
  
    '& *': {
      display: !active && 'none',
    }
}));