import { useRequest } from '@hooks/request/UseRequest';
import { CurateSession } from '@utils/curate/CurateSession';
import { Image } from '@utils/image/image';
import { buildFormData, getRequestResult } from '@root/utils/helpers';
import {
    CurateLayerDto,
    GenerateEraseDto,
    GenerateFromSketchDto,
    GenerateInPaintDto,
    MixImageDto,
    SceneShiftDto,
    StyleDriveGenerationDto,
    UpscaleLayerDto
} from '@root/types/dto';
import { SavedCanvasInfo, SupportedRequestError } from '@root/types/commonTypes';
import { CurateApiItems } from '@root/types/apiTypes';

const CURATE_SESSIONS_END_POINT = '/curate-sessions';

export const useCurateApi = (): CurateApiItems => {
    const {
        requestPutWithJson,
        requestDelete,
        requestPutWithForm,
        requestPostWithForm,
        requestPostWithJson,
        requestGet,
    } = useRequest();

    const mixImage = async (generationConfig: MixImageDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);

        const response = await requestPostWithForm('/generations/mix-images', {
            data: formData,
        });
        return getRequestResult(response, { propertyName: 'imageIds' });
    };

    const getSessions = async (projectId: number): Promise<CurateSession[] | SupportedRequestError> => {
        const result = await requestGet(
            `/projects/${projectId}${CURATE_SESSIONS_END_POINT}`,
        );
        return result?.data;
    };

    const newSession = async (projectId: number, tuneId?: number): Promise<number | SupportedRequestError> => {
        const result = await requestPostWithJson(CURATE_SESSIONS_END_POINT, {
            data: {
                projectId,
                tuneId,
            },
        });
        return getRequestResult(result, { propertyName:'id' });
    };

    const newLayer = async (data: object): Promise<CurateLayerDto | SupportedRequestError> => {
        const response = await requestPostWithForm('/curate-layers', {
            data,
        });
        return response?.data;
    };

    const getSessionById = async (sessionId: number): Promise<CurateSession | SupportedRequestError> => {
        const result = await requestGet(
            `${CURATE_SESSIONS_END_POINT}/${sessionId}`,
        );
        return result?.data;
    };

    const getSessionLayers = async (sessionId: number): Promise<CurateLayerDto[] | SupportedRequestError> => {
        const result = await requestGet(
            `${CURATE_SESSIONS_END_POINT}/${sessionId}/layers`,
        );
        return result?.data;
    };

    const getImageBySession = async (sessionId: number): Promise<Image[] | SupportedRequestError> => {
        const result = await requestGet(
            `${CURATE_SESSIONS_END_POINT}/${sessionId}/images`,
        );
        return result?.data;
    };

    const generateInPaint = async (generationConfig: GenerateInPaintDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm('/generations/inpaint', {
            data: formData,
        });
        return response?.data;
    };

    const generateFromSketch = async (generationConfig: GenerateFromSketchDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm(
            '/generations/generate-from-sketch',
            {
                data: formData,
            },
        );
        return response?.data;
    };

    const sceneShiftApi = async (generationConfig: SceneShiftDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm(
            '/generations/scene-shift',
            {
                data: formData,
            },
        );
        return response?.data;
    };

    const confirmResults = async (sessionId: number, imagesId: number[]): Promise<any | SupportedRequestError> => {
        const result = await requestPutWithJson(
            `${CURATE_SESSIONS_END_POINT}/${sessionId}/confirm-results`,
            {
                data: {
                    imagesId,
                },
            },
        );
        return result?.data;
    };

    const saveCanvas = async (sessionId: number, data: object): Promise<SavedCanvasInfo | SupportedRequestError> => {
        const response = await requestPutWithForm(
            `${CURATE_SESSIONS_END_POINT}/${sessionId}/save-canvas-image`,
            {
                data,
            },
        );
        return response?.data;
    };
    const updateLayer = async (layerId: number, data: object): Promise<CurateLayerDto | SupportedRequestError> => {
        const response = await requestPutWithForm(`/curate-layers/${layerId}`, {
            data,
        });
        return response?.data;
    };

    const deleteLayerById = async (layerId: number): Promise<string | SupportedRequestError> => {
        const response = await requestDelete(`/curate-layers/${layerId}`);
        return getRequestResult(response, { propertyName:'id' });
    };

    const renameLayerById = async (layerId: number, name: string): Promise<CurateLayerDto | SupportedRequestError> => {
        const response = await requestPutWithJson(`/curate-layers/${layerId}/rename`,
            {
                data: {
                    name,
                },
            },
        );
        return response?.data;
    };

    const changeLayersOrder = async (curateSessionId: number, layersId: number[]): Promise<number | SupportedRequestError> => {
        const result = await requestPutWithJson('/curate-layers/change-order', {
            data: {
                curateSessionId,
                layersId,
            },
        });
        return getRequestResult(result, { propertyName:'curateSessionId' });
    };

    const convertToSketch = async (curateSessionId: number, originalImage: File): Promise<number | SupportedRequestError> => {
        const formData = new FormData();
        formData.append('inputImage', originalImage);
        formData.append('curateSessionId', curateSessionId.toString());

        const response = await requestPostWithForm('/generations/convert-to-sketch', {
            data: formData,
        });
        return response?.data;
    };

    const generateWithStyleDrive = async (generationConfig: StyleDriveGenerationDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);

        const response = await requestPostWithForm('/generations/style-drive', {
            data: formData
        });
        return response?.data;
    };

    const generateErase =  async (generationConfig: GenerateEraseDto): Promise<number[] | SupportedRequestError> => {
        const formData = buildFormData(generationConfig);
        const response = await requestPostWithForm('/generations/eraser', {
            data: formData,
        });
        return response?.data;
    };

    const upscaleLayer = async (generationConfig: UpscaleLayerDto): Promise<number | SupportedRequestError> => {
        const result = await requestPostWithJson('/generations/upscale-curate', {
            data: {
                curateSessionId: generationConfig.curateSessionId,
                imageId: generationConfig.imageId,
                upscaleFactor: generationConfig.upscaleFactor
            },
        });

        return getRequestResult(result, { propertyName:'imageId' });
    };

    const blendImages = async (background: File, foreground: File): Promise<string | SupportedRequestError> => {
        const formData = new FormData();
        formData.append('background', background);
        formData.append('foreground', foreground);

        const response = await requestPostWithForm('/images/blend-images', {
            data: formData,
        });
        return getRequestResult(response, { propertyName:'blendedImage' });
    };

    return {
        mixImage,
        getSessions,
        newSession,
        getImageBySession,
        generateInPaint,
        getSessionById,
        getSessionLayers,
        newLayer,
        confirmResults,
        saveCanvas,
        deleteLayerById,
        renameLayerById,
        changeLayersOrder,
        updateLayer,
        generateFromSketch,
        sceneShiftApi,
        convertToSketch,
        generateWithStyleDrive,
        generateErase,
        upscaleLayer,
        blendImages
    };
};
