import React, { useContext, useEffect, useState } from 'react';

import { TokenUpdate } from '@hooks/request/TokenUpdate';

import AuthContext from './AuthContext';
import LoaderContext from '../LoaderContext/LoaderContext';
import LocalStorageContext from '../LocalStorageContext/LocalStorageContext';

import axios from 'axios';
import { useAuthApi } from '@hooks/auth/AuthApi';
import {clearAuthStorage} from '@utils/auth/clearAuthStorage';

const AuthContextProvider = ({ children }) => {
    const { signUp, signIn } = useAuthApi();
    const {
        storageUsername,
        setStorageUsername,
        storageAccessToken,
        setStorageAccessToken,
        setStorageRefreshToken,
        storageUserRole,
        setStorageUserRole
    } = useContext(LocalStorageContext);

    const [isSignedIn, setSignedIn] = useState(!!storageAccessToken);
    const [isRequestError, setRequestError] = useState(false);
    const [username, setUsername] = useState(storageUsername);
    const [userRole, setUserRole] = useState(storageUserRole ? +storageUserRole : null);

    const { showLoader, hideLoader } = useContext(LoaderContext);

    const handleSignUp = (username, password) => {
        const data = {
            name: username,
            password: password,
        };

        signUp(data).then((success) => {
            setRequestError(!success);
            success && handleLogin(username, password);
        });
    };

    const handleLogin = (loginUsername, password) => {
        const data = {
            name: loginUsername,
            password: password,
        };

        setRequestError(false);
        showLoader();

        signIn(data).then((user) => {
            const userInfo = user?.errorCode ? null : user;

            setSignedIn(userInfo !== null);
            setRequestError(userInfo === null);
            hideLoader();

            setUsername(loginUsername);
            setStorageUsername(loginUsername);

            setStorageUserRole(userInfo ? userInfo.roleId?.toString() : '');
            setUserRole(userInfo ? userInfo.roleId : null);

            setStorageAccessToken(userInfo ? userInfo.accessToken : '');
            setStorageRefreshToken(userInfo ? userInfo.refreshToken : '');

            TokenUpdate();
        });
    };

    const signOut = () => {
        setSignedIn(false);

        clearAuthStorage();
        delete axios.defaults.headers.common['Authorization'];
    };

    useEffect(() => {
        if (!storageUsername) {
            signOut();
        }
    }, []);

    return (
        <AuthContext.Provider
            value={{
                isSignedIn,
                username,
                userRole,
                handleSignUp,
                handleLogin,
                signOut,
                isRequestError,
                setRequestError,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
