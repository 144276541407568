export const sortByExpirationDate = <T extends { expirationDate: string | null }>(array: T[]): T[] => {
    return array.sort(
        (a, b) =>  {
            if (!b.expirationDate) {
                return  -1;
            }

            if (!a.expirationDate) {
                return  1;
            }

            return Date.parse(a.expirationDate) - Date.parse(b.expirationDate);
        },
    );
};
