import React, {useEffect, useRef, useState} from 'react';

function useAutosave(callback, delay = 1000, deps = [], disable = false) {
    // To save the current "fresh" callback.
    const savedCallback = useRef();

    // Keep callback ref up to date.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Create the interval.
    useEffect(() => {
        if (disable) {
            return;
        }

        // Function to call the callback.
        function runCallback() {
            savedCallback.current();
        }

        if (typeof delay === 'number') {
            // Run the interval.
            let interval = setTimeout(runCallback, delay);

            // Clean up on unmount or dependency change.
            return () => clearTimeout(interval);
        }
    }, [delay, ...deps]);
}

export default useAutosave;
