import React from 'react';
import { SearchFieldProps } from './SearchField.types';
import { Box, IconButton, TextField, styled } from '@mui/material';
import { CloseIcon } from '@root/assets/icons/CloseIcon';
import { SearchIcon } from '@root/assets/icons/SearchIcon';


const SearchField = ({widthCssValue, heightCssValue, searchValue, setSearchValue, onClose}: SearchFieldProps): React.JSX.Element => {
    const handleSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchValue(event.target.value);
    };

    const clearSearchValue = (): void => {
        setSearchValue('');
        onClose();
    };

  return (
    <Container>
        <SearchInput
            widthValue={widthCssValue}
            heightValue={heightCssValue}
            value={searchValue}
            onChange={handleSearchValueChange}
            variant="outlined"
            placeholder="Search"
            InputProps={{
                endAdornment:
                <>
                    {
                        <ActionButton disableRipple>
                            <CloseIcon onClick={clearSearchValue}/>
                        </ActionButton>
                    }
                </>,
                startAdornment:
                <>
                    {
                        <LabelButton disableRipple>
                            <SearchIcon/>
                        </LabelButton>
                    }
                </>,
            }}
        />
    </Container>
  );
};

export default SearchField;

const Container = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
}));

const SearchInput = styled(TextField)<{widthValue: string, heightValue: string}>(({widthValue, heightValue, theme}) => ({
    display: 'flex',
    alignItems: 'center',

    width: widthValue,
    minHeight: heightValue,

    '& .MuiOutlinedInput-root': {
        gap: '12px',
        width: widthValue,
        minHeight: heightValue,
        fontSize: '14px',
        fontFamily: 'Roboto400',

        padding: '0',
        paddingLeft: '7px',
        paddingRight: '7px',
        paddingTop: '4px',
        paddingBottom: '4px',
        borderRadius: '6px',

        'fieldset': {
            borderColor: theme.palette.primary.light,
        },
        
        '&:hover fieldset': {
            borderColor: theme.palette.primary.light,
        },

        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.light1,
            borderWidth: '1px'
        },
        
        '&.Mui-focused textarea': {
            color: theme.palette.primary.contrastText,
        }
    },

    '& input': {
        padding: '0',
    }
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
    width: '24px',
    height: '24px',
    color: theme.palette.primary.contrastText,

    '& svg': {
      width: '24px',
      height: '24px',
    },
    '&:hover': {
      background: 'transparent',
    }
}));

const LabelButton = styled(IconButton)(({ theme }) => ({
    width: '18px',
    height: '18px',
    color: theme.palette.primary.light1,

    '& svg': {
      width: '18px',
      height: '18px',
    },
    '&:hover': {
      background: 'transparent',
    }
}));