import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, styled } from '@mui/material';
import { MergeModelsFormProps } from './MergeModelsForm.types';
import { MergeModelsDto, ModelDto } from '@root/types/dto';
import { FormWrapper } from '@root/components/FormWrapper';
import ExpandMenuButton from '@root/components/ExpandMenuButton';
import InputField from '@root/components/InputField';
import TwoComponentSlider from '@root/components/TwoComponentSlider';
import DragAndDropObjectPanel from '@root/components/DragAndDropObjectPanel';
import { CommonConstants } from '@root/utils/constants';
import { ErrorTooltip } from '@root/components/ErrorTooltip';

const MergeModelsForm = ({
    isActive,
    setIsActive,
    onSubmit,
    errorMessage,
    setErrorMessage
}: MergeModelsFormProps): React.JSX.Element => {
    const [modelName, setModelName] = useState<string>('');
    const [isMergeEnabled, setIsMergeEnabled] = useState<boolean>(false);
    const [firstModelInfluence, setFirstModelInfluence] = useState<number>(CommonConstants.DEFAULT_MERGE_MULTIPLIER);
    const [firstModel, setFirstModel] = useState<ModelDto | null>(null);
    const [secondModel, setSecondModel] = useState<ModelDto | null>(null);

    const buttonAction = (): void => {
      setIsActive(!isActive);
      setErrorMessage('');
    };

    const submitAction = (event: React.FormEvent): void => {
        event.preventDefault();
        if (firstModel && secondModel) {
    
        const config: MergeModelsDto = {
            primaryModelId: firstModel.Id,
            secondaryModelId: secondModel.Id,
            modelName: modelName,
            multiplier: 100 - firstModelInfluence
        };

        onSubmit(config);
        }
    };

    const onModelTitleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (errorMessage) {
            setErrorMessage('');
        }

        setModelName(event.target.value);
    };

    useEffect(() => {
        setIsMergeEnabled(!!firstModel?.Id && !!secondModel?.Id && !!modelName);
    }, [firstModel, secondModel, modelName]);
    
  return (
    <Wrapper>
        <FormWrapper
          id="mergeForm"
          component="form"
          active={isActive ? 1 : 0}
          onSubmit={submitAction}>
            <Content>
                <ErrorTooltip className='form-menu-input' title={errorMessage} placement="right" open={!!errorMessage && isActive} arrow>
                    <span>
                        <InputField
                        id="mergeModelsResultName"
                        name="mergeModelsResultName"
                        isRequired={true}
                        label="new model name" 
                        value={modelName || ''}
                        onChange={onModelTitleInputChange}
                        />
                    </span>
                </ErrorTooltip>
                <ImageWrapper>
                    { firstModel && 
                        <ModelInfo>
                            <FormLabel info={false}> {'model 1'}:&nbsp;</FormLabel>
                            <FormLabel info={true}> {firstModel.Name.replaceAll('.safetensors', '')}</FormLabel>
                        </ModelInfo>
                    }
                    <DragAndDropObjectPanel
                        label={`drag and drop ${CommonConstants.COMPLEX_LABEL_SEPARATOR}
                            ${'model 1'}
                            ${CommonConstants.COMPLEX_LABEL_SEPARATOR} for the merge`}
                            onObjectUpload={setFirstModel}
                    />
                </ImageWrapper>
                <TwoComponentSlider
                    value={firstModelInfluence}
                    setValue={setFirstModelInfluence}
                    leftLabel={'model 1'}
                    rightLabel={'model 2'}
                    small={true}
                    withLabels={true}
                />
                <ImageWrapper>
                    { secondModel && 
                        <ModelInfo>
                            <FormLabel info={false}> {'model 2'}:&nbsp;</FormLabel>
                            <FormLabel info={true}> {`${secondModel.Name.replaceAll('.safetensors', '')}`}</FormLabel>
                        </ModelInfo>
                    }
                    <DragAndDropObjectPanel
                        label={`drag and drop ${CommonConstants.COMPLEX_LABEL_SEPARATOR}
                            ${'model 2'}
                            ${CommonConstants.COMPLEX_LABEL_SEPARATOR} for the merge`}
                            onObjectUpload={setSecondModel}
                    />
                </ImageWrapper>
            </Content>
            <Footer>
                <FormButton
                    active={isActive ? 1 : 0}
                    disabled={!isMergeEnabled}
                    type="submit"
                    variant="contained">
                        MERGE
                </FormButton>
            </Footer>
        </FormWrapper>
        <ExpandMenuButton
            isActive={isActive}
            buttonAction={buttonAction}/>
  </Wrapper>
  );
};

export default MergeModelsForm;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    maxWidth: '402px',
    minWidth: '210px',
    flex: 1,
}));

const Content = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  
    overflowY: 'auto',
    overflowX: 'hidden',
    gap: '32px',
}));

const ImageWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  paddingRight: '32px'
}));

const Footer = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '25px 0px',
    gap: '32px'
}));
  
const FormButton = styled(Button)<{active: number}>(({ active }) => ({
    width: '300px',
    transition: 'all 0.5s',
    display: active ? 'block' : 'none'
}));

const FormLabel = styled(Typography)<{ info: boolean}>(({ theme, info }) => ({
    fontFamily: 'Roboto400',
    color: info ? theme.palette.primary.contrastText : theme.palette.primary.inactive,
    fontSize: '14px',
    lineHeight: '16px',

    textOverflow: info ? 'ellipsis' : 'initial',
    overflow: 'hidden',
    whiteSpace: info ? 'nowrap' : 'initial',
    maxWidth: info ? '220px' : '300px',

    '@media (max-width:1500px)': {
        fontSize: '12px',
        lineHeight: '14px',
    },
}));

const ModelInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-start',
    marginBottom: '12px'
}));
