import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const ShareIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24" 
            {...props}>
            <path d="M20.5053 9.05448L16.0053 13.5545C15.8646 13.6952 15.6737 13.7743 15.4747 13.7743C15.2757 13.7743 15.0848 13.6952 14.9441 13.5545C14.8033 13.4137 14.7243 13.2229 14.7243 13.0239C14.7243 12.8248 14.8033 12.634 14.9441 12.4932L18.1644 9.27385H14.4434C12.5 9.27385 11.4464 9.88091 10 11.001C8.55359 12.121 6.94042 15.5665 6.48307 17.3378C6.43334 17.5305 6.3091 17.6955 6.13768 17.7966C5.96626 17.8977 5.76171 17.9266 5.56901 17.8769C5.37631 17.8271 5.21126 17.7029 5.11017 17.5315C5.00908 17.3601 4.98022 17.1555 5.02995 16.9628C5.56958 14.8689 7.29034 11.3248 9 10.001C10.7097 8.67711 12.2432 7.72091 14.4434 7.77385H18.1663L14.9441 4.55448C14.8744 4.4848 14.8191 4.40207 14.7814 4.31103C14.7437 4.21998 14.7243 4.1224 14.7243 4.02385C14.7243 3.92531 14.7437 3.82773 14.7814 3.73668C14.8191 3.64564 14.8744 3.56291 14.9441 3.49323C15.0848 3.3525 15.2757 3.27344 15.4747 3.27344C15.5732 3.27344 15.6708 3.29285 15.7619 3.33056C15.8529 3.36827 15.9356 3.42355 16.0053 3.49323L20.5053 7.99323C20.575 8.06288 20.6304 8.1456 20.6681 8.23665C20.7058 8.3277 20.7253 8.42529 20.7253 8.52385C20.7253 8.62242 20.7058 8.72001 20.6681 8.81106C20.6304 8.90211 20.575 8.98482 20.5053 9.05448ZM18 19.5012H3.75V8.25124C3.75 8.05233 3.67098 7.86156 3.53033 7.72091C3.38968 7.58026 3.19891 7.50124 3 7.50124C2.80109 7.50124 2.61032 7.58026 2.46967 7.72091C2.32902 7.86156 2.25 8.05233 2.25 8.25124V19.5012C2.25 19.8991 2.40804 20.2806 2.68934 20.5619C2.97064 20.8432 3.35218 21.0012 3.75 21.0012H18C18.1989 21.0012 18.3897 20.9222 18.5303 20.7816C18.671 20.6409 18.75 20.4501 18.75 20.2512C18.75 20.0523 18.671 19.8616 18.5303 19.7209C18.3897 19.5802 18.1989 19.5012 18 19.5012Z" fill="currentColor"/>
        </SvgIcon>
    );
};