import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AspectRatioLandscapeIcon = (props: SvgIconProps): React.JSX.Element => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17 10"
            {...props}>
            <path opacity="0.35" d="M15.9998 8.33333C15.9998 8.92221 15.4398 9.5 14.6174 9.5L2.38211 9.5C1.55973 9.5 0.999756 8.92221 0.999756 8.33333L0.999756 1.66667C0.999756 1.07779 1.55973 0.5 2.38211 0.5L14.6174 0.5C15.4398 0.5 15.9998 1.07779 15.9998 1.66667L15.9998 8.33333Z" stroke="currentColor" fill="none"/>
            <rect opacity="0.7" x="14.5" y="8" width="12" height="6" transform="rotate(180 14.5 8)" fill="currentColor"/>
        </SvgIcon>
    );
};
