import React from 'react';
import {Box, styled, Typography} from '@mui/material';
import { InfoBlockProps } from './InfoBlock.types';

const InfoBlock = ({
    className,
    fullWidth = false,
    title,
    info,
    infoImage,
    isPrimary = true
}: InfoBlockProps): React.JSX.Element => {
    return (
        <Wrapper className={className} fullWidth={fullWidth}>
            <InfoLabel primary={isPrimary} variant={'body16'}>
                {title}
            </InfoLabel>

            { infoImage &&
                <StyledImg loading="lazy" src={infoImage} />
            }
            { info &&
                <StyledLabel component="p">
                    {info}
                </StyledLabel>
            }
        </Wrapper>
    );
};

export default InfoBlock;

const InfoLabel = styled(Typography)<{ primary: boolean }>(({ theme, primary }) => ({
    fontFamily: primary ? 'Roboto500' : 'Roboto400',
    color: theme.palette.primary.inactive,
}));

const Wrapper = styled(Box)<{ fullWidth: boolean }>(({ fullWidth }) => ({
  display: 'flex',
  flexDirection: 'column',

  width: fullWidth ? '100%' : '50%',
  height: 'fit-content'
}));

const StyledImg = styled('img')(() => ({
    marginTop:'14px',

    maxWidth: '180px',
    maxHeight: '170px',

    borderRadius: '10px',

    objectFit: 'contain',
    width: 'fit-content',
    alignSelf: 'center'
}));

const StyledLabel = styled(Typography)<{ component: string }>(() => ({
    fontFamily: 'Roboto400',
    fontSize: '16px',
    lineHeight: '22px',

    '@media (max-width:1500px)': {
        fontSize: '14px',
        lineHeight: '18px',
    },
}));
