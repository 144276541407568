import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const AddImageIngredientIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={'0 0 50 50'}
            {...props}>
            <path d="M24.8182 45.4723C36.2244 45.4723 45.4704 36.2263 45.4704 24.8201C45.4704 13.4139 36.2244 4.16797 24.8182 4.16797C13.412 4.16797 4.16602 13.4139 4.16602 24.8201C4.16602 36.2263 13.412 45.4723 24.8182 45.4723Z" stroke="white" strokeWidth="1.51229" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M24.8175 16.5596V33.0813M16.5566 24.8204H33.0784" stroke="white" strokeWidth="1.51229" strokeLinecap="round" strokeLinejoin="round"/>
        </SvgIcon> 
    );
};
