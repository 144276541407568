import React from 'react';
import { styled, Stack } from '@mui/material';
import InfoBlock from '../InfoBlock';
import { ImageMetadataRowProps } from './ImageMetadataRow.types';

const ImageMetadataRow = ({ title, info }: ImageMetadataRowProps): React.JSX.Element => {
    return (
        <InfoRow>
            <InfoBlock
                title={title}
                info={info}
                fullWidth
            />
        </InfoRow>
    );
};

export default ImageMetadataRow;

const InfoRow = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
}));