import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sortByDateCreated } from '@utils/sortByDateCreated';

import ModelsContext from './ModelsContext';
import LoaderContext from '../LoaderContext/LoaderContext';
import { useNotifications } from '@context/NotificationsContext/useNotifications';
import AuthContext from '../AuthContext/AuthContext';
import useExitPrompt from '@hooks/exitPrompt/UseExitPrompt';
import {useModelApi} from '@hooks/model/ModelApi';
import { CommonConstants } from '@root/utils/constants';
import { ImportWithOptStatus, ModelStatus, NavigationPaths } from '@root/utils/constants/enums';
import { useJobs } from '@root/hooks/jobs/UseJobs';


export const EXPIRED_OTP_LABEL = 'invalid';


const ModelsContextProvider = ({ children }) => {
    const navigate = useNavigate();

    const { updateJobStatus } = useJobs();
    const { showLoader, hideLoader } = useContext(LoaderContext);
    const { isSignedIn } = useContext(AuthContext);
    const { createNotification, notificationMessages } = useNotifications();
    const { addExitAlert, removeExitAlert } = useExitPrompt();
    const { newModel,
        getModels,
        getModel: getModelFromApi,
        importModel,
        deleteModel,
        mergeModels: mergeModelsApi,
        applyOtp
    } = useModelApi();

    const [models, setModels] = useState([]);
    const [trainedModelsList, setTrainedModelsList] = useState([]);
    const [importInProgress, setImportInProgress] = useState(false);

    const [unsavedModelId, setUnsavedModelId] = useState(false);
    const [unsavedModelName, setUnsavedModelName] = useState('');
    const [selectedModelName, setSelectedModelName] = useState('');

    const refreshModels = async () => {
        const models = await getModels();

        if (models && !models.errorCode) {
            const sortedModels = sortByDateCreated(models);

            const trainedModelsList = sortedModels.filter(
                (el) => (
                    el.StatusId === ModelStatus.Trained ||
                    el.StatusId === ModelStatus.Merged
                ),
            );

            setModels(sortedModels);
            setTrainedModelsList(trainedModelsList);
        }
    };

    const createNewModel = async (data) => {
        showLoader();
        const result = await newModel(data);
        hideLoader();
        if (result && !result.errorCode) {
            createNotification(
                notificationMessages.model.creating.title,
                `'${data.modelName}' ${notificationMessages.model.creating.success}`,
            );
            refreshModels();
            navigate(`/${NavigationPaths.Models}/${result}`);
            return result;
        } else {
            createNotification(
                notificationMessages.model.creating.title,
                notificationMessages.model.creating.error,
            );
            return result;
        }
    };

    const getModel = async (modelId) => {
        const model = await getModelFromApi(modelId);

        if (model && !model.errorCode) {
            return {
                statusId: model.StatusId,
                model: model
            };
        }
    };

    const checkModelStatus = async (modelId) => {
        const model = await getModel(modelId);
        return model?.statusId;
    };

    const handleImportModel = (modelFile, onProgressChange) => {
        setImportInProgress(true);
        const exitAlert = {
            name: 'importing-model',
            message: 'The model is being imported. Closing/refreshing the tab may interrupt the process.'
        };
        addExitAlert(exitAlert);

        createNotification(
            notificationMessages.model.importing.title,
            notificationMessages.model.importing.info,
            );

        const notifyFailed = () => createNotification(
            notificationMessages.model.importing.title,
            notificationMessages.model.importing.error,
            'browser'
        );

        return importModel(modelFile, onProgressChange)
            .then((response) => {
            removeExitAlert();
            setImportInProgress(false);
            if (response?.Id) {
                createNotification(
                    notificationMessages.model.importing.title,
                    notificationMessages.model.importing.success,
                    'browser'
                );
                refreshModels();
            } else {
                notifyFailed();
            }
            return !!response?.Id;
        });
    };

    const getModelNameById = (id) => {
        if (!models.length || !id) {
            return CommonConstants.MODEL_NOT_FOUND_LABEL;
        }
        const modelName = models.find((el) => el.Id === id)?.Name.replaceAll('.safetensors', '');

        return modelName ? modelName : CommonConstants.MODEL_NOT_FOUND_LABEL;
    };

    const deleteModelById = async (id) => {
        showLoader();
        const response = await deleteModel(id);
        if (response && !response.errorCode) {
            setModels((models) => models.filter((model) => model.Id !== id));
            setTrainedModelsList((models) => models.filter((model) => model.Id !== id));

            await refreshModels();
            createNotification(
                notificationMessages.model.deleting.title,
                notificationMessages.model.deleting.success,
            );
        } else {
            createNotification(
                notificationMessages.model.deleting.title,
                notificationMessages.model.deleting.error,
            );
        }
        hideLoader();
    };

    const mergeModels = async(mergeConfig) => {
        showLoader();
        const result = await mergeModelsApi(mergeConfig);

        if (result && !result.errorCode) {
            refreshModels();
            updateJobStatus();
        }
        hideLoader();
        return result;
    };
    
    const handleImportWithOtp = async(key) => {
        const result = await applyOtp(key);

        if (result && !result.errorCode) {
            await refreshModels();
            updateJobStatus();

            return ImportWithOptStatus.Success;
        } else if (result.errorCode) {
            return result.message.includes(EXPIRED_OTP_LABEL) ?
                ImportWithOptStatus.Expired :
                ImportWithOptStatus.NotFound;
        }
    };

    useEffect(() => {
        !isSignedIn && setModels([]);
        !isSignedIn && setTrainedModelsList([]);
        isSignedIn &&  refreshModels();
    }, [isSignedIn]);


    return (
        <ModelsContext.Provider
            value={{
                models,
                refreshModels,
                trainedModelsList,
                getModel,
                checkModelStatus,
                createNewModel,
                handleImportModel,
                getModelNameById,
                deleteModelById,
                mergeModels,
                handleImportWithOtp,
                importInProgress,
                unsavedModelId,
                setUnsavedModelId,
                unsavedModelName,
                setUnsavedModelName,
                selectedModelName,
                setSelectedModelName
            }}>
            {children}
        </ModelsContext.Provider>
    );
};

export default ModelsContextProvider;
