import React, { useState } from 'react';
import { Snackbar, Stack, Typography, styled } from '@mui/material';
import { Done } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { LinkIcon } from '@root/assets/icons/LinkIcon';
import { CopyIcon } from '@root/assets/icons/CopyIcon';
import { CopyValueButtonProps } from './CopyValueButton.types';

const AUTO_HIDE_DURATION = 2000;

const CopyValueButton = ({ value, isLink = true }: CopyValueButtonProps): React.JSX.Element => {
    const [linkCopied, setLinkCopied] = useState(false);

    const onCopy = (): void => {
        setLinkCopied(true);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={linkCopied}
                autoHideDuration={AUTO_HIDE_DURATION}
                onClose={() => setLinkCopied(false)}>
                <Wrapper>
                    <DoneLabel/>
                    <Label>
                        { isLink ? 'Link' : 'Value' } copied to clipboard 
                    </Label>
                </Wrapper>
            </Snackbar>
            <CopyToClipboard text={value} onCopy={onCopy}>
                {isLink ? <LinkIcon /> : <CopyIcon/> }
            </CopyToClipboard>
        </>
    );
};

export default CopyValueButton;

const Wrapper = styled(Stack)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    background: theme.palette.primary.light,
    padding: `${theme.spacing(0.6)} ${theme.spacing(1.4)}`,
    borderRadius: '4px',
    gap: '20px',
}));

const DoneLabel = styled(Done)(() => ({
    fontFamily: 'Roboto400',
    fontSize: '10px',
}));

const Label = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.primary.main,
}));