import {useStatisticsApi} from '@hooks/statistics/StatisticsApi';

interface UseStatistics {
    pageViewed(pageName: string): void;
    toolViewed(pageName: string, toolName: string): void;
    timeSpentOnPage(pageName: string, timeSpent: number): void;
    timeSpentOnTool(pageName: string, toolName: string, timeSpent: number): void;
}

export const useStatistics = (): UseStatistics => {
    const { register } = useStatisticsApi();
    
    const pageViewed = (pageName: string) => {
        register({'eventName': 'Page Viewed', 'eventDetails': { 'Page Name': pageName }});
    };

    const toolViewed = (pageName: string, toolName: string) => {
        register({'eventName': 'Tool Viewed', 'eventDetails': { 'Page Name': pageName, 'Tool Name': toolName }});
    };

    const timeSpentOnPage = (pageName: string, timeSpent: number) => {
        register({'eventName': 'Time Spent on Page', 'eventDetails': {'Page Name': pageName, 'Time Spent (seconds)': timeSpent}});
    };

    const timeSpentOnTool = (pageName: string, toolName: string, timeSpent: number) => {
        register({'eventName': 'Time Spent on Tool', 'eventDetails': {'Page Name': pageName,  'Tool Name': toolName, 'Time Spent (seconds)': timeSpent}});
    };

    return {
        pageViewed,
        toolViewed,
        timeSpentOnPage,
        timeSpentOnTool
    };
};

