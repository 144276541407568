export const  buildFormData  = (config: any): FormData => {
    const formData = new FormData();

    for (const [key, value] of Object.entries(config)) {
        const formDataValue = convertToSupportedType(value);
        formData.append(key, formDataValue);
    }

    return formData;
};

const convertToSupportedType = (value: any) => {
    switch (value) {
        case typeof value === 'boolean' || value instanceof Boolean:
        case typeof value === 'number' || value instanceof Number:
            return value.toString();
        default:
            return value;
    }
};