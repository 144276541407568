class DrawCanvas {
    constructor(canvasElement, viewportElement) {
        this.canvasElement = canvasElement;

        this.viewportElement = viewportElement;

        this.isDrawing = false;

        this.points = [];

        this.enable = false;

        this.ctx = this.canvasElement.getContext('2d');

        this.color = 'black';
        this.lineWidth = 0;
        this.isLineDash = false;
        this.lineDashStyle = [5, 3];

        this.diameter = 1;
    }

    computePointInCanvas(clientX, clientY) {
        if (!this.viewportElement) {
            return null;
        } else {
            const boundingRect = this.viewportElement.getBoundingClientRect();

            return {
                x: clientX - boundingRect.left,
                y: clientY - boundingRect.top,
            };
        }
    }

    setLines = () => {
        this.isLineDash && this.ctx.setLineDash(this.lineDashStyle);
        this.ctx.lineWidth = this.lineWidth;
        this.ctx.globalCompositeOperation = 'source-over';
    };

    distanceBetween(point1, point2) {
        return Math.sqrt(
            Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2),
        );
    }
    angleBetween(point1, point2) {
        return Math.atan2(point2.x - point1.x, point2.y - point1.y);
    }

    renderSelection = () => {
        this.lastPointInfo = this.points[0];
        this.points.forEach((pointInfo) => {
            this.drawLine(pointInfo);
        });
    };

    drawLine = (pointInfo) => {
        this.setLines();

        const lastPoint = this.lastPointInfo.point ? this.lastPointInfo.point : this.lastPointInfo;
        const point = pointInfo.point ? pointInfo.point : pointInfo;
        const size = pointInfo.size ? pointInfo.size : this.diameter;
        const color = pointInfo.color ?  pointInfo.color : this.color;

        this.ctx.strokeStyle = color;
        this.ctx.fillStyle = color;

        const dist = this.distanceBetween(lastPoint, point);
        const angle = this.angleBetween(lastPoint, point);

        for (let i = 0; i < dist; i += 1) {
            const x = lastPoint.x + Math.sin(angle) * i;
            const y = lastPoint.y + Math.cos(angle) * i;
            this.ctx.beginPath();
            this.ctx.arc(
                x + size / 2,
                y + size / 2,
                size,
                false,
                Math.PI * 2,
                false,
            );
            this.ctx.closePath();
            this.ctx.fill();
            this.ctx.stroke();
        }

        this.lastPointInfo = point;
    };

    onMouseDown = (e) => {
        if (!this.enable) {
            return;
        }
        this.isDrawing = true;
        const point = e.clientX
            ? this.computePointInCanvas(e.clientX, e.clientY)
            : this.computePointInCanvas(
                  e.touches[0].clientX,
                  e.touches[0].clientY,
              );

        const pointInfo = {point: point, size: this.diameter, color: this.color };
        this.points = [pointInfo];
        this.lastPointInfo = pointInfo;
    };

    onMouseMove = (e) => {
        if (!this.isDrawing) {
            return;
        }
        const point = e.clientX
            ? this.computePointInCanvas(e.clientX, e.clientY)
            : this.computePointInCanvas(
                  e.touches[0].clientX,
                  e.touches[0].clientY,
              );

        const pointInfo = {point: point, size: this.diameter, color: this.color };
        this.points.push(pointInfo);

        this.drawLine(pointInfo);
    };

    onMouseUp = () => {
        if (!this.isDrawing) {
            return;
        }

        this.isDrawing = false;
    };

    clearCanvas = (
        width = this.viewportElement.clientWidth,
        height = this.viewportElement.clientHeight,
    ) => {
        this.ctx.clearRect(0, 0, width, height);
    };
}

export default DrawCanvas;
