import React, { useEffect, useRef, useState } from 'react';
import { Box, styled } from '@mui/material';
import DrawCanvas from '@root/lib/DrawCanvas/DrawCanvas';
import EraserSettingsPanel from '@root/components/EraserSettingsPanel';
import { useCurate } from '@hooks/curate/useCurate';
import { CuratePageConstants } from '@root/utils/constants';
import { CurateTools } from '@root/utils/constants/enums';
import MousePointer from '../MousePointer';
import { AuxiliaryCanvas } from '../CuratedCanvasComponents';
import { EraserToolProps } from './EraserTool.types';
import { useLicenseValidation } from '@root/context/LicenseContext/useLicenseValidation';

const EraserTool = ({ imageRef, width, height, updatePointerPosition } : EraserToolProps): React.JSX.Element => {
    const { checkLicenseStatus } = useLicenseValidation();
    const eraserCanvasMask = useRef<HTMLCanvasElement>(null);
    const [eraserBrush, setEraserBrush] = useState<any>('');
    const [actualPointsEraser, setActualPointsEraser] = useState([[]]);

    const [pointerPosition, setPointerPosition] = useState<{ x: number; y: number } | null>(null);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [isShiftDown, setIsShiftDown] = useState<boolean>(false);

    const {
        activeTool,
        eraserToolSize,
        setEraserToolSize,
        setEraserMask,
        isEraserInProgress,
        lastVisibleLayerId,
        setIsEraserInProgress
    } = useCurate();

    const onMouseDown = (event?: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): void => {
        if (activeTool === CurateTools.Eraser) {
            setIsDrawing(true);

            if (!isShiftDown) {
                setActualPointsEraser([]);
            }

            eraserBrush.onMouseDown(event);
            document.addEventListener('mouseup', onMouseUpEraser, { once: true });
            document.addEventListener('touchend', onMouseUpEraser, { once: true });
        }
    };
    const onMouseMove = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>): void => {
        if (activeTool === CurateTools.Eraser) {
            const position = updatePointerPosition(
                event,
                eraserToolSize,
                eraserCanvasMask,
                isDrawing
            );

            setPointerPosition(position);
            eraserBrush.onMouseMove(event);
        }
    };

    const onMouseUpEraser = (): void => {
        setIsDrawing(false);

        eraserBrush.onMouseUp();
        if (eraserBrush.points.length) {
            setActualPointsEraser((prev) => [
                ...prev,
                [...eraserBrush.points],
            ] as any);
        }
    };

    const createEraserMask = async (): Promise<void> => {
        if (!eraserCanvasMask?.current) {
            return;
        }
        const canvas = document.createElement('canvas');

        canvas.width = eraserCanvasMask?.current?.clientWidth;
        canvas.height = eraserCanvasMask?.current?.clientHeight;

        const sketch = new DrawCanvas(canvas, canvas);
        sketch.color = eraserBrush.color;
        sketch.diameter = eraserBrush.diameter;

        sketch.clearCanvas(canvas.width, canvas.height);
        
        sketch.ctx.fillStyle = 'black';
        sketch.ctx.fillRect(0, 0, canvas.width, canvas.height);

        actualPointsEraser.forEach((element) => {
            sketch.points = element;
            sketch.ctx.fillStyle = 'white';
            sketch.isLineDash = false;
            sketch.lineWidth = 1;
            sketch.renderSelection();
        });

        const image = new Image();
        image.src = canvas.toDataURL('image/png');

        setIsEraserInProgress(true);

        image.addEventListener('load', async () => {
            canvas.width = imageRef.current.naturalWidth;
            canvas.height = imageRef.current.naturalHeight;
            sketch.clearCanvas(canvas.width, canvas.height);
            sketch.ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            const isValid = await checkLicenseStatus();

            if (!isValid) {
                eraserBrush.clearCanvas(
                    eraserCanvasMask?.current?.clientWidth,
                    eraserCanvasMask?.current?.clientHeight,
                );
                setIsEraserInProgress(false);

                return;
            }
    
            setActualPointsEraser([]);
            setEraserMask(canvas.toDataURL('image/png'));
        });

    };

    const onKeyDown = (event: KeyboardEvent): void => {
        if (event.key === 'Shift') {
            setIsShiftDown(true);
        }
    };

    const onKeyUp = (event: KeyboardEvent): void => {
        if (event.key === 'Shift') {
            setIsShiftDown(false);
        }
    };

    useEffect(() => {
        if (!eraserBrush) {
            return;
        }

        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
    
        return () => {
            window.removeEventListener('keydown', onKeyDown);
            window.removeEventListener('keyup', onKeyUp);
        };
    }, [eraserBrush]);

    useEffect(() => {
        if (!eraserBrush) {
            return;
        }

        if (!isShiftDown && (actualPointsEraser[0]?.length > 1 || actualPointsEraser.length > 1)) {
            createEraserMask();
        }
    }, [isShiftDown]);

    useEffect(() => {
        if (!eraserCanvasMask.current || eraserBrush) {
            return;
        }

        const brush = new DrawCanvas(eraserCanvasMask.current, eraserCanvasMask.current);
        setEraserBrush(brush);
    }, [eraserCanvasMask.current]);

    useEffect(() => {
        if (!actualPointsEraser.length) {
            return;
        }

        if (actualPointsEraser[0]?.length > 1 && !isShiftDown) {
            createEraserMask();
        }
    }, [actualPointsEraser]);
    

    useEffect(() => {
        if (!eraserBrush) {
            return;
        }

        eraserBrush.enable = activeTool === CurateTools.Eraser;

        if (lastVisibleLayerId && activeTool === CurateTools.Eraser) {
            eraserBrush.color = CuratePageConstants.DEFAULT_ERASER_COLOR;
            eraserBrush.diameter = eraserToolSize;
        }
    }, [activeTool, lastVisibleLayerId, eraserBrush]);

    useEffect(() => {
        if (!eraserBrush) {
            return;
        }

        eraserBrush.diameter = eraserToolSize;

    }, [eraserToolSize]);
    

    useEffect(() => {
        if (activeTool !== CurateTools.Eraser || !eraserBrush) {
            return;
        }

        eraserBrush.clearCanvas(
            eraserCanvasMask?.current?.clientWidth,
            eraserCanvasMask?.current?.clientHeight,
        );
    }, [lastVisibleLayerId]);

    useEffect(() => {
        if (activeTool !== CurateTools.Eraser || !eraserBrush || isEraserInProgress) {
            return;
        }

        eraserBrush.clearCanvas(
            eraserCanvasMask?.current?.clientWidth,
            eraserCanvasMask?.current?.clientHeight
        );
    }, [isEraserInProgress]);

    return (
        <>
            <AuxiliaryCanvas
                isTransparent={true}
                display={activeTool === CurateTools.Eraser ? 1 : 0}
                loading={isEraserInProgress}
                animated={isEraserInProgress}
                onMouseDown={onMouseDown}
                onTouchStart={onMouseDown}
                onMouseMove={onMouseMove}
                onTouchMove={onMouseMove}
                width={width}
                height={height}
                ref={eraserCanvasMask}/>

                <SettingsWrapper>
                    <EraserSettingsPanel
                        withSpaceTooltip={false}
                        eraserSize={eraserToolSize}
                        setEraserSize={setEraserToolSize}/>
                </SettingsWrapper>

            { !isEraserInProgress && pointerPosition && !isDrawing &&
                (<MousePointer
                    size={eraserToolSize}
                    position={pointerPosition}
                    color={CuratePageConstants.DEFAULT_BRUSH_POINTER_COLOR}
                    onMouseDown={onMouseDown}/>
                )
            }
        </>
    );
};

export default EraserTool;

const SettingsWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    // 108px - half of the  settings toolbar width
    right: 'calc(50% - 108px)',
    top: 'calc(100% + 35px)',

    gap: '20px',

    '& svg': {
        fontSize: '20px'
    }
}));