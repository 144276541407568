import React, { useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { FileUploader } from 'react-drag-drop-files';
import AddImageSmall from '../../../../../../../../assets/images/AddImageSmall.png';
import ContentLoader from '@root/components/ContentLoader';
import { FileUploadIcon } from '@root/assets/icons/FileUploadIcon';
import { CameraViewFileUploaderProps } from './CameraViewFileUploader.types';
import { Model3dErrorCode } from '@root/utils/constants/enums';
import { MAX_FILE_SIZE } from '@root/utils/constants/cameraViewConstants';

const CameraViewFileUploader = ({ addModel, isLoading, currentFileName, onError }: CameraViewFileUploaderProps): React.JSX.Element => {

    const [uploadedFileName, setUploadedFileName] = useState<string>('');

    const handleUpload = async (file: File): Promise<void> => {
        if (file?.size > MAX_FILE_SIZE) {
            onError(Model3dErrorCode.FileSize);
            return;
        }

        if (file?.name) {
            setUploadedFileName(file.name);
        }
        await addModel(file);
    };

    const ImagePlaceholder = (): React.JSX.Element => {
        return (
            <ImagePlaceholderWrapper className={'action'}>
                <StyledAddImageIngredientIcon />
                <PlaceholderTypography className={'main'}>
                    {'Choose the 3D model or drag it here. '}
                </PlaceholderTypography>
                <PlaceholderTypography variant="caption">
                    {'Supported obj, stl, fbx, glb, gltf without textures and animation. Up to 8 Mb file size. '}
                </PlaceholderTypography>
            </ImagePlaceholderWrapper>
        );
    };

    const LoadingPlaceholder = (): React.JSX.Element => {
        return (
            <ImagePlaceholderWrapper>
                <StyledFileUploadIcon />
                <PlaceholderTypography className={'secondary'}>
                    {'Uploading file'}
                </PlaceholderTypography>
                <LoadingInfo>
                    <ContentLoader state={isLoading}/>
                    <InfoTypography>{uploadedFileName || currentFileName}</InfoTypography>
                </LoadingInfo>
            </ImagePlaceholderWrapper>
        );
    };
  return (
    <>
        { isLoading ?
            <LoadingPlaceholder/>
        :
            <FileUploader
                disabled={false}
                multiple={false}
                handleChange={handleUpload}
                types={['fbx', 'obj', 'gltf', 'glb', 'stl']}>
                <ImageWrapper>
                    <ImagePlaceholder />
                </ImageWrapper>
            </FileUploader>
        }
    </>
  );
};

export default CameraViewFileUploader;

const ImageWrapper = styled(Box)(({ theme }) => ({

    width: '300px',
    height: '153px',

    backgroundColor: theme.palette.primary.light,

    borderRadius: '6px',
    overflow: 'hidden',
    cursor: 'inherit',
    aspectRatio: '1'
}));

const ImagePlaceholderWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    borderRadius: '6px',
    backgroundColor: theme.palette.primary.grey,
    border: `2px dashed ${theme.palette.primary.light1}`,

    '&.action': {
        cursor: 'pointer',
        backgroundImage: `url(${AddImageSmall})` ,

        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom -25px right 10px',
    },
    width: '300px',

    aspectRatio: 'auto',
    height: '153px',
}));

const LoadingInfo = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    gap: '14px'

}));

const StyledAddImageIngredientIcon = styled(AddImageIngredientIcon)(({ theme }) => ({
    display: 'flex',
    height: '46px',
    width: '46px',
    color:  theme.palette.primary.grey
}));

const StyledFileUploadIcon = styled(FileUploadIcon)(({ theme }) => ({
    display: 'flex',
    height: '40px',
    width: '31px',
    color:  theme.palette.primary.contrastText,
}));

const PlaceholderTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    color:  theme.palette.primary.inactive,

    '&.main': {
        color:  theme.palette.primary.contrastText,
        padding: '14px 0px'
    },

    '&.secondary': {
        color:  theme.palette.primary.contrastText,
        padding: '12px 0px'
    },
}));

const InfoTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center',
    maxWidth: '165px',

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',

    color:  theme.palette.primary.contrastText,
}));
