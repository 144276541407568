const DEFAULT_FILENAME = 'image.png';

export const getImageFile = async (imageSrc: string, fileName = DEFAULT_FILENAME): Promise<File> => {
    const result = await fetch(imageSrc);
    const blob = await result.blob();
    const file = new File([blob], fileName, {
        type: 'image/png',
    });
    return file;
};

export const getImageFileFromBase64String = (imageSrc: string): File => {
    const byteCharacters = atob(imageSrc.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray]);

    const file = new File([blob], DEFAULT_FILENAME, {
        type: 'image/png',
    });

    return file;
};

export const downloadPngByUrl = (url: string): void => {
    fetch(url)
        .then((response) => {
            return response.blob();
        })
        .then((blob) => {
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = DEFAULT_FILENAME;
            a.click();
        });
};
