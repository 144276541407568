import * as THREE from 'three';
import { degToRad } from 'three/src/math/MathUtils';
import {DefaultZoomRange} from '@utils/camera/ZoomRange';

class MouseControls {
    constructor(camera, cb = () => undefined) {
        this.camera = camera;
        this.cb = cb;

        this.maxAngleX = degToRad(90);
        this.minAngleX = degToRad(0);
    }

    handleMouseDown = (event) => {
        this.isMouseDown = true;
        this.previousMousePosition = {
            x: event.clientX,
            y: event.clientY,
        };
    };

    handleMouseMove = (event, model) => {
        if (!this.isMouseDown) return;

        const { x, y } = this.previousMousePosition;
        const deltaX = event.clientX - x;
        const deltaY = event.clientY - y;

        switch (event.which) {
            case 1:
                this.rotateModelByMouse(deltaX, deltaY, model);
                break;
            case 3:
                this.moveModelByMouse(deltaX, deltaY, model);
                break;
        }

        this.previousMousePosition = {
            x: event.clientX,
            y: event.clientY,
        };
    };

    handleMouseUp = () => {
        this.isMouseDown && this.cb();
        this.isMouseDown = false;
    };

    rotateModelByMouse = (deltaX, deltaY, model) => {
        const rotationSpeed = 0.005;
        const rotationX = deltaX * rotationSpeed;
        const rotationY = deltaY * rotationSpeed;

        const newRotationX = Math.min(
            Math.max(model.rotation.x + rotationY, this.minAngleX),
            this.maxAngleX,
        );
        const newRotationY = model.rotation.y + rotationX;

        model.rotation.x = newRotationX;
        model.rotation.y = newRotationY;
    };

    moveModelByMouse = (deltaX, deltaY, model) => {
        const translationX = deltaX;
        const translationY = -deltaY;

        model.position.x += translationX;
        model.position.y += translationY;
    };

    zoomCamera(delta) {
        const newZoom = this.camera.zoom - delta * 0.002;
        this.camera.setZoom(newZoom);
        this.cb();
    }
}
export default MouseControls;
