import React from 'react';
import { Box, styled, IconButton, Typography } from '@mui/material';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from '@mui/icons-material';
import { ExpandMenuButtonProps } from './ExpandMenuButton.type';

const ExpandMenuButton = ({ isActive, label, buttonAction, isDisabled = false, direction = 'right' }: ExpandMenuButtonProps): React.JSX.Element => {
  return (
    <FormButton active={isActive} large={!!label} className={`${direction}-direction`}>
        <MenuIcon
            disabled={isDisabled}
            onClick={buttonAction}
            disableRipple>
            { label
                ?  <MenuTitle>{label}</MenuTitle>
                : isActive ? <KeyboardArrowLeftOutlined /> : <KeyboardArrowRightOutlined />
            }
        </MenuIcon>
    </FormButton>
  );
};

export default ExpandMenuButton;

const FormButton = styled(Box)<{active: boolean, large: boolean}>(({ theme, active, large }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top: '30px',

    background: theme.palette.neutrals.main,
    border: `1px solid ${theme.palette.primary.light}`,

    width: '34px',
    height: large ? '76px' : '64px',

    transition: 'all 0.5s',
    transitionDelay: active ? '0s' : ' 0.5s',

    '&.right-direction' : {
        borderBottomRightRadius: '15px',
        borderTopRightRadius: '15px',
    },

    '&.left-direction' : {
        borderBottomLeftRadius: '15px',
        borderTopLeftRadius: '15px',
    }
}));

const MenuTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.neutrals.inactive1,
    transform: 'rotate(270deg)',

    fontFamily: 'Roboto400',
    fontSize: '14px',
    lineHeight: '19.6px',

    '&:hover': {
        color: theme.palette.primary.contrastText,
    },
}));

const MenuIcon = styled(IconButton)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },

    '& svg': {
        fill: theme.palette.primary.light1,

        '&:hover': {
            fill: theme.palette.secondary.main,
        }
    }
}));
