import React from 'react';
import { Box, styled } from '@mui/material';
import DragAndDropPanel from '@root/components/DragAndDropPanel';
import InfluenceSlider from '@root/components/InfluenceSlider';
import { ControlImageProps } from './ControlImage.types';

const ControlImage = ({
    id,
    enabled,
    image,
    onImageChange,
    weight,
    onWeightChange,
}: ControlImageProps): React.JSX.Element => {
    
    return (
        enabled ? <Wrapper>
            { enabled && (
                <ImageWrapper>
                    <DragAndDropPanel
                        enabled={enabled}
                        OnUpload={onImageChange}
                        loadedImageUrl={image ? URL.createObjectURL(image) : ''}
                        weight={weight}
                    ></DragAndDropPanel>
                </ImageWrapper>)
            }
            { enabled && image && (
                 <InfluenceSlider
                    id={id}
                    value={weight} 
                    OnChange={onWeightChange} 
                    label={'Influence'}
                    step={1}
                    range={[1, 100]}
                    endAdornment={'%'}
                ></InfluenceSlider>)
            }
        </Wrapper>
        :
        <></>
    );
};

export default ControlImage;

const Wrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '300px'
}));

const ImageWrapper = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    position: 'relative',
    paddingBottom: '10px',

    overflow: 'hidden',
    transition: 'all 0.5s'
}));
