import React, { useEffect, useState } from 'react';
import { Box, Modal, Typography, Tooltip, styled, Button } from '@mui/material';
import { ShareDialogProps } from './ShareDialog.types';
import { InformationIcon } from '@root/assets/icons/InformationIcon';
import { ZipFileIcon } from '@root/assets/icons/ZipFileIcon';
import InputField from '@root/components/InputField';
import { FileUploader } from 'react-drag-drop-files';
import AddImageSmall from '@root/assets/images/AddImageSmall.png';
import { AddImageIngredientIcon } from '@root/assets/icons/AddImageIngredientIcon';
import { ImportWithOptStatus, ImportWithOptStatusMessages } from '@root/utils/constants/enums';
import { DoneIcon } from '@root/assets/icons/DoneIcon';

const ShareDialog = ({
    isActive,
    isExport,
    keyValue,
    importStatus,
    isDownloadDisabled,
    onClose,
    handleDownload,
    handleKeyGeneration,
    handleKeyApply,
    uploadModel
}: ShareDialogProps): React.JSX.Element => {

    const [appliedOtp, setAppliedOtp] = useState<string>('');

    const handleClick = (): void => {
        if (isExport && handleKeyGeneration) {
            handleKeyGeneration();
        } else if (!isExport && handleKeyApply && appliedOtp) {
            handleKeyApply(appliedOtp);
        }
    };
    
    const handleClose = (): void => {
        onClose();
        setAppliedOtp('');
    };

    const onOtpInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (isExport) {
            return;
        }
    
        setAppliedOtp(event.target.value);
    };


    useEffect(() => {
        if (!isActive) {
            setAppliedOtp('');
        }
    }, [isActive]);
    

    return (
        <StyledModal
            open={isActive}
            onClose={handleClose}>
            <ModalContainer>
                <ModalItem isImport={!isExport}>
                    {
                        isExport ?
                        <>
                            <ItemLabel>{'ZIP file'}</ItemLabel>
                            <ItemContent>
                                <ItemIcon></ItemIcon>
                                <DialogButton variant="contained" onClick={handleDownload} disabled={isDownloadDisabled}>{'DOWNLOAD'}</DialogButton>
                            </ItemContent>
                        </>
                        :
                        <FileUploader
                            disabled={false}
                            multiple={false}
                            handleChange={uploadModel}
                            types={['zip']}>
                                <UploaderPlaceholder>
                                    <UploaderIcon />
                                    <PlaceholderLabel variant="caption">
                                        {'choose the file or drag it here'}
                                    </PlaceholderLabel>
                                </UploaderPlaceholder>
                        </FileUploader>
                    }
                </ModalItem>
                <ModalItem>
                    <ItemHeader>
                        <ItemLabel>{'API Key'}</ItemLabel>
                        <Tooltip
                            placement="right"
                            title={'Please be advised that your API key is valid for a single use only. Once utilized, it cannot be employed again. '}>
                            <span><TooltipIcon></TooltipIcon></span>
                        </Tooltip>
                    </ItemHeader>
                    {
                        <ItemContent>
                            <InputField
                                isReadOnly={isExport}
                                id="otpValue"
                                name="otpValue"
                                isRequired={false}
                                value={isExport ? keyValue || '' : appliedOtp}
                                withCopyOption={true}
                                onChange={onOtpInputChange}
                            ></InputField>
                            {
                                importStatus && 
                                <>
                                    {   importStatus === ImportWithOptStatus.Success ?
                                        <StatusMessage>
                                            <StatusLabel>{ImportWithOptStatusMessages[importStatus]}</StatusLabel>
                                            <DoneIcon></DoneIcon>
                                        </StatusMessage>
                                        :
                                        <StatusMessage error={true}>
                                            <StatusLabel>{ImportWithOptStatusMessages[importStatus]}</StatusLabel>
                                            <TooltipIcon></TooltipIcon>
                                        </StatusMessage>
                                    }
                                </>
                            }
                            <DialogButton variant="contained" onClick={handleClick}>{isExport ? 'CREATE' : 'OK'}</DialogButton>
                        </ItemContent>
                    }
                </ModalItem>
            </ModalContainer>
        </StyledModal>
    );
};

export default ShareDialog;

const StyledModal = styled(Modal)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const ModalContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '698px',
    height: '234px',

    background: theme.palette.neutrals.main,
    padding: '30px',
    gap: '18px',
    borderRadius: '15px'
}));

const ModalItem = styled(Box)<{ isImport?: boolean }>(({ theme, isImport }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '310px',
    height: '174px',

    borderRadius: '15px',
    padding: isImport ? '0px' : '20px',

    border: `${isImport ? '1.5px dashed' : '1px solid' } ${theme.palette.primary.light1}`
}));

const ItemIcon = styled(ZipFileIcon)(({ theme }) => ({
    marginBottom: '25px',
    color: theme.palette.primary.light1,
    height: '40px',
    width: '30px'
}));

const ItemHeader = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px'
}));
  
const ItemLabel = styled(Typography)(({ theme }) => ({
    fontFamily: 'Roboto500',
    fontSize: '16px',
    lineHeight: '22px',
    color: theme.palette.primary.light1,
}));

const ItemContent = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',

    height: '100%',

    '& .MuiOutlinedInput-root': {
        width: '270px !important',
        marginLeft: '15px',
        borderRadius: '6px',
        marginBottom: '25px',
    },
}));

const TooltipIcon = styled(InformationIcon)(({ theme }) => ({
    width: '13px',
    height: '13px',
    color: theme.palette.primary.main
    
}));

const DialogButton = styled(Button)(() => ({
    width: '270px',
    height: '30px',
    fontSize: '14px',

    '@media (max-width:1500px)': {
        fontSize: '14px',
    }
}));

const UploaderPlaceholder = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    height: '172px',

    backgroundColor: theme.palette.primary.grey,
    backgroundImage: `url(${AddImageSmall})` ,

    borderRadius: '15px',
}));

const UploaderIcon = styled(AddImageIngredientIcon)(({ theme }) => ({
    display: 'flex',
    marginTop: '24px',
    margin: '10px',

    height: '41px',
    width: '41px',
    color:  theme.palette.primary.grey
}));


const PlaceholderLabel = styled(Typography)(({ theme }) => ({
    alignItems:'center',
    textAlign: 'center',

    paddingBottom:'10px',
    maxWidth: '200px',
    color:  theme.palette.primary.inactive,
}));

const StatusLabel = styled(Typography)(() => ({
    fontFamily: 'Roboto400',
    fontSize: '10px',
    lineHeight: '14px',

}));

const StatusMessage = styled(Box)<{error?: boolean}>(({ theme, error }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '13px',

    color:  error ? theme.palette.error.main : theme.palette.primary.main,
    marginTop: '-21px',
    marginBottom: '8px',
    gap: error ? '8px' : '4px',

    '& svg': {
        width: '13px',
        height: '13px',
        color:  error ? theme.palette.error.main : theme.palette.primary.main,
    },
}));